import React from 'react';
import Logo from '../../assets/images/admin/Reap_logo.png';

const HeaderWithLogo = () => {
	const isMobile = window.innerWidth < 768;

	return (
		<div
			className="header header_tc_web"
			style={{ paddingLeft: !isMobile ? '50px' : '10px' }}
		>
			<img src={Logo} width="120px" height="70px"  alt="REAP logo" style={{objectFit:"contain"}}/>
		</div>
	);
};

export default HeaderWithLogo;
