import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import AdminDataService from '../../../../../services/AdminDataServices';
import './toggle.css';
import { GlobalContext } from './../../../../../context/GlobalState';

function ToggleButtonStatus({
	active,
	inActive,
	status,
	id,
	type,
	themeArray,
	setThemeArray,
	themeId,
	themeStatus,
}) {
	const { setMessage } = useContext(GlobalContext);
	const adminApi = new AdminDataService();
	const [toggled, setToggled] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(status);

	useEffect(() => {
		setCurrentStatus(status);
		let statusCopy = status == 0 ? false : true;
		setToggled(statusCopy);
	}, [status]);

	useEffect(() => {
		const currentStatuscopy = currentStatus == 0 ? false : true;
		setToggled(currentStatuscopy);
	}, [currentStatus]);

	const toggleRef = useRef(null);

	const getApi = () => {
		let api = '';
		switch (type) {
			case 'resource':
				api = adminApi.updateResourceStatus;
				break;
			case 'process':
				api = adminApi.updateProcessStatus;
				break;
			case 'waste':
				api = adminApi.updateWasteStatus;
				break;
			case 'observation':
				api = adminApi.updateObservationStatus;
				break;
			case 'workarea':
				api = adminApi.updateWorkAreaStatus;
				break;
			case 'subTheme':
				api = adminApi.updateSubThemeStatus;
				break;

			default:
				break;
		}
		return api;
	};

	const handleToggle = async () => {
		const api = getApi();
		try {
			const newCurrentKey = currentStatus == 1 ? 0 : 1;

			const apiResult = await api(newCurrentKey, id);
			const themeArrayCopy = themeArray.map((resourceItem) => {
				if (resourceItem[themeId] === id) {
					resourceItem = { ...resourceItem, [themeStatus]: newCurrentKey };
					return resourceItem;
				} else {
					return resourceItem;
				}
			});
			setThemeArray(themeArrayCopy);

			setMessage(apiResult.data.message);
			setCurrentStatus(newCurrentKey);
			setToggled(!toggled);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Button
			ref={toggleRef}
			className={currentStatus == 1 ? 'toggle-btn toggled' : 'toggle-btn'}
			onClick={handleToggle}
			size="md"
			style={{ backgroundColor: currentStatus == 0 ? '#FFFCF4' : '#1E989A' }}
		>
			<span className="toggle-ball"></span>
			<span
				style={{
					paddingBottom: '10px',
					color: currentStatus ? 'white' : 'black',
				}}
			>
				{currentStatus == 0 ? active : inActive}
			</span>
		</Button>
	);
}

export default ToggleButtonStatus;
