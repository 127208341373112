
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import Header from '../../../assets/pdf_images/Header.png';
import RobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
import Footer from '../../../assets/pdf_images/Footer.png';
import ItalicText from '../../../assets/fonts/ArialCEItalic.ttf';
const ExecutiveSummeryPDF = ({ executiveData, company}) => {
    Font.register({
        family: 'Roboto',
        fonts: [
            { src: RobotoRegular, fontWeight: 'normal' },
            { src: RobotoBold, fontWeight: 'bold' },
            {
                src: ItalicText,
                fontStyle:"italic"
              }
        ]
    });
    const pageStyle = {
        paddingTop:10,
        paddingBottom: 75,
        paddingHorizontal: 40,

    };
    const ColorLightBlue = {
        color: "#1E989A"
    }
    const tableStyle = {
        display: "table",
        width: "auto"
    };

    const tableRowStyle = {
        flexDirection: "row"
    };

    const firstTableColHeaderStyle = {
        width: "10%",
        borderStyle: "solid",
        borderColor: "#000",
        borderBottomColor: "#000",
        borderWidth: 1,
        backgroundColor: "#d9d9d9",
        justifyContent: 'center',
        alignItems: 'center',
    };

    const tableColHeaderStyle = {
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: "solid",
        borderColor: "#000",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        backgroundColor: "#d9d9d9",
        fontSize: "10px",
        justifyContent: 'center',
        alignItems: 'center',
    };

    const firstTableColStyle = {
        width: "10%",
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        borderTopWidth: 0,
        justifyContent: 'center',
        alignItems: 'center',
    };

    const tableColStyle = {
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        justifyContent: 'center',
        alignItems: 'center',
    };

    const tableCellHeaderStyle = {
        textAlign: "center",
        margin: 4,
        fontSize: 11,
        fontWeight: "bold",
        alignItem: "center",
        fontFamily:"Roboto",
    };

    const tableCellStyle = {
        textAlign: "center",
        margin: 5,
        fontSize: 10,
        fontFamily:'Roboto'
    };
    const FooterStyle = {
        position: 'absolute',
        fontSize: 12,
        bottom: "10px",
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    };
    const pageNumberstyle = {
        position: 'absolute',
        fontSize: 12,
        bottom: "0px",
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor:"#1e989a",
        height:"16px",
        flexDirection:"row",
        alignItems:"center",
        textAlign:"center",
    }
    return (
        <Page
            style={pageStyle}
            size="A4"
            orientation="portrait"
        >
            <View fixed><Image src={Header} /></View>
            <View
                style={{
                    color: ColorLightBlue.color,
                    flexDirection: 'row',
                    paddingBottom: "10px",
                    marginBottom:"10px"
                }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: "12px" }}>{1 + "."} </Text>
                <Text style={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "12px" }}>Executive Summary</Text>
            </View>
            <View>
                <Text style={{ fontSize: "11px", lineHeight: "1.8px" }}>
                    The Resource Efficient Cleaner Production (RECP) approach was used during on-site consulting visits to
                    <Text style={{fontFamily: 'Roboto',fontWeight:"bold"}}> {company.length>0&&company[0].company_name}</Text> on <Text style={{fontFamily: 'Roboto',fontWeight:"bold"}}>{company.length>0&&company[0].company_iav_date}</Text>. REAP team members studied the process of
                    production at the company to identify opportunities for reducing the resource use at the company. The
                    focus was on identifying low cost or no cost options for improvements and for reducing resource use at the
                    source.
                </Text>
            </View>
            <View style={{ paddingTop: "5px" }}>
                <Text style={{ fontSize: "11px", lineHeight: "1.8px" }}>
                    A summary of the identified improvement opportunities is provided in the tables to help the company
                    owners or top management to obtain a quick overview of savings potential identified at this stage. The
                    improvement opportunities are described in brief in section 3 of this report. REAP team members may be
                    contacted for any clarification or technical support. The company management is urged to study this report
                    in detail and to select those options they wish to implement.
                </Text>
            </View>
            <View style={{ paddingTop: "5px" }}>
                <Text style={{ fontSize: "11px", lineHeight: "1.8px" }}>
                    The management is also requested to assign clear responsibilities to internal team members for early
                    implementation of the selected options. Based on the selected areas or options, REAP team will prepare
                    more detailed steps explaining the steps to be taken for the implementation of the selected options, where
                    needed.
                </Text>
                <View
                    style={{
                        color: "#1E989A",
                        flexDirection: 'row',
                        paddingTop: "10px",
                        paddingBottom: "10px"
                    }}>
                    <Text style={{ fontSize: "12px" }}>{"1.1. "} </Text>
                    <Text style={{ fontWeight: "bold", fontSize: "12px" }}>Summary of Resource Saving Potentia</Text>


                </View>
                <View style={{ paddingTop: "3px" }}>
                    <Text style={{ fontSize: "11px", lineHeight: "1.8px", paddingTop: "5px" }}>
                        A summary of resource and cost saving potential along with estimation of investment is provided in Table
                        1.
                    </Text>
                </View>
                <View style={{ paddingTop: "3px", paddingBottom: "8px" }}>
                    <Text style={{ fontSize: "10px", fontFamily:"Roboto",fontStyle:"italic", color: ColorLightBlue.color }}>Table 1 Summary of resource saving potential</Text>
                </View>
            </View>
            <View>
                <View style={tableRowStyle} fixed>
                    <View style={firstTableColHeaderStyle}>
                        <Text style={tableCellHeaderStyle}>S. No</Text>
                    </View>

                    <View style={{ ...tableColHeaderStyle, width: "15%" }}>
                        <Text style={tableCellHeaderStyle}>Resources</Text>
                    </View>
                    <View style={{ ...tableColHeaderStyle, width: "50%" }}>
                        <Text style={tableCellHeaderStyle}>Potential Saving </Text>
                        <View style={{ width: "100%", flexDirection: "row", borderTop: "1px" }}>
                            <View style={{ width: "50%", borderRight: "1px", verticalAlign: 'middle' }}>
                                <Text style={tableCellHeaderStyle}>Quantity</Text>
                            </View>

                            <View style={{ width: "50%" }}>
                                <Text style={tableCellHeaderStyle}>Monetary {"("}{company.length>0&&company[0].currency}{")"}</Text>
                            </View>
                        </View>

                    </View>
                    <View style={{ ...tableColHeaderStyle, width: "25%" }}>
                        <View>
                            <Text style={tableCellHeaderStyle}>Estimated Investment</Text>
                        </View>
                        <View style={{ borderTop: "1px", width: "100%" }}>
                            <Text style={tableCellHeaderStyle}>{company.length>0&&company[0].currency}</Text>
                        </View>
                    </View>
                </View>
                {executiveData && executiveData.map((item, index) => <View style={tableRowStyle} key={index}>
                    <View style={firstTableColStyle}>
                        <Text style={tableCellStyle}>{index + 1}</Text>
                    </View>

                    <View style={{ ...tableColStyle, width: "15%" }}>
                        <Text style={tableCellStyle}>{item.executive_summary_resource_name}</Text>
                    </View>

                    <View style={{ ...tableColStyle, width: "25%" }}>
                        <Text style={tableCellStyle}>{item.executive_summary_quantity}</Text>
                    </View>

                    <View style={{ ...tableColStyle, width: "25%" }}>
                        <Text style={tableCellStyle}>{item.executive_summary_monetary}</Text>
                    </View>

                    <View style={{ ...tableColStyle, width: "25%" }}>
                        <Text style={tableCellStyle}>{item.executive_summary_estimated_investment}</Text>
                    </View>

                </View>)}

            </View>
            <View style={FooterStyle} fixed><Image src={Footer} /></View>
            <View style={pageNumberstyle} fixed><Text style={{color:"#fff",fontSize:9,width:"100%",textAlign:"center"}} 
               render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )}
            /></View>
        </Page>

    )
}
export default ExecutiveSummeryPDF;