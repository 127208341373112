import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { GlobalContext } from '../../../../../context/GlobalState';
import './toggle.css';
import AdminDataService from '../../../../../services/AdminDataServices';

const ToggleButtonCustom = ({
	active,
	inActive,
	isCustom,
	id,
	type,
	recommendation,
	themeArray,
	setThemeArray,
	themeId,
}) => {
	const adminApi = new AdminDataService();
	const { setMessage, setObservationApiError, setCurrentObservatinId } =
		useContext(GlobalContext);
	const [toggled, setToggled] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(isCustom);

	const toggleRef = useRef(null);

	useEffect(() => {
		setCurrentStatus(isCustom);
	}, [isCustom]);
	useEffect(() => {}, [currentStatus]);

	const getApi = () => {
		let api = '';
		switch (type) {
			case 'resource':
				api = adminApi.resourceCustomToActive;
				break;
			case 'process':
				api = adminApi.processCustomToActive;
				break;
			case 'waste':
				api = adminApi.wasteCustomToActive;
				break;
			case 'observation':
				api = adminApi.updateObservationIsCustom;
				break;
			case 'workarea':
				api = adminApi.updateWorkareaIsCustom;
				break;
			case 'subTheme':
				api = adminApi.updateSubthemeIsCustom;
				break;

			default:
				break;
		}
		return api;
	};

	const handleToggle = async () => {
		const api = getApi();
		let apiResult = '';
		try {
			const newCurrentKey = currentStatus == 1 ? 0 : 1;
			const result =
				currentStatus == 1
					? (toggleRef.current.style.backgroundColor = '#FFFCF4')
					: (toggleRef.current.style.backgroundColor = '#1E989A');
			if (type != 'observation') {
				apiResult = await api(id);
				const themeArrayCopy = themeArray.map((resourceItem) => {
					if (resourceItem[themeId] === id) {
						resourceItem = { ...resourceItem, is_custom: newCurrentKey };
						return resourceItem;
					} else {
						return resourceItem;
					}
				});
				setThemeArray(themeArrayCopy);
			} else {
				if (!recommendation) {
					setCurrentObservatinId(id);
					setObservationApiError(true);
					return;
				}
			}

			setMessage(apiResult.data.message);
			setCurrentStatus(newCurrentKey);
			setToggled(!toggled);
		} catch (error) {
			console.log(error);
		}
	};

	return currentStatus == 1 ? (
		<Button
			ref={toggleRef}
			className="toggle-btn"
			onClick={handleToggle}
			size="md"
		>
			<span className="toggle-ball"></span>
			<span style={{ paddingBottom: '10px', color: 'black' }}>
				{currentStatus == 0 ? active : inActive}
			</span>
		</Button>
	) : (
		<button className="Master_btn">Master</button>
	);
};

export default ToggleButtonCustom;
