import SuccessMessage from './components/admin/components/SuccessMessage/SuccessMessage';
import { GlobalContext, GlobalProvider } from './context/GlobalState';
import Resource from './components/admin/components/database/Resource';
import {
	Routes,
	Route,
	useLocation,
	Navigate,
	useNavigate,
} from 'react-router-dom';
import Process from './components/admin/components/database/Process';
import Waste from './components/admin/components/database/Waste';
import Observation from './components/admin/components/database/Observation/Observation';
import ActivityLog from './components/admin/components/ActivityLog/ActivityLog';
import Tcs from './components/admin/components/tc/tc';
import AdminHeader from './components/admin/components/header/header';
import Companies from './components/admin/components/companies/companies';
import jwtDecode from 'jwt-decode';
import './admin.css';
import { useEffect, useContext } from 'react';
import AdminLogin from './components/admin/components/AdminLogin/login';
import ErrorModal from './components/admin/components/ErrorModal';

const AdminRoutes = () => {
	const path = useLocation();
	const navigate = useNavigate();
	let adminPath = path.pathname;
	let adminCurrentpath = adminPath.split('/')[1];
	const secondaryPath = adminPath.split('/')[2];

	useEffect(() => {
		// if (localStorage.getItem('adminToken')) {
		// 	const decodedToken = jwtDecode(localStorage.getItem('adminToken'));
		// 	const { exp } = decodedToken;
		// 	const currentDate = Math.floor(Date.now() / 1000);
		// 	if (exp < currentDate) {
		// 		localStorage.removeItem('adminToken');
		// 		window.location.href = '/cms';
		// 	} else {
		// 	}
		// } else {
		// 	window.location.href = '/cms';
		// }
	}, []);

	const adminRoutes = [
		'companies',
		'tcs',
		'resource',
		'process',
		'waste',
		'observation',
		'activity-log',
	];

	return (
		<GlobalProvider>
			{localStorage.getItem('adminToken') && adminCurrentpath == 'cms' ? (
				<AdminHeader />
			) : null}

			<ErrorModal />
			<Routes>
				<Route path="/" element={<AdminLogin />} />

				{localStorage.getItem('adminToken') ? (
					<>
						<Route path="/companies" element={<Companies />} />
						<Route path="/tcs" element={<Tcs />} />
						<Route path="/resource" element={<Resource />} />
						<Route path="/process" element={<Process />} />
						<Route path="/waste" element={<Waste />} />
						<Route path="/observation" element={<Observation />} />
						<Route path="/activity-log" element={<ActivityLog />} />
					</>
				) : (
					<Route
						path={adminRoutes.includes(secondaryPath) ? '/*' : '/cms'}
						element={<AdminLogin />}
					/>
				)}
				<Route
					path="/*"
					element={
						<Navigate
							to="/notFound"
							state={{ from: adminCurrentpath }}
							replace
						/>
					}
				/>
			</Routes>
			<SuccessMessage />
		</GlobalProvider>
	);
};

export default AdminRoutes;
