// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_login__hZu51 {
    padding: 67px 20px 157px 0px;
}
.login_login__hZu51 h1{
    font-weight: 700;
    font-size: 24px;
    color: #3B7E80;
}
.login_login_form__UV9yM label{
    font-weight: 600;
    font-size: 14px;
    color: #1E989A;
}

`, "",{"version":3,"sources":["webpack://./src/components/admin/components/AdminLogin/login.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".login {\r\n    padding: 67px 20px 157px 0px;\r\n}\r\n.login h1{\r\n    font-weight: 700;\r\n    font-size: 24px;\r\n    color: #3B7E80;\r\n}\r\n.login_form label{\r\n    font-weight: 600;\r\n    font-size: 14px;\r\n    color: #1E989A;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `login_login__hZu51`,
	"login_form": `login_login_form__UV9yM`
};
export default ___CSS_LOADER_EXPORT___;
