import React, { useEffect, useState } from 'react';
import TCStyles from './tc.module.css';
import AdminDataService from '../../../../services/AdminDataServices';

const ViewTcDetails = ({ id }) => {
	const adminApi = new AdminDataService();
	const [tcCompanies, setTcCompanies] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		async function getTcHandledCompaniesData() {
			try {
				setIsLoading(true);
				const companies = await adminApi.getCompanyByTcId(id);
				setTcCompanies(companies.data.company);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}

		getTcHandledCompaniesData();
	}, [id]);

	if (isLoading) {
		return <div className="loader"></div>;
	}

	return (
		<div
			className={TCStyles.detailsTable}
			style={{
				border: 'none',
				padding: '0px 20px 15px',
			}}
		>
			<div
				style={{
					borderRadius: '7px',
					border: '3px solid gray',
				}}
			>
				<table className={TCStyles.iarTable}>
					<tbody>
						<tr
							className={TCStyles.trDetail}
							style={{ padding: '10px', borderBottom: '1px solid gray' }}
						>
							<th className={TCStyles.th}>Date of IA Visit</th>
							<th className={TCStyles.th}>Company Code</th>
							<th className={TCStyles.th}>Company Name</th>
							<th className={TCStyles.th}>STE</th>
							<th className={TCStyles.th}>Status</th>
							<th className={TCStyles.th}>Date of Creation</th>
						</tr>
						{tcCompanies?.length > 0 ? (
							tcCompanies?.map((company, index) => {
								let date = company?.company_created_date
									.split('T')[0]
									.split('-');
								let dateFomat = date[2] + '-' + date[1] + '-' + date[0];

								return (
									<tr
										className={
											index % 2 == 0 ? TCStyles.trOdd : TCStyles.trEven
										}
										key={index}
									>
										<td className={TCStyles.th}>
											{company?.company_iav_date?.slice(
												0,
												company?.company_iav_date?.indexOf('T')
											)}
										</td>
										<td className={TCStyles.td}>{company?.company_code}</td>
										<td className={TCStyles.td}>{company?.company_name}</td>
										<td className={TCStyles.td}>{company?.ste_name}</td>
										<td className={TCStyles.td}>
											{company?.company_checkpoints_status == 0
												? 'IAR in Progress'
												: company?.company_checkpoints_status == 1
												? 'IAVisit Done'
												: 'IAR approved'}
										</td>
										<td className={TCStyles.td}>{dateFomat}</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan={7} style={{ textAlign: 'center' }}>
									{' '}
									There are no companies for this TC
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ViewTcDetails;
