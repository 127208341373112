import React from 'react'
import "./Note.css"
import { Page, Text, View, Image, Font, StyleSheet } from "@react-pdf/renderer";
import Header from '../../../assets/pdf_images/Header.png';
import Footer from '../../../assets/pdf_images/Footer.png'
import Robot from '../../../assets/fonts/Roboto-Regular.ttf';
import RobotSemiBold from '../../../assets/fonts/Roboto-Medium.ttf';
import ItalicText from '../../../assets/fonts/ArialCEItalic.ttf';
Font.register({
    family: "RobotFont",
    fonts: [
        {
            src: Robot,
            fontWeight: 100,
        },
        {
            src: RobotSemiBold,
            fontWeight: 600,
        },
        {
            src: ItalicText,
            fontStyle: "italic"
        }
    ]
});
const NotePDF = ({ tcname, company, userSignature }) => {
    const pageStyle = {
        paddingTop: 5,
        paddingBottom: 75,
        paddingHorizontal: 40,

    };
    const FooterStyle = {
        position: 'absolute',
        fontSize: 12,
        bottom: "10px",
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    };
    const pageNumberstyle = {
        position: 'absolute',
        fontSize: 12,
        bottom: "0px",
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor:"#1e989a",
        height:"16px",
        flexDirection:"row",
        alignItems:"center",
        textAlign:"center",
    }
    return (
        <Page
            size="A4"
            orientation="landscape"
            style={pageStyle}
        >
            <View fixed><Image src={Header} /></View>
            <View
                style={{
                    flexDirection: 'row',
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontFamily: "RobotFont",
                    fontWeight: "700"
                }}>
                <Text style={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "12px" }}>Note:</Text>
            </View>
            <View>
                <Text style={{ fontSize: "11px", lineHeight: "1.6px" }}>
                    The savings total shown above does not include the savings potential from several other possible measures that have been not quantified yet. Actual
                    savings potential is higher than the total shown above; likewise, the total investment required may vary.
                </Text>
            </View>
            {/* <View style={{ marginTop: "10px" }}>
                <Text style={{ fontSize: "11px", lineHeight: "1.6px", fontFamily: "RobotFont", fontWeight: "700" }}>
                    For savings calculation, the cost of electricity is taken as 450 UZS/kWh and cost of water as 2170 UZS/m<Text style={{ verticalAlign: "super", fontSize: 8 }}>3 </Text>
                    <Text style={{ fontSize: "11px", lineHeight: "1.8px" }}>. Some of the savings have been estimated based on presently available data.</Text>
                </Text>
            </View> */}
            <View style={{ marginTop: "10px" }}>
                <Text style={{ fontSize: "11px", lineHeight: "1.6px" }}>
                    In general, REAP team has been conservative in estimating the monetary savings. Where the resource saving is shown as a range, the lower figure
                    is used for calculating monetary savings. The company will certainly be able to achieve higher monetary savings than shown in above table, by
                    implementing all the listed recommendations.
                </Text>
            </View>
            <View style={{paddingTop:"70px",flexDirection:"row",justifyContent:"space-between"}}>
                <View style={{border:"1px",padding:"10px",width:"200px"}}>
                    <Text style={{ fontSize: "9px"}}>Submited by:</Text>
                    {userSignature&&<View><Image src={`data:application/pdf;base64,${userSignature}`} style={{width:"100px",height:"75px",objectFit:"contain"}}/></View>}
                    <View><Text style={{width:"70%",borderBottom:"1",color:"#000"}}></Text></View>
                    <View style={{paddingTop:"5px",flexDirection:"row"}}><Text style={{ fontSize: "8px"}}>Technical Consultant: </Text>{tcname&&tcname.map((name,index)=><Text style={{ fontSize: "8px",textTransform:"capitalize"}} key={index}>{name.tc_name}</Text>)}</View>
                </View>
                <View style={{border:"1px",padding:"10px",width:"200px"}}>
                    <Text style={{ fontSize: "9px"}}>Approved by:</Text>
                        {company&&company.map((item,index)=><View key={index}>
                            <Text key={index} style={{ fontSize: "10px",paddingBottom:"5px",fontFamily:"RobotFont",fontWeight:"700",paddingTop:"20px",textTransform:"capitalize"}}>{item.approved_by!=null&&item.approved_by}</Text>
                            {/* <Text style={{ fontSize: "8px"}}>{item.approved_by!=null&&item.approved_by}</Text> */}
                            <View><Text style={{width:"70%",borderBottom:"1",color:"#000"}}></Text></View>
                            <Text style={{ fontSize: "8px",paddingTop:"5px"}}>Senior Technical Expert</Text>
                            <Text style={{ fontSize: "8px"}}>Approval Date: {item.approved_date!=null&&item.approved_date}</Text>
                    </View>)}
                </View>
            </View>
            <View style={FooterStyle} fixed><Image src={Footer} /></View>
            <View style={pageNumberstyle} fixed><Text style={{color:"#fff",fontSize:9,width:"100%",textAlign:"center"}} 
               render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )}
            /></View>
        </Page>
    )
}
export default NotePDF
