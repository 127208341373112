import React, { useState, useEffect } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { english, russian } from './HelpText';
import './style.css';
import DataService from './../../services/DataServices';

const Help = () => {
	const [activeIndex, setActiveIndex] = useState(-1);
	const [currentLanguage, setCurrentLanguage] = useState(english);
	const isMobile = window.innerWidth < 768;
	const handleAccordionClick = (index) => {
		if (activeIndex === index) {
			setActiveIndex(-1);
		} else {
			setActiveIndex(index);
		}
	};
	const api = new DataService();
	useEffect(() => {
		// async function getData() {
		// 	const result = await api.pdfdata({ company_id: 5 });

		// 	const processFLow = JSON.parse(
		// 		result?.data?.pdfData[0]?.PROCESS_FLOW_ARRAY
		// 	);
		// 	console.log(processFLow);

		// 	let resourceArray = [];

		// 	processFLow.map((process, i) => {
		// 		resourceArray.push(JSON.parse(process?.resource_json));
		// 	});
		// 	// const resourceJson = resourceJson.flat();

		// 	const wasteJson = processFLow[0].waste_json;
		// }
		// getData();

		if (localStorage.getItem('lanId')) {
			localStorage.getItem('lanId') == 2
				? setCurrentLanguage(english)
				: setCurrentLanguage(russian);
		}
	}, []);

	return (
		<div className="deskWrapper">
			<div className="container">
				<div className="mainContainers">
					{!isMobile ? (
						<h2
							style={{
								color: '#3B7E80',
								fontWeight: 'bold',
								marginBottom: '30px',
							}}
						>
							{currentLanguage == english ? 'Help' : 'Справка'}
						</h2>
					) : null}
					<p className="mainParagraph">
						{currentLanguage == english
							? 'We are here to assist you with any questions or concerns you may have about using REAP Digital Solution (REAP DS). Here are some frequently asked questions and answers that may be helpful:'
							: 'Мы здесь, чтобы помочь вам с любыми вопросами или опасениями, которые могут у вас возникнуть по поводу использования Цифрового Решения REAP (REAP ЦР). Вот некоторые часто задаваемые вопросы и ответы, которые могут оказаться полезными:'}
					</p>
					<Accordion className="accordionHelp">
						{currentLanguage.map((item, index) => (
							<Card
								className="cardHelp"
								key={index}
								border="gray"
								style={{
									marginBottom: '10px',
									borderTop: '0px',
									borderLeft: '0px',
									borderRight: '0px',
								}}
							>
								<Card.Header
									className="cardHeader"
									style={{
										backgroundColor: 'white',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
									onClick={() => handleAccordionClick(index)}
								>
									<div
										style={{
											fontSize: isMobile ? '16px' : '18px',
											fontWeight: 'bold',
										}}
									>
										{item.title}
									</div>
									<div style={{ paddingRight: '20px' }}>
										{activeIndex === index ? (
											<FaChevronUp />
										) : (
											<FaChevronDown />
										)}
									</div>
								</Card.Header>
								{activeIndex === index && (
									<Card.Body
										className="cardBodHelp"
										dangerouslySetInnerHTML={{ __html: item.content }}
									></Card.Body>
								)}
							</Card>
						))}
					</Accordion>
				</div>
			</div>
		</div>
	);
};

export default Help;
