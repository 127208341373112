import Style from "./NotePreview.module.css"
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
import logo from '../../../assets/pdf_images/logo.png'
import project_implement_img8 from "../../../assets/pdf_images/Partner_Logos_Combined.png"

const NotePreview = ({ tcname, company, userSignature }) => {

    return (
        <div className={Style.SCP_Section}>
            <div style={{ display: "flex", marginTop: "75px", marginLeft: "70px" }}>
                <img src={logo} alt="logoImg" style={{ width: "137px" }} />
                <div className={Style.imgtxtSectn}>
                    <div className={Style.switchImgTxt}>
                        <p>Project Funded by</p>
                    </div>
                    <div className={Style.image_two}>
                        <img src={switchasia} alt="logoImg" />
                    </div>
                </div>
            </div>
            <div className={Style.custom_container_table}>
                <div className={Style.note_text_header}>
                    <p className={Style.note_txt}>Note:</p>
                    <p className={Style.note_desc}>The savings total shown above does not include the savings potential from several other possible measures that have been not quantified yet. Actual
                        savings potential is higher than the total shown above; likewise, the total investment required may vary.</p>
                    <p className={Style.note_desc}>
                        Some of the savings have been
                        estimated based on presently available data.</p>
                    <p className={Style.note_desc}>In general, REAP team has been conservative in estimating the monetary savings. Where the resource saving is shown as a range, the lower figure
                        is used for calculating monetary savings. The company will certainly be able to achieve higher monetary savings than shown in above table, by
                        implementing all the listed recommendations.
                    </p>
                </div>
                <div className={Style.sign_box}>
                    <div className={Style.submitd_box}>
                        <p className={Style.submitdby_txt}>Submitted by:</p>
                        {userSignature && <img src={`data:image/jpg;base64,${userSignature}`} style={{ width: "120px", height: "50px" }} alt="Sign" />}
                        <hr className={Style.sign_line} />
                        {tcname && tcname.map((i, index) => <p className={Style.teconslt_txt} key={index}>Technical Consultant: {i.tc_name}</p>)}
                    </div>
                    <div className={Style.submitd_box}>
                        <p className={Style.submitdby_txt}>Approved by:</p>
                        {company && company.map((i, index) => <p className={Style.ste_name} key={index}>{i.approved_by}</p>)}
                        <hr className={Style.sign_line} />
                        <p className={Style.ste_txt}>Senior Technical Expert</p>
                        {company && company.map((i, index) => <p className={Style.ste_txt} key={index}>Approval Date:{i.approved_date}</p>)}
                    </div>
                </div>
            </div>
            <div>
                <img src={project_implement_img8} style={{ width: "600px", marginTop: "120px", marginLeft: "95px" }} alt="footerImg" />
            </div>
            <div style={{ backgroundColor: "#1E989A", marginTop: "18px", width: "814px" }} >
                <p style={{ color: "white", textAlign: "center", fontSize: "12px", marginBottom: "0" }}>2</p>
            </div>
        </div>
    )
}
export default NotePreview