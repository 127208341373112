import React from 'react';
import TCStyles from '../database.module.css';
import ToggleCustom from './ToggleCustom';
import ToggleStatus from './ToggleStatus';

const ObservationRow = ({
	resource,
	type,
	name,
	id,
	span,
	observation,
	setObservation,
}) => {
	function parseHtmlToPlainText(htmlString) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');
		const plainText = doc.documentElement.textContent;
		const parsedText = plainText.replace(/\n/g, '<br>');
		return parsedText;
	}

	let parsedRecommedation = '';
	if (resource?.observation_master_recommendation) {
		parsedRecommedation = parseHtmlToPlainText(
			resource?.observation_master_recommendation
		);
	}

	return (
		<tr className={TCStyles.tr}>
			<td className={TCStyles.td} colSpan={span}>
				{resource?.observation_master_name}
			</td>

			<td
				className={TCStyles.td}
				colSpan={span}
				style={{ margin: '0 10px' }}
				dangerouslySetInnerHTML={{ __html: parsedRecommedation }}
			></td>
			<td className={TCStyles.td} colSpan={span} style={{ margin: '0 10px' }}>
				{resource?.sub_theme_name}
			</td>
			<td className={TCStyles.td} colSpan={span} style={{ margin: '0 10px' }}>
				{resource?.work_area_name}
			</td>

			<td className={TCStyles.td} colSpan={span}>
				<ToggleStatus
					active="Inactive"
					inActive="Active"
					status={resource?.Main_status}
					resource={resource}
					observation={observation}
					setObservation={setObservation}
				/>
			</td>
			<td className={TCStyles.td} colSpan={span}>
				<ToggleCustom
					active="Master"
					inActive="Custom"
					isCustom={resource?.Main_custom}
					resource={resource}
					recommendation={resource?.observation_master_recommendation}
					observation={observation}
					setObservation={setObservation}
				/>
			</td>
		</tr>
	);
};

export default ObservationRow;
