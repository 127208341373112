import React, { useState, useEffect } from "react";
import Style from './dateofiavisit.module.css'
import DataService from '../../services/DataServices';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import TcBreadCrumb from "../tc_breadcrumb/tc_breadcrumb";
import LoaderImg from '../../assets/images/loadin_gif.gif';
import AuthUser from "../authUser/authuser";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { useLanguageChange } from "../customHooks/lan_hook";
import { ErrorLog } from "../erroLog/errorLog";

const DateofIAisit = () => {
const { logout } = AuthUser();
const {t} = useTranslation();
useLanguageChange();
let currentLocation = useLocation();
let pathName = currentLocation.pathname;
let com_id = pathName.substring(pathName.lastIndexOf('/') + 1);
const navigate = useNavigate();
const api = new DataService();
const [startDate, setStartDate] = useState(new Date());
const [message, setMessage] = useState("");
const [ErrorMessage,SetErrorMessage] =useState(false);
const [Loader, setLoader] = useState(false);
var date = new Date(startDate).getFullYear() + '-' + ("0" + (new Date(startDate).getMonth() + 1)).slice(-2) + '-' + ("0" + new Date(startDate).getDate()).slice(-2);

const saveEntireProcess = async () => {
    let data = {
        "company_id": com_id, 
        "company_iav_date": date 
    };
    await api.addIAvisitDate(data).then((response) => {
        if (response.status == 200 || response.status == 201) {
            let res = response.data;
            setTimeout(() => {
                setLoader(true)
            }, 1000)
            setTimeout(() => {
                setMessage(res.message)
                setLoader(false)
            }, 1500)
            setTimeout(() => {
                navigate("/checkpoints/IARPreparation/" + `${com_id}`)
            }, 2000)
        }
    }).catch((error) => {
        if(error.code !== "ERR_NETWORK"){
            let errorCode = error.response.status;
            let errMsg = error.response.data.error.message;
            if (errorCode == 401) {
                logout();
            }
            if (errorCode == 400 || errorCode == 500) {
                setMessage(errMsg)
                let errorLogData ={
                    api_name:"addiavisitdate",
                    error_code:errorCode,
                    error_message:errMsg
                } 
                ErrorLog(errorLogData);
            }
    }
    else{
        SetErrorMessage(true)
        setMessage("Please check your internet connection.")
    }
    })
}
return (
    <div className='mobileWrapper'>
        {window.innerWidth>768?<TcBreadCrumb title="Initial Assessment Visit" />:null}
        <div className="container">
            <div>
                <p className={Style.datepickr_txt}>{t("DateOfIAVisit")}</p>
            </div>
            <div className={Style.date_picker}>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    styel={{ with: "100%" }}
                    dateFormat="dd/MM/yyyy"
                />
            </div>

            <div className="row">
                <div className={"col-md-12 mb-3 date_btn" + " " + Style.date_btn}>
                    <button className={Style.secondary_date_btn} onClick={() => saveEntireProcess()}>{t("Continue")}</button>
                    {/* <Link to="/"><button className={Style.secondary_date_btn} onClick={() => saveEntireProcess()}>Continue</button></Link> */}
                </div>
            </div>
            {Loader == true ? <div style={{ textAlign: "center" }}><img src={LoaderImg} alt="loader" style={{ width: "200px", margin: "auto" }} /></div> : null}
            <div style={{ marginTop: "8px", textAlign: "center" }}>{message.length > 0 ? message : null}</div>

        </div>
        <Modal className="newObservation removeConform" show={ErrorMessage} onHide={()=>SetErrorMessage(false)} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="P-3 pt-0 text-center">
                <h4 className="mb-4">{message&&message}</h4>
            </div>
        </Modal.Body>
    </Modal>
    </div>
);
}
export default DateofIAisit;