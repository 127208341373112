import { useState, useEffect } from "react";


export const useMasterdata = () => {
   // const [lang,setlang] =useState(localStorage.getItem('lan')!=null?localStorage.getItem('lan'):localStorage.setItem('lan','en'))
   //const [lanId,setLangId] = useState();
   let master_data = JSON.parse(localStorage.getItem('masterData'));
   const [masterData, setMasterData] = useState(master_data)
   const [country, setCountry] = useState();
   const [region, setRegion] = useState();

   const [resources, setResources] = useState();
   const [process, setProcess] = useState();
   const [wasteHazard, setWaste] = useState();
   const [workArea, setWorkArea] = useState();
   const [SubTheme, setSubTheme] = useState();
   const [observation, setObservation] = useState();

   const filterCountry = () => {
      let countryArray = masterData&&masterData.COUNTRY_ARRAY;
      setCountry(JSON.parse(countryArray))
   }
   const filterRegion = () => {
      let regionArray = masterData&&masterData.REGION_ARRAY;
      setRegion(JSON.parse(regionArray))
   }
   const filterResources = () => {
      let resourceArray = masterData&&masterData.RESOURCE_ARRAY;
      setResources(JSON.parse(resourceArray))
   }
   const filterProcess = () => {
      let processArray = masterData&&masterData.PROCESS_ARRAY;
      setProcess(JSON.parse(processArray))
   }
   const filterWaste = () => {
      let wasteArray = masterData&&masterData.WASTE_HAZARD;
      setWaste(JSON.parse(wasteArray))
   }
   const filterWorkArea = () => {
      let workAreaArray = masterData&&masterData.WORK_AREA;
      setWorkArea(JSON.parse(workAreaArray))
   }
   const filterSubtheme = () => {
      let subThemeArray = masterData&&masterData.SUB_THEME_ARRAY;
      setSubTheme(JSON.parse(subThemeArray))
   }
   const filterObservation = () => {
      let ObservationArray = masterData&&masterData.OBSERVATION_ARRAY;
      setObservation(JSON.parse(ObservationArray))
   }
   useEffect(() => {
      filterCountry();
      filterRegion();
      filterResources();
      filterProcess();
      filterWaste();
      filterWorkArea();
      filterSubtheme();
      filterObservation()
   }, [])
   return {
      masterData,
      country,
      region,
      resources,
      process,
      wasteHazard,
      workArea,
      SubTheme,
      observation
   }
};