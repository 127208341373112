import Style from "./GalleryPreview.module.css"
import project_implement_img8 from '../../../assets/pdf_images/Partner_Logos_Combined.png'
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
import logo from '../../../assets/pdf_images/logo.png'

const GalleryPreview = ({ gallery }) => {
    const perChunk = 2
    //const [gallery, setGallery] = useState([])
    const result = gallery && gallery.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)
        return resultArray
    }, [])

    let tabeleContent = '';
    tabeleContent = <>{result && result.map((item, index) => <tr key={index}>
        {item.map((subItem, idx) => <td key={idx} style={{ width: "50%", border: "1px solid black", textAlign: "center" }}>
            <img src={`data:image/jpg;base64,${subItem.linkedImg}`} alt={subItem.gallery_caption} className="img-fluid " style={{ width: "auto", height: "200px", objectFit: "contain", padding: "5px" }} />
            <p className={Style.imgCaption}><span>{subItem.gallery_name}: </span>{subItem.gallery_caption}</p>
        </td>)}

    </tr>)}</>
    return (<div>
        <div style={{ display: "flex", marginTop: "75px", marginLeft: "70px" }}>
            <img src={logo} style={{ width: "137px" }} alt="headerImg" />
            <div className={Style.imgtxtSectn}>
                <div className={Style.switchImgTxt}>
                    <p>Project Funded by</p>
                </div>
                <div className={Style.image_two} >
                    <img src={switchasia} alt="headerImg" />
                </div>
            </div>

        </div>
        <div className={Style.custom_container_pageNine}>
            <p className={Style.header}>4.On-site Photographs</p>
            {/* <div>
                <div className={Style.custom_container_pageNine}>
                    {gallery && gallery.map((i, index) => {
                        return (
                            <div key={index} className={Style.gallery_img_div} style={{ display: "flex", pageBreakBefore: "auto", position: "relative" }}>
                                <div className={Style.imgdiv}>
                                    <img src={`data:image/jpg;base64,${i.linkedImg}`} width="130px" alt="galleryImg" />
                                    <p className={Style.imgCaption} >{i.gallery_name}:{i.gallery_caption}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> */}
            <table>
                <tbody>
                    {tabeleContent}
                </tbody>
            </table>
            <div>
            </div>
            <img src={project_implement_img8} style={{ width: "600px", marginTop: "120px", marginLeft: "15px" }} alt="footerImg" />
        </div>
        <div style={{ backgroundColor: "#1E989A", marginTop: "18px", width: "815px", }} >
            <p style={{ color: "white", textAlign: "center", fontSize: "12px", marginBottom: "0" }}>1</p>
        </div>
    </div>
    )
}
export default GalleryPreview
