import axios from 'axios';
import { baseUrl, cmsApiPath } from '../constant';

//let token = "Bearer " + localStorage.getItem("token").split(" ");
export default class AdminDataService {
	constructor() {
		this.client = null;
		this.init();
	}
	init = () => {
		this.client = axios.create({
			baseURL: baseUrl,
			withCredentials: true,
		});
	};

	//GETTING THE TOKEN
	getToken = () => {
		const parsedToken = JSON.parse(localStorage.getItem('adminToken'));
		return 'bearer ' + parsedToken;
	};

	getHeaders = () => {
		return {
			'content-type': 'application/json',
			Authorization: this.getToken(),
			'app-key': 'CMSKIATZKT5OU3l74OHUGFKTO7',
			'app-name': 'CMS-REAP',
		};
	};

	// LOGIN API CALLS -------------------
	AdminvalidateLogin = (formData) => {
		return this.client.post(cmsApiPath.adminLogin, formData, {
			headers: {
				'content-type': 'application/json',
				// Origin: 'http://65.0.191.49:3001',
				// "app-key":app_key,
				// 'app-name': 'CMS-REAP',
				// 'app-key': 'CMSKIATZKT5OU3l74OHUGFKTO7',
			},
		});
	};

	//COMPANY API CALLS-----------------
	getAllCompanies = () => {
		return this.client.get(cmsApiPath.getAllCompanies, {
			headers: this.getHeaders(),
		});
	};

	deactivateCompany = (companyData) => {
		return this.client.post(cmsApiPath.deactivateCompany, companyData, {
			headers: this.getHeaders(),
		});
	};

	duplicateCompany = (companyData) => {
		return this.client.post(cmsApiPath.duplicateCompany, companyData, {
			headers: this.getHeaders(),
		});
	};

	getCompanyById = (id) => {
		return this.client.post(
			cmsApiPath.getCompanyById,
			{ company_id: id },
			{
				headers: {
					'content-type': 'application/json',
					Authorization: this.getToken(),
					// 'app-key': 'CMSKIATZKT5OU3l74OHUGFKTO7',
					// 'app-name': 'CMS-REAP',
				},
			}
		);
	};
	//this api will be called right after the user logs in , hence we're directly getting the token from caller function
	getCountryAndRegionArray = (token) => {
		return this.client.get(cmsApiPath.getCountryAndRegionArray, {
			headers: {
				'content-type': 'application/json',
				Authorization: 'bearer ' + token,
				'app-key': 'CMSKIATZKT5OU3l74OHUGFKTO7',
				'app-name': 'CMS-REAP',
			},
		});
	};

	editCompany = (companyData) => {
		return this.client.post(cmsApiPath.editCompany, companyData, {
			headers: {
				'content-type': 'application/json',
				Authorization: this.getToken(),
				// 'app-key': 'CMSKIATZKT5OU3l74OHUGFKTO7',
				// 'app-name': 'CMS-REAP',
			},
		});
	};

	getCompanyByTcId = (tcId) => {
		return this.client.post(
			cmsApiPath.getCompanyByTcId,
			{ user_id: tcId },
			{
				headers: this.getHeaders(),
			}
		);
	};

	approveCompanyApi = (id) => {
		return this.client.post(
			cmsApiPath.approveCompany,
			{ company_id: id, company_checkpoints_status: 2 },
			{
				headers: this.getHeaders(),
			}
		);
	};

	// STE AND TC ----------------------
	addNewSte = (steData) => {
		return this.client.post(cmsApiPath.addSTE, steData, {
			headers: this.getHeaders(),
		});
	};

	assignSte = (steData) => {
		return this.client.post(cmsApiPath.assignSte, steData, {
			headers: this.getHeaders(),
		});
	};

	assignTC = (tcData) => {
		return this.client.post(cmsApiPath.assignTC, tcData, {
			headers: this.getHeaders(),
		});
	};

	getSteList = () => {
		return this.client.get(
			cmsApiPath.getSteList,

			{
				headers: this.getHeaders(),
			}
		);
	};

	addNewTc = (tcData) => {
		return this.client.post(cmsApiPath.addTC, JSON.stringify(tcData), {
			headers: this.getHeaders(),
		});
	};

	getTcList = (token) => {
		return this.client.get(cmsApiPath.getTcList, {
			headers: {
				'content-type': 'application/json',
				Authorization: `bearer ${token}`,
				'app-key': 'CMSKIATZKT5OU3l74OHUGFKTO7',
				'app-name': 'CMS-REAP',
			},
		});
	};

	//Resouce, waste, process
	getResourceData = (id) => {
		return this.client.post(
			cmsApiPath.getResourceData,
			{ language_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};

	uploadResourceExcel = (formData) => {
		return this.client.post(
			cmsApiPath.uploadResourceExcel,
			{ resourceFile: formData },
			{
				headers: {
					'content-Type': 'multipart/form-data',
					Authorization: this.getToken(),
					'X-Requested-With': 'XMLHttpRequest',
					Accept: 'application/json',
				},
			}
		);
	};

	getProcessData = (id) => {
		return this.client.post(
			cmsApiPath.getProcessData,
			{ language_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};

	uploadProcessExcel = (formData) => {
		return this.client.post(
			cmsApiPath.uploadProcessExcel,
			{ processFile: formData },
			{
				headers: {
					'content-Type': 'multipart/form-data',
					Authorization: this.getToken(),
					'X-Requested-With': 'XMLHttpRequest',
					Accept: 'application/json',
				},
			}
		);
	};

	getWasteData = (id) => {
		return this.client.post(
			cmsApiPath.getWasteData,
			{ language_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};
	uploadWasteExcel = (formData) => {
		return this.client.post(
			cmsApiPath.uploadWasteExcel,
			{ wasteFile: formData },
			{
				headers: {
					'content-type': 'multipart/form-data',
					Authorization: this.getToken(),
					'app-key': 'CMSKIATZKT5OU3l74OHUGFKTO7',
					'app-name': 'CMS-REAP',
				},
			}
		);
	};

	uploadObservationExcel = (formData) => {
		return this.client.post(
			cmsApiPath.uploadObservationExcel,
			{ uploadobservationfile: formData },
			{
				headers: {
					'content-type': 'multipart/form-data',
					Authorization: this.getToken(),
					'app-key': 'CMSKIATZKT5OU3l74OHUGFKTO7',
					'app-name': 'CMS-REAP',
				},
			}
		);
	};

	//updating status
	updateResourceStatus = (status, id) => {
		return this.client.post(
			cmsApiPath.updateResourceStatus,
			{ resource_id: id, resource_status: status },
			{
				headers: this.getHeaders(),
			}
		);
	};
	updateProcessStatus = (status, id) => {
		return this.client.post(
			cmsApiPath.updateProcessStatus,
			{ process_id: id, process_status: status },
			{
				headers: this.getHeaders(),
			}
		);
	};
	updateWasteStatus = (status, id) => {
		return this.client.post(
			cmsApiPath.updateWasteStatus,
			{ waste_hazard_id: id, isStatus: status },
			{
				headers: this.getHeaders(),
			}
		);
	};
	updateSubThemeStatus = (status, id) => {
		return this.client.post(
			cmsApiPath.updateSubThemeStatus,
			{ sub_theme_id: id, is_active: status },
			{
				headers: this.getHeaders(),
			}
		);
	};
	updateWorkAreaStatus = (status, id) => {
		return this.client.post(
			cmsApiPath.updateWorkAreaStatus,
			{ work_area_id: id, is_active: status },
			{
				headers: this.getHeaders(),
			}
		);
	};
	updateObservationStatus = (status, workAreaId, subThemeId, ObservationId) => {
		return this.client.post(
			cmsApiPath.updateObservationStatus,
			{
				observation_id: ObservationId,
				work_area_id: workAreaId,
				sub_theme_id: subThemeId,
				is_active: status,
			},
			{
				headers: this.getHeaders(),
			}
		);
	};

	resourceCustomToActive = (id) => {
		return this.client.post(
			cmsApiPath.resourceCustomToActive,
			{ resource_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};
	processCustomToActive = (id) => {
		return this.client.post(
			cmsApiPath.processCustomToActive,
			{ process_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};
	wasteCustomToActive = (id) => {
		return this.client.post(
			cmsApiPath.wasteCustomToActive,
			{ waste_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};

	updateObservationIsCustom = (workAreaId, subThemeId, ObservationId) => {
		return this.client.post(
			cmsApiPath.updateObservationIsCustom,
			{
				observation_id: ObservationId,
				work_area_id: workAreaId,
				sub_theme_id: subThemeId,
			},
			{
				headers: this.getHeaders(),
			}
		);
	};
	updateWorkareaIsCustom = (id) => {
		return this.client.post(
			cmsApiPath.updateWorkareaIsCustom,
			{ work_area_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};
	updateSubthemeIsCustom = (id) => {
		return this.client.post(
			cmsApiPath.updateSubthemeIsCustom,
			{ sub_theme_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};

	getObservationMasterList = (id) => {
		return this.client.post(
			cmsApiPath.getObservationMasterList,
			{ language_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};

	updateObservationRecommendation = (id, message) => {
		return this.client.post(
			cmsApiPath.updateObservationRecommendation,
			{ observation_id: id, recommendation: message },
			{
				headers: this.getHeaders(),
			}
		);
	};
	getWorkAreaMasterList = (id) => {
		return this.client.post(
			cmsApiPath.getWorkAreaMasterList,
			{ language_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};
	getSubThemeMasterList = (id) => {
		return this.client.post(
			cmsApiPath.getSubThemeMasterList,
			{ language_id: id },
			{
				headers: this.getHeaders(),
			}
		);
	};

	getLanguage = () => {
		return this.client.get(
			cmsApiPath.getLanguage,

			{
				headers: this.getHeaders(),
			}
		);
	};

	getUserActivity = (dateFilter) => {
		return this.client.post(cmsApiPath.getUserActivity, dateFilter, {
			headers: this.getHeaders(),
		});
	};
}
