import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { GlobalContext } from '../../../../../context/GlobalState';
import '../ToggleButton/toggle.css';
import AdminDataService from '../../../../../services/AdminDataServices';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { produce } from 'immer';

const ToggleCustom = ({
	active,
	inActive,
	isCustom,
	resource,
	observation,
	setObservation,
	recommendation,
}) => {
	const adminApi = new AdminDataService();
	const {
		setMessage,
		setObservationApiError,
		setCurrentObservatinId,
		currentObservationId,
		observationApiError,
	} = useContext(GlobalContext);
	const [toggled, setToggled] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(isCustom);
	const [recommendationText, setRecommendationText] = useState('');
	const [error, setError] = useState(false);

	const toggleRef = useRef(null);

	useEffect(() => {
		setCurrentStatus(isCustom);
	}, [isCustom]);
	useEffect(() => {}, [currentStatus]);

	const handleToggle = async () => {
		let apiResult = '';
		try {
			const newCurrentKey = currentStatus == 1 ? 0 : 1;
			const result =
				currentStatus == 1
					? (toggleRef.current.style.backgroundColor = '#FFFCF4')
					: (toggleRef.current.style.backgroundColor = '#1E989A');

			if (!recommendation && recommendationText.length < 3) {
				setCurrentObservatinId(resource.observation_master_id);
				setObservationApiError(true);
				return;
			} else {
				apiResult = await adminApi.updateObservationIsCustom(
					resource.work_area_id,
					resource.sub_theme_id,
					resource.observation_master_id
				);
			}
			setMessage(apiResult.data.message);
			setCurrentStatus(newCurrentKey);
			setToggled(!toggled);
		} catch (error) {
			console.log(error);
		}
	};

	const setRecommendationValue = (e) => {
		setError(false);
		setRecommendationText(e.target.value);
	};

	const handleRecommendation = async () => {
		if (recommendationText.length < 3) {
			setError(
				'You need to enter a proper recommendation and cannot leave it empty'
			);
			return;
		}
		setObservationApiError(false);

		try {
			const result = await adminApi.updateObservationRecommendation(
				currentObservationId,
				recommendationText
			);

			setMessage('Recommendation updated successfully');
			//settting recommendaiton
			const newObservationElement = observation.find(
				(item) => item?.observation_master_id === currentObservationId
			);

			const newArray = produce(observation, (draftArray) => {
				draftArray.splice(observation?.indexOf(newObservationElement), 1, {
					...newObservationElement,
					observation_master_recommendation: recommendationText,
				});
			});
			setObservation(newArray);
			setRecommendationText('');
		} catch (error) {
			console.log(error);
		}
	};

	const handleClose = () => {
		setObservationApiError(false);
	};

	return currentStatus == 1 ? (
		<>
			<Button
				ref={toggleRef}
				className="toggle-btn"
				onClick={handleToggle}
				size="md"
			>
				<span className="toggle-ball"></span>
				<span style={{ paddingBottom: '10px', color: 'black' }}>
					{currentStatus == 0 ? active : inActive}
				</span>
			</Button>
			<Modal
				show={observationApiError}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="transparent"
			>
				<Modal.Header
					closeButton
					onClick={() => setRecommendationText('')}
				></Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-12 text-center">
							<h4 style={{ fontWeight: '700', fontSize: '18px' }}>
								You need to add a Recommendation before changing it to Master
							</h4>
							<div>
								<input
									type="text"
									style={{
										padding: '15px',
										width: '100%',
										border: '1px solid gray',
										borderRadius: '3px',
										marginBottom: '15px',
									}}
									placeholder="Type here"
									value={recommendationText}
									onChange={(e) => setRecommendationValue(e)}
								/>

								<button
									className="primary_btn small_btn"
									style={{
										width: '150px',
										minWidth: '100px',
									}}
									onClick={handleRecommendation}
								>
									Add Recommendation
								</button>
							</div>
							{error && (
								<Alert style={{ marginTop: '10px' }} variant="danger">
									{error}
								</Alert>
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	) : (
		<button className="Master_btn">Master</button>
	);
};

export default ToggleCustom;
