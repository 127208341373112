export const formatDateWithDateFirst = (date) => {
	if (date != null && typeof date == 'string') {
		let splitArray = date.split(' ')[0].split('-');
		const dateFormat =
			splitArray[2] + '-' + splitArray[1] + '-' + splitArray[0];
		return dateFormat;
	} else {
		return date;
	}
};
