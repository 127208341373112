export const handleApiError = (error, setNetworkError, setError) => {
	if (error.code === 'ERR_NETWORK') {
		// Showing popup

		setNetworkError(true);
	} else if (
		error.code === 'ERR_BAD_REQUEST' &&
		error.response.status === 400
	) {
		// Handle specific error cases
		setError({ ...error, email: 'This Email id already exists ' });
	} else {
		// Handle other types of errors
		console.log('Unknown error occurred:', error);
	}
};
