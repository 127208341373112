import React, { useEffect, useState } from "react";
import options from '../../assets/images/options.png';
import IARStyles from './iar.module.css';
import Modal from 'react-bootstrap/Modal';
import GalleryLinkIcon from '../../assets/images/galleryLink.png';
import OrderIcon from '../../assets/images/drag.png';
import Delete from '../../assets/images/trash.png';
import DataService from "../../services/DataServices";
import { useLocation } from "react-router-dom";
import Gallery from "./tc_gallery/gallery";
import AddnewObservationDesk from "./addObservation/add_observation";
import AuthUser from "../authUser/authuser";
import { Editor } from '@tinymce/tinymce-react';
import LoaderImage from '../../assets/images/loadin_gif.gif';
import { FiEdit } from 'react-icons/fi';
import { AiOutlineFileDone } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { ErrorLog } from "../erroLog/errorLog";
import {tiny_key} from '../../constant'

const SCPImprovement = () => {
    const { logout } = AuthUser();
    const { t } = useTranslation();
    const api = new DataService();
    let currentLocation = useLocation();
    let pathName = currentLocation.pathname
    let comp_url = pathName.substring(pathName.lastIndexOf('/') + 1);
    const [showObservation, SetshowObservation] = useState(false);
    const [scpUpdateShow, setSCPupdatePopup] = useState(false);
    const [scpUpdateMessage, SetSCPUpdateMessage] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [message, setMessage] = useState("");
    const [deleteIndex, setdeleteIndex] = useState();
    const [ScpSidemenuIndex, setScpSidemenuIndex] = useState();
    const [LoaderTexteditor, setLoaderTexteditor] = useState(true);
    const [LoaderSave, setLoaderSave] = useState(false);
    const [InternetError,setInternetError]  = useState("");
    const [Errorpopup,setErrorpopup]=useState(false);
    const [Currency,setCurrency] =useState('');
    const handleClose = () => {
        setShowgallery(false);
        SetshowObservation(false);
        setSCPupdatePopup(false);
        SetSCPUpdateMessage(false);
        setDeletePopup(false);
        setConformObservation(false);
        setInternetError();
    }
    const [SCPimprovementData, setSCPimprovementData] = useState();
    const [showGallery, setShowgallery] = useState(false);
    const [UniqScpId, setUniqScpId] = useState();
    const [FirstNameIndex, setFirstNameIndex] = useState();
    const [conformObservation, setConformObservation] = useState(false);
    const getSCPimprovementData = async () => {
        let getBucketdata = localStorage.getItem("SCPimprovementBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filteredBucket = BucketJson && BucketJson.filter((company) => company.company_id == comp_url);
        if (filteredBucket == null || filteredBucket.length == 0) {
            let data = {
                "company_id": comp_url,
            }
            try {
                await api.getSCPimprovements(data).then((res) => {
                    let responseData = res.data;
                    setCurrency(responseData&&responseData.currency);
                    let Result = responseData.scp_improvements_list.map((item, index) => {
                        let data = {
                            "scp_improvement_options_id": item.scp_improvement_options_id,
                            "work_area_id": item.work_area_id,
                            "gallery_json": JSON.parse(item.gallery_json),
                            "recommendation": item.recommendation,
                            "amount_of_savings_benefits": item.amount_of_savings_benefits,
                            "investments_costs": item.investments_costs,
                            "simple_payback_period": item.simple_payback_period,
                            "is_custom": item.is_custom,
                            "is_delete": item.is_delete,
                            "scp_improvement_options_created_by": item.scp_improvement_options_created_by,
                            "work_area_name": item.work_area_name,
                            "observation_name": item.observation_name
                        }
                        return data;
                    })
                    let allScp = {
                        company_id: responseData.company_id,
                        currency: responseData.currency,
                        scp_improvements_list: Result
                    }
                    let scpBucketArray = [];
                    scpBucketArray.push(allScp)
                    localStorage.setItem("SCPimprovementBucket", JSON.stringify(scpBucketArray))
                    setSCPimprovementData(allScp.scp_improvements_list);
                    if (filteredBucket != null) {
                        let scpBucketArray = [...BucketJson];
                        scpBucketArray.push(allScp)
                        localStorage.setItem("SCPimprovementBucket", JSON.stringify(scpBucketArray))
                        setSCPimprovementData(allScp.scp_improvements_list)
                    }
                    else {
                        let ExeBucketArray = [];
                        ExeBucketArray.push(allScp)
                        localStorage.setItem("SCPimprovementBucket", JSON.stringify(scpBucketArray))
                        setSCPimprovementData(allScp.scp_improvements_list)
                    }
                })
            }
            catch (error) {
                if (error.code != "ERR_NETWORK") {
                    let errorCode = error.response.status;
                    let errMsg = error.response.data.error.message;
                    if (errorCode == 401) {
                        logout();
                    }
                    if (errorCode == 400 || errorCode == 500) {
                        setInternetError(errMsg);
                        setErrorpopup(true);
                        let errorLogData = {
                            api_name: "getscpimprovements",
                            error_code: errorCode,
                            error_message: errMsg
                        }
                        ErrorLog(errorLogData);
                    }
                }
            }
        }
        else {
            let filteredBucketTwo = BucketJson && BucketJson.filter((company) => company.company_id == comp_url);
            setSCPimprovementData(filteredBucketTwo[0].scp_improvements_list);
            setCurrency(filteredBucketTwo[0].currency);
        }
    }

    const galleryShowFunc = (scp_improvement_options_id, index) => {
        setShowgallery(true)
        setUniqScpId(scp_improvement_options_id)
        setFirstNameIndex(index + 1)
    }
    const getgalleryLink = (gallery) => {
        getNewGalleryArray(gallery);
    }

    const getNewGalleryArray = (gallery) => {
        setShowgallery(false);
        let allSCPList = SCPimprovementData;
        let filterScpList = allSCPList.filter((item) => item.scp_improvement_options_id == UniqScpId);
        if (filterScpList[0].scp_improvement_options_id == UniqScpId) {
            let filterGalleryJson = filterScpList[0].gallery_json;
            const newGalleryArray = filterGalleryJson != null ? filterGalleryJson.concat(gallery) : gallery;
            let updateScpGallery = {
                "scp_improvement_options_id": filterScpList[0].scp_improvement_options_id,
                "work_area_id": filterScpList[0].work_area_id,
                "gallery_json": newGalleryArray.length > 0 ? newGalleryArray : null,
                "recommendation": filterScpList[0].recommendation,
                "amount_of_savings_benefits": filterScpList[0].amount_of_savings_benefits,
                "investments_costs": filterScpList[0].investments_costs,
                "simple_payback_period": filterScpList[0].simple_payback_period,
                "is_custom": filterScpList[0].is_custom,
                "is_delete": filterScpList[0].is_delete,
                "scp_improvement_options_created_by": filterScpList[0].scp_improvement_options_created_by,
                "work_area_name": filterScpList[0].work_area_name,
                "observation_name": filterScpList[0].observation_name
            }
            let UpdateData = allSCPList.filter((item) => item.scp_improvement_options_id != UniqScpId);

            UpdateData.push(updateScpGallery)
            UpdateData.sort(function (a, b) {
                return (a.scp_improvement_options_id - b.scp_improvement_options_id);
            })
            let currentScp = {
                company_id: comp_url,
                scp_improvements_list: UpdateData
            }
            let getBucketdata = localStorage.getItem("SCPimprovementBucket");
            let BucketJson = JSON.parse(getBucketdata);
            let filterData = BucketJson.filter((item) => item.company_id != comp_url)
            // console.log("BucketJson-------------------->",BucketJson);
            filterData.push(currentScp);
            localStorage.setItem("SCPimprovementBucket", JSON.stringify(filterData))
            setSCPimprovementData(UpdateData)
        }
        else {
            console.log("error");
        }
    }
    const removeGalleryList = (scpListId, imageid) => {
        let allSCPList = [...SCPimprovementData];
        allSCPList.forEach(element => {
            if (scpListId == element.scp_improvement_options_id) {
                const removeIndex = element.gallery_json.findIndex(item => item.id === imageid);
                if (element.gallery_json.length > 0) {
                    element.gallery_json.splice(removeIndex, 1)
                    if (element.gallery_json.length == 0) {
                        element.gallery_json = null
                    }
                }


                // localStorage.setItem('scp_data', JSON.stringify(getitem))
                setSCPimprovementData(allSCPList)
                let currentScp = {
                    company_id: comp_url,
                    scp_improvements_list: allSCPList
                }
                let getBucketdata = localStorage.getItem("SCPimprovementBucket");
                let BucketJson = JSON.parse(getBucketdata);
                let filterData = BucketJson.filter((item) => item.company_id != comp_url)
                // console.log("BucketJson-------------------->",BucketJson);
                filterData.push(currentScp);
                localStorage.setItem("SCPimprovementBucket", JSON.stringify(filterData))
            }
        });
    }
    const SCPhandleChange = (e, index) => {
        const { name, value } = e.target;
        const inputData = [...SCPimprovementData]
        inputData[index][name] = value;
        if (name == "work_area_name") {
            inputData[index].is_custom = 0;
            inputData[index].work_area_id = 0;
        }
        //if()
        let currentScp = {
            company_id: comp_url,
            scp_improvements_list: inputData
        }
        let getBucketdata = localStorage.getItem("SCPimprovementBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        // console.log("BucketJson-------------------->",BucketJson);
        filterData.push(currentScp);
        localStorage.setItem("SCPimprovementBucket", JSON.stringify(filterData))
        setSCPimprovementData(inputData)
    }
    // const handleUpdateEditor = (e, index) => {
    //     const { name, value } = e.target;
    //     const inputData = [...SCPimprovementData]
    //     inputData[index][name] = value;
    //     let currentScp = {
    //         company_id: comp_url,
    //         scp_improvements_list: inputData
    //     }
    //     let getBucketdata = localStorage.getItem("SCPimprovementBucket");
    //     let BucketJson = JSON.parse(getBucketdata);
    //     let filterData = BucketJson.filter((item) => item.company_id != comp_url)
    //     // console.log("BucketJson-------------------->",BucketJson);
    //     filterData.push(currentScp);
    //     localStorage.setItem("SCPimprovementBucket", JSON.stringify(filterData))
    //     setSCPimprovementData(inputData)
    // }
    const handleEditorchange = (editorText, name, index) => {
        const inputData = [...SCPimprovementData]
        inputData[index][name.targetElm.name] = editorText;
        let currentScp = {
            company_id: comp_url,
            scp_improvements_list: inputData
        }
        let getBucketdata = localStorage.getItem("SCPimprovementBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        // console.log("BucketJson-------------------->",BucketJson);
        filterData.push(currentScp);
        localStorage.setItem("SCPimprovementBucket", JSON.stringify(filterData))
        //  setSCPimprovementData(inputData)
    }
    const updateChanges = () => {
        setSCPupdatePopup(true)
    }
    const updateStatus = (status) => {
        if (status == "yes") {
            saveUpdateFunction();
        }
        else {
            setSCPupdatePopup(false)
        }
    }
    const saveUpdateFunction = async () => {
        setLoaderSave(true);
        let data = {
            company_id: comp_url,
            scp_improvements_list: SCPimprovementData
        }
        await api.updateSCPdata(data).then((res) => {
            if (res.status == 200 || res.status == 201) {
                let message = res.data.message;
                let getBucketdata = localStorage.getItem("SCPimprovementBucket");
                let BucketJson = JSON.parse(getBucketdata);
                let filterData = BucketJson.filter((item) => item.company_id != comp_url)
                localStorage.setItem("SCPimprovementBucket", JSON.stringify(filterData))
                getSCPimprovementData();
                setSCPupdatePopup(false)
                SetSCPUpdateMessage(true)
                setMessage(message)
                setTimeout(() => {
                    setLoaderSave(false);
                }, 1000)
            }
        }).catch((error) => {
            if (error.code != "ERR_NETWORK") {
                let errorCode = error.response.status;
                let errMsg = error.response.data.error.message;
                if (errorCode == 401) {
                    logout();
                }
                if (errorCode == 400 || errorCode == 500) {
                    setTimeout(() => {
                        setInternetError(errMsg)
                        setLoaderSave(false);
                    }, 1000)
                    setMessage(errMsg);
                    let errorLogData = {
                        api_name: "getscpimprovements",
                        error_code: errorCode,
                        error_message: errMsg
                    }
                    ErrorLog(errorLogData);
                }
            }
            else {
                setSCPupdatePopup(false)
                SetSCPUpdateMessage(true)
                setMessage(error.message + ". Please check your internet connection.")
            }
        })
    }
    const DeleteFunction = (index) => {
        setDeletePopup(true)
        setdeleteIndex(index)
    }
    const DeleteusingStatus = (status) => {
        if (status == "yes") {
            deleteSCPRow(deleteIndex)
        }
        else {
            setDeletePopup(false)
        }
    }
    const deleteSCPRow = (index) => {
        const inputData = [...SCPimprovementData]
        inputData[index].is_delete = 1
        let data = {
            "company_id": comp_url,
            "scp_improvements_list": inputData
        }
        let getBucketdata = localStorage.getItem("SCPimprovementBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)

        // console.log("BucketJson-------------------->",BucketJson);
        filterData.push(data);
        localStorage.setItem("SCPimprovementBucket", JSON.stringify(filterData))
        setDeletePopup(false)
        setSCPimprovementData(inputData)
    }
    const conformSaveObservation = () => {
        setConformObservation(true)
    }
    const conformSaveObservationstatus = (status) => {
        if (status == "yes") {
            showObservationFunction();
            setConformObservation(false)
        }
        else {
            setConformObservation(false)
        }
    }
    const showObservationFunction = () => {
        SetshowObservation(true)
    }

    const scpSidemenu = (e, index) => {
        e.stopPropagation()
        setScpSidemenuIndex(index)
    }
    const handleClickOutside = () => {
        setScpSidemenuIndex()
    }
    const LoaderFunc = () => {
        setLoaderTexteditor(false)
    }
    const [EditId, setEditId] = useState();
    const EditRowFunction = (indexId) => {
        setEditId()
    }
    const EditCloseRowFunction = (indexId) => {
        setEditId(indexId);
    }
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        getSCPimprovementData();
    }, [])

    return (
        <div className='scpImprovement'>
            <div
                className="container scrollCustom"
                style={{
                    height: "calc(100vh - 390px)",
                    overflowY: "auto",
                    overflowX: "hidden"
                }}>
                <div className="row clearfix">
                    <div className="col-md-12 column">
                        <table className={"table table-bordered" + " " + IARStyles.iarTable}>
                            <thead>
                                <tr>
                                    {/* {processColumnsArray.map((column, index) => (
                                        <th className="text-center" key={index}>{column}</th>
                                    ))} */}
                                    <th className="text-center">{t("WorkAreaorProcess")}</th>
                                    <th className="text-center">{t("SNo")}</th>
                                    <th className="text-center">{t("Descriptionofthepresent")}</th>
                                    <th className="text-center">{t("Descriptionofimprovement")}</th>
                                    <th className="text-center">{t("Descriptionandamount")}</th>
                                    <th className="text-center">{t("Investmentcosts")} {"("+Currency+")"}</th>
                                    <th className="text-center">{t("Simplepaybackperiod")}</th>
                                </tr>
                            </thead>
                            <tbody style={{ verticalAlign: "middle" }}>

                                {SCPimprovementData != null && SCPimprovementData.length > 0 ? SCPimprovementData.map((scpItem, index) => scpItem.is_delete == 0 ? <tr key={index}>
                                    <td style={{ width: "15%" }}>
                                        {scpItem.is_custom == 1 ? <span className={IARStyles.marked}></span> : null}
                                        <input
                                            type="text"
                                            name="work_area_name"
                                            className="form-control text-center"
                                            value={scpItem.work_area_name !== "null" && scpItem.work_area_name !== null ? scpItem.work_area_name : ''}
                                            onChange={(e) => SCPhandleChange(e, index)}
                                            readOnly
                                            style={{ border: "none", width: "136px" }}
                                        />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td style={{ width: "20%" }} className="EditorBorder">
                                        {EditId === index ? <>{LoaderTexteditor == true ? <img
                                            src={LoaderImage}
                                            alt="loader"
                                            style={{ width: "100px" }}
                                        /> : null}<Editor
                                                apiKey={tiny_key}
                                                onLoadContent={() => LoaderFunc()}
                                                className="EditorBorder"
                                                init={{
                                                    branding: false,
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'lists'
                                                      ],
                                                      toolbar: 
                                                      'bold italic underline| aligncenter ' +
                                                      'alignleft alignright alignjustify | bullist numlist| ',
                                                    content_style: 'body { font-family:Roboto, Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                                onEditorChange={(editor, name) => handleEditorchange(editor, name, index)}
                                                initialValue={scpItem.observation_name !== "null" || scpItem.observation_name != null ? scpItem.observation_name : ''}
                                                textareaName="observation_name"
                                            /></> : <span
                                                className="spanPtag"
                                                dangerouslySetInnerHTML={{ __html: scpItem.observation_name !== "null" || scpItem.observation_name != null ? scpItem.observation_name : '' }}></span>}

                                        {scpItem.gallery_json != null ? scpItem.gallery_json.map((itemlink, idx) =>
                                            <span className="imageName" key={idx}> Refer {itemlink.name}
                                                <span
                                                    onClick={() => removeGalleryList(scpItem.scp_improvement_options_id, itemlink.id)}
                                                > x Unlink Photo,</span></span>) : null}
                                    </td>
                                    <td style={{ width: "20%" }} className="EditorBorder">
                                        {EditId === index ? <>{LoaderTexteditor == true ? <img
                                            src={LoaderImage}
                                            alt="loader"
                                            style={{ width: "100px" }}
                                        /> : null} <Editor
                                                apiKey={tiny_key}
                                                onLoadContent={() => LoaderFunc()}
                                                init={{
                                                    branding: false,
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'lists'
                                                      ],
                                                      toolbar: 
                                                      'bold italic underline| aligncenter ' +
                                                      'alignleft alignright alignjustify | bullist numlist| ',
                                                    content_style: 'body { font-family:Roboto, Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                                onEditorChange={(editor, name) => handleEditorchange(editor, name, index)}
                                                initialValue={scpItem.recommendation != "null" && scpItem.recommendation != null ? scpItem.recommendation : ''}
                                                textareaName="recommendation"
                                            /></> : <span
                                                className="spanPtag"
                                                dangerouslySetInnerHTML={{ __html: scpItem.recommendation != "null" && scpItem.recommendation != null ? scpItem.recommendation : '' }}></span>}
                                    </td>
                                    <td style={{ width: "15%" }}>
                                        <input
                                            disabled={EditId === index ? false : true}
                                            type="text"
                                            name="amount_of_savings_benefits"
                                            className="form-control text-center bgcolorTransparent"
                                            value={scpItem.amount_of_savings_benefits !== "null" && scpItem.amount_of_savings_benefits !== null ? scpItem.amount_of_savings_benefits : ''}
                                            onChange={(e) => SCPhandleChange(e, index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            disabled={EditId === index ? false : true}
                                            type="text"
                                            name="investments_costs"
                                            className="form-control text-center bgcolorTransparent"
                                            value={scpItem.investments_costs !== "null" && scpItem.investments_costs !== null ? scpItem.investments_costs : ''}
                                            onChange={(e) => SCPhandleChange(e, index)}
                                        />
                                    </td>
                                    <td style={{ width: "15%" }}>
                                        <input
                                            disabled={EditId === index ? false : true}
                                            type="text"
                                            name="simple_payback_period"
                                            className="form-control text-center bgcolorTransparent"
                                            value={scpItem.simple_payback_period !== "null" && scpItem.simple_payback_period !== null ? scpItem.simple_payback_period : ''}
                                            onChange={(e) => SCPhandleChange(e, index)}
                                        />
                                        <p className="scpMenuWrapper">

                                            <label className="scpLabelPos"><button
                                                className="delete_btn threeDot"
                                                onClick={(e) => scpSidemenu(e, index)}> <img style={{ border: ScpSidemenuIndex == index ? "none" : "2px solid #FFDE9B" }} src={options} alt="options" width={'20px'} /></button></label>
                                            {ScpSidemenuIndex == index ? <span>
                                                <span><button className="delete_btn" onClick={() => galleryShowFunc(scpItem.scp_improvement_options_id, index)}> <img src={GalleryLinkIcon} alt="Remove" width={'20px'} /></button></span>
                                                <span>
                                                    <button
                                                        className="delete_btn"
                                                    >
                                                        {EditId === index ? <AiOutlineFileDone
                                                            style={{ fontSize: "20px", color: "rgba(0,0,0,0.5)", marginTop: "3px" }}
                                                            onClick={() => EditRowFunction(index)}
                                                        /> : <FiEdit
                                                            style={{ fontSize: "16px", color: "rgba(0,0,0,0.5)", marginTop: "3px" }}
                                                            onClick={() => EditCloseRowFunction(index)}
                                                        />}
                                                    </button>
                                                </span>
                                                <span><button className="delete_btn" onClick={() => DeleteFunction(index)}> <img src={Delete} alt="Remove" width={'20px'} /></button></span>
                                            </span> : null}
                                        </p>
                                    </td>

                                </tr> : null
                                ) : <tr style={{ textAlign: "center" }}><th style={{ border: "none", width: "156px" }}>{t("NoSCPrecorded")}</th></tr>}

                            </tbody>
                        </table>
                        <div>


                            <Modal className="galleryList newObservation" show={showObservation} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header closeButton>
                                    <Modal.Title><h2>{t("AddNewObservation")}</h2></Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <AddnewObservationDesk getSCPimprovementFunc={() => getSCPimprovementData()} closeAddNew={handleClose} SetshowObservation={SetshowObservation} />
                                </Modal.Body>

                            </Modal>


                        </div>
                        <Modal className="galleryList" show={showGallery} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                                <Modal.Title><h2>{t("ChoosePhotoLinked")}</h2></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Gallery
                                    companyId={comp_url}
                                    linkpropsFunction={getgalleryLink}
                                    firstname={FirstNameIndex}
                                />
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={scpUpdateShow} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{t("DoyouWantUpdate")}</h4>
                                    <button className="secondary_btn small_btn" onClick={() => updateStatus('no')}>{t("No")}</button>
                                    <button className="secondary_btn small_btn" onClick={() => updateStatus('yes')}>{t("Yes")}</button>
                                    {LoaderSave === true ? <div><img src={LoaderImage} alt="loader" width="130" /></div> : null}
                                    <div className="form-text text-danger">{InternetError}</div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={scpUpdateMessage} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    {LoaderSave === true ? <img src={LoaderImage} alt="loader" width="130" /> : <h4 className="mb-4">{message && message}</h4>}
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={deletePopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{t("DoyouWantDelete")}</h4>
                                    <button className="secondary_btn small_btn" onClick={() => DeleteusingStatus('no')}>{t("No")}</button>
                                    <button className="secondary_btn small_btn" onClick={() => DeleteusingStatus('yes')}>{t("Yes")}</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={conformObservation} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <div className="warningMsg" style={{ textAlign: "left" }}>{t("Alert")}</div>
                                    <div className="mb-4 warningMsgTwo" style={{ textAlign: "left" }}>{t("PleasemakeSureDatasave")}</div>
                                    <button className="secondary_btn small_btn" onClick={() => conformSaveObservationstatus('no')}>{t("No")}</button>
                                    <button className="secondary_btn small_btn" onClick={() => conformSaveObservationstatus('yes')}>{t("Yes")}</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={Errorpopup} onHide={() => setErrorpopup(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{InternetError}</h4>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>

            </div>
            <button
                className="secondary_btn"
                onClick={conformSaveObservation}
                style={{
                    backgroundColor: "#E4FFFE",
                    float: "left",
                    margin: "40px -10px -15px -10px",
                    padding: "8px 32px"
                }}
            >
                <FaPlus style={{ marginRight: "5px", marginBottom: "2px" }} />
                {t("AddNewObservation")}
            </button>
            <button
                className="secondary_btn"
                onClick={updateChanges}
                style={{
                    float: "right",
                    margin: "40px -10px -15px ",
                    padding: "8px 32px"
                }}
            >
                {t("Save")}
            </button>
        </div>
    );
}
export default SCPImprovement;