export default (state, action) => {
	const { payload, type } = action;

	switch (type) {
		case 'SET_MESSAGE':
			return {
				...state,
				message: payload,
			};

		case 'SET_COMPANY_EDIT_MODE':
			return {
				...state,
				companyEditIndex: payload,
			};

		case 'SET_CURRENT_OBSERVATION_ID':
			return {
				...state,
				currentObservationId: payload,
			};
		case 'SET_STE_ID':
			return {
				...state,
				steId: payload,
			};
		case 'SET_TC_ID':
			return {
				...state,
				tcId: payload,
			};
		case 'SET_STE_NAME':
			return {
				...state,
				steName: payload,
			};
		case 'SET_TC_NAME':
			return {
				...state,
				tcName: payload,
			};

		case 'SET_NETWORK_ERROR':
			return {
				...state,
				networkError: payload,
			};

		case 'CHANGE_LANGUAGE':
			return {
				...state,
				currentLanguageId: payload,
			};

		case 'CHANGE TABS':
			return {
				...state,
				currentTab: payload,
			};

		default:
			return state;
	}
};
