import React, { useState, useEffect } from 'react';
import './style.css';

const PrivacyPolicy = () => {
	const isMobile = window.innerWidth < 768;
	const [currentLanguage, setCurrentLanguage] = useState(2);
	useEffect(() => {
		if (localStorage.getItem('lanId')) {
			setCurrentLanguage(localStorage.getItem('lanId'));
		}
	}, []);

	return (
		<div className="deskWrapper">
			<div
				style={{ paddingLeft: !isMobile ? '80px' : '20px', marginTop: '20px' }}
			>
				<h2
					style={{
						color: '#3B7E80',
						fontSize: '24px',
						fontWeight: 'bold',
					}}
				>
					{currentLanguage == 2
						? 'Privacy Policy'
						: 'Политика конфиденциальности'}
				</h2>
				<div style={{ padding: !isMobile ? '15px 15px 15px 70px' : '10px' }}>
					{currentLanguage == 2 ? (
						<>
							<section className="section">
								<h4 className="heading">1. Acceptance</h4>
								<p className="paragraph">
									This privacy policy (“privacy policy”) and related terms of
									use shall govern the use by you of this REAP Digital Solution
									(REAP DS).
									<br />
									<b>
										If you do not agree to this privacy policy and the related
										terms and conditions, you must not use the REAP Digital
										Solution.
									</b>
								</p>
							</section>
							<section className="section" style={{ marginBottom: '20px' }}>
								<h5></h5>
							</section>
							<section className="section">
								<h4 className="heading">
									2. Definition of Personal Information
								</h4>
								<p className="paragraph">
									Personal information will include your name, e-mail address,
									country of residence, phone number (“personal data”) and any
									other information that can directly be identified and linked
									with your name, email id and/or phone number and has been
									submitted by you on or through this web based solution.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">
									3. Why do we collect the above defined Personal Information?
								</h4>
								<p className="paragraph">
									When you use this web based solution, we collect data relating
									to the user and usage activity in this solution. This
									information helps us in many ways to improve your experience
									of using the REAP DS. Additionally, aggregated data that
									contains no reference to any individual personal information
									may be extracted for internal analysis by the Organizers. We
									do not share your personal information with anyone else.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">4. Security</h4>
								<p className="paragraph">
									We use modern globally popular technologies to give our users
									a very safe and secure experience on the web-based solution.
									However, we are not responsible in any way for your privacy on
									all other digital properties from where you may come to this
									web based solution (REAP DS) or to where you may go to after
									using this web-based solution.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">5. General Disclaimer</h4>
								<p className="paragraph">
									The REAP DS is hosted and made available from India. In the
									event you access REAP DS from other jurisdictions, you are
									solely responsible for complying with the local law as it may
									be applicable.
								</p>
							</section>
						</>
					) : (
						<>
							<section className="section">
								<h4 className="heading">1. Согласие</h4>
								<p className="paragraph">
									Политика конфиденциальности («политика конфиденциальности ») и
									соответствующие условия использования регулируют использование
									вами данного цифрового решения REAP (REAP ЦР).
								</p>
							</section>
							<section className="section" style={{ marginBottom: '20px' }}>
								<h4 className="heading">
									<b>
										Если вы не согласны с этой политикой конфиденциальности и
										соответствующими условиями, вы не должны использовать
										Цифровое Решение REAP.
									</b>
								</h4>
							</section>
							<section className="section">
								<h4 className="heading">2. Определение личной информации </h4>
								<p className="paragraph">
									Личная информация будет включать ваше имя, адрес электронной
									почты, страну проживания, номер телефона (« личные данные ») и
									любую другую информацию, которая может быть непосредственно
									идентифицирована и связана с вашим именем, идентификатором
									электронной почты и/или номер телефона и был отправлен вами
									через это веб-решение.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">
									3. Почему мы собираем указанные выше Персональные данные?
								</h4>
								<p className="paragraph">
									Когда вы используете это веб-решение, мы собираем данные о
									пользователе и активности использования этого решения. Эта
									информация помогает нам во многих отношениях улучшить ваш опыт
									использования REAP ЦР. Кроме того, агрегированные данные, не
									содержащие ссылки на какую-либо личную информацию, могут быть
									извлечены Организаторами для внутреннего анализа. Мы не
									передаем вашу личную информацию никому другому.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">4. Безопасность</h4>
								<p className="paragraph">
									Мы используем современные всемирно популярные технологии,
									чтобы предоставить нашим пользователям очень безопасную и
									надежную работу с веб-решением. Тем не менее, мы никоим
									образом не несем ответственности за вашу конфиденциальность в
									отношении всех других цифровых свойств, откуда вы можете
									перейти к этому веб-решению (REAP ЦР) или куда вы можете
									перейти после использования этого веб-решения.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">5. Общий отказ от ответственности </h4>
								<p className="paragraph">
									REAP ЦР размещается и предоставляется из Индии. Если вы
									получаете доступ к REAP ЦР из других юрисдикций, вы несете
									единоличную ответственность за соблюдение местного
									законодательства, если оно применимо.
								</p>
							</section>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
