// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_headerText__zVyxh{
    color: #3B7E80;
    font-weight: bold;
}
.style_label__GLZo9{
    font-weight: 700;
    font-size: 22px;
    color: #1E989A;
}
.style_passWordStrongList__1yDcx{
    padding: 0px;
}
.style_passWordStrongList__1yDcx li{
    font-family: 'Roboto';
    position: relative;
    padding: 4px 0px;
    font-size: 12px;
} 
.style_passWordStrongList__1yDcx li span:nth-child(2){
    margin-top: 0px;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 0px;
}
.style_secondary_btnTwo__182uK{
    background-color: #ffffff;
    color: #1E989A;
    border: #8a9192 1px solid;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 6px;
    font-weight: 500;
    line-height: 1.5;
    margin: 2px;
    position: relative;
    display: inline-block;
    text-align: center;
    min-width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChangePassword/style.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;AACA;IACI,YAAY;AAChB;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,QAAQ;AACZ;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".headerText{\r\n    color: #3B7E80;\r\n    font-weight: bold;\r\n}\r\n.label{\r\n    font-weight: 700;\r\n    font-size: 22px;\r\n    color: #1E989A;\r\n}\r\n.passWordStrongList{\r\n    padding: 0px;\r\n}\r\n.passWordStrongList li{\r\n    font-family: 'Roboto';\r\n    position: relative;\r\n    padding: 4px 0px;\r\n    font-size: 12px;\r\n} \r\n.passWordStrongList li span:nth-child(2){\r\n    margin-top: 0px;\r\n    font-size: 20px;\r\n    position: absolute;\r\n    right: 0;\r\n    top: 0px;\r\n}\r\n.secondary_btnTwo{\r\n    background-color: #ffffff;\r\n    color: #1E989A;\r\n    border: #8a9192 1px solid;\r\n    padding: 8px 15px;\r\n    font-size: 14px;\r\n    border-radius: 6px;\r\n    font-weight: 500;\r\n    line-height: 1.5;\r\n    margin: 2px;\r\n    position: relative;\r\n    display: inline-block;\r\n    text-align: center;\r\n    min-width: 80px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerText": `style_headerText__zVyxh`,
	"label": `style_label__GLZo9`,
	"passWordStrongList": `style_passWordStrongList__1yDcx`,
	"secondary_btnTwo": `style_secondary_btnTwo__182uK`
};
export default ___CSS_LOADER_EXPORT___;
