// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash_splash__w5b4I{
    min-height: calc(100vh - 109px);
    display: flex;
    align-items: center;
    padding: 50px 76px;
}
.splash_splash__w5b4I img{
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/mobileSplash/splash.module.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB","sourcesContent":[".splash{\r\n    min-height: calc(100vh - 109px);\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 50px 76px;\r\n}\r\n.splash img{\r\n    max-width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"splash": `splash_splash__w5b4I`
};
export default ___CSS_LOADER_EXPORT___;
