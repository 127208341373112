import React, { useEffect } from 'react';
import { Document,Page, Text, View, Image, Font } from "@react-pdf/renderer";
import FrontPage from '../pdf/FrontPage/FrontPage';
import ExecutiveSummeryPDF from '../pdf/ExecutiveSummery/ExecutiveSummery';
import ProcessFlowPDF from '../pdf/ProcessFlow/ProcessFlow';
import GalleryPDF from '../pdf/Gallery/Gallery';
import NotePDF from '../pdf/Note/Note';
import Html from 'react-pdf-html';
import Header from '../../assets/pdf_images/Header.png';
import RobotoRegular from '../../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../assets/fonts/Roboto-Bold.ttf';
import Footer from '../../assets/pdf_images/Footer.png'
//import Burmese from '../../assets/fonts/Padauk-Regular.ttf'
Font.register({
    family: 'Roboto',
    fonts: [
        { src: RobotoRegular, fontWeight: 'normal' },
        { src: RobotoBold, fontWeight: 'bold' }     
    ]
});
const MyDocument = ({company,executiveData,scpImrovment,pdfDataProcessflow,gallery,tcname,userSignature}) => {
    const pageStyle = {
        paddingTop: 5,
        paddingBottom: 80,
        paddingHorizontal: 40,
    };
    const ColorLightBlue = {
        color: "#1E989A"
    }
    const tableStyle = {
        display: "table",
        width: "auto"
    };

    const tableRowStyle = {
        flexDirection: "row"
    };

    const firstTableColHeaderStyle = {
        width: "20%",
        borderStyle: "solid",
        borderColor: "#000",
        borderBottomColor: "#000",
        borderWidth: 1,
        backgroundColor: "#1E989A",
        justifyContent: 'center',
        alignItems: 'center',
    };

    const tableColHeaderStyle = {
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: "solid",
        borderColor: "#000",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        backgroundColor: "#1E989A",
        fontSize: "10px",
        justifyContent: 'center',
        alignItems: 'center',
    };

    const firstTableColStyle = {
        width: "20%",
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        borderTopWidth: 0,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: "10px",
        paddingBottom: "10px",
        fontFamily:'Roboto'
    };

    const tableColStyle = {
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily:'Roboto'
    };

    const tableCellHeaderStyle = {
        textAlign: "center",
        margin: 4,
        fontSize: 10,
        fontFamily:"Roboto",
        fontWeight: "bold",
        alignItem: "center",
        color: "#fff",
        lineHeight:"1.4px",
    };

    const tableCellStyle = {
        textAlign: "center",
        margin: 5,
        fontSize: 10,
        fontFamily:'Roboto'
    };
    const FooterStyle = {
        position: 'absolute',
        fontSize: 12,
        bottom: "10px",
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey'
    };
    const pageNumberstyle = {
        position: 'absolute',
        fontSize: 12,
        bottom: "0px",
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor:"#1e989a",
        height:"16px",
        flexDirection:"row",
        alignItems:"center",
        textAlign:"center",
    }
    const stylesheet = {
        // clear margins for all <p> tags
        p: {
          margin: 0,
          fontSize:10,
          paddingLeft:5,
          paddingRight:5,
          fontFamily:"Roboto"
        },
        li:{
            paddingLeft:0,
            fontSize:10,
            paddingRight:10,
            marginRight:10,
            fontFamily:'Roboto'
        },
        ul:{
            padding:0,
            fontFamily:'Roboto'
        },
        ol:{
            paddingLeft:0,
            fontFamily:'Roboto'
        },
        strong:{
            fontFamily:"Roboto",
            fontWeight:"bold",
            fontSize:10
        },
        span:{
            paddingLeft:0,
            fontSize:10,
            paddingRight:10,
            marginRight:10,
            fontFamily:'Roboto'
        },
        em:{
            paddingLeft:0,
            fontSize:10,
            paddingRight:10,
            marginRight:10,
            fontFamily:'Roboto',
        }
        // ['.special']: {
        //   backgroundColor: 'pink',
        // },
      };

    return(
        <Document>
            <FrontPage company = {company}/>
            <ExecutiveSummeryPDF executiveData={executiveData} company = {company}/>
            <ProcessFlowPDF pdfDataProcessflow={pdfDataProcessflow} company={company} />
            <Page
            size="A4"
            orientation="landscape"
            style={pageStyle}
        >
            <View fixed><Image src={Header} /></View>
            <View
                style={{
                    color: ColorLightBlue.color,
                    flexDirection: 'row',
                    paddingBottom: "10px"
                }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: "12px" }}>{"3. "} </Text>
                <Text style={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "12px" }}>SCP Improvement Options</Text>
            </View>
            <View style={{ paddingTop: "3px"}}>
                <View style={tableRowStyle} fixed>
                    <View style={firstTableColHeaderStyle}>
                        <Text style={tableCellHeaderStyle}>Work Area or Process</Text>
                    </View>
                    <View style={{ ...tableColHeaderStyle, width: "10%" }}>
                        <Text style={tableCellHeaderStyle}>S.No.</Text>
                    </View>
                    <View style={{ ...tableColHeaderStyle, width: "28%" }}>
                        <Text style={tableCellHeaderStyle}>Description of the present situation and the problem observed<Text style={{verticalAlign:"super",fontSize:"7px"}}>1</Text></Text>
                    </View>
                    <View style={{ ...tableColHeaderStyle, width: "28%" }}>
                        <Text style={tableCellHeaderStyle}>Description of improvement action</Text>
                    </View>
                    <View style={{ ...tableColHeaderStyle, width: "15%" }}>
                        <Text style={tableCellHeaderStyle}>Description and amount of the savings and other benefits<Text style={{verticalAlign:"super",fontSize:"7px"}}>2</Text></Text>
                    </View>
                    <View style={{ ...tableColHeaderStyle, width: "15%" }}>
                        <Text style={tableCellHeaderStyle}>Investment costs<Text style={{verticalAlign:"super",fontSize:"7px"}}>3</Text></Text>
                    </View>
                    <View style={{ ...tableColHeaderStyle, width: "15%" }}>
                        <Text style={tableCellHeaderStyle}>Simple payback period<Text style={{verticalAlign:"super",fontSize:"7px"}}>4</Text></Text>
                    </View>
                </View>
                {scpImrovment && scpImrovment.map((item, index) => <View 
                style={tableRowStyle} key={index}

                >
                    <View style={firstTableColStyle}>
                        <Text style={tableCellStyle} >{item.work_area_name}</Text>
                    </View>

                    <View style={{ ...tableColStyle, width: "10%" }}>
                        <Text style={tableCellStyle}>{index + 1}</Text>
                    </View>
                    <View style={{ ...tableColStyle, width: "28%",paddingLeft:5,paddingRight:5,paddingTop:5}}>
                          <Html style={{fontSize:10}} stylesheet={stylesheet} >{item.observation_master_name }</Html>
                    </View>
                    <View style={{ ...tableColStyle, width: "28%",paddingLeft:5,paddingRight:5,paddingTop:5 }}>
                         <Html style={{fontSize:10}} stylesheet={stylesheet} >{item.recommendation}</Html>
                    </View>
                    <View style={{ ...tableColStyle, width: "15%" }}>
                        <Text style={tableCellStyle}>{item.amount_of_savings_benefits}</Text>
                    </View>
                    <View style={{ ...tableColStyle, width: "15%" }}>
                        <Text style={tableCellStyle}>{item.investments_costs}</Text>
                    </View>
                    <View style={{ ...tableColStyle, width: "15%" }}>
                        <Text style={tableCellStyle}>{item.simple_payback_period}</Text>
                    </View>
                </View>)}
                <View fixed style={{borderBottom:1,marginTop:"-1px"}}></View>
            </View>
            <View style={FooterStyle} fixed><Image src={Footer} /></View>
            <View style={pageNumberstyle} fixed><Text style={{color:"#fff",fontSize:9,width:"100%",textAlign:"center"}} 
               render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )}
            /></View>
        </Page>
            <NotePDF tcname={tcname} company={company} userSignature={userSignature} />
            <GalleryPDF gallery={gallery}/>
        </Document>
        
    )
}
export default MyDocument