import React from 'react';
import TCStyles from './database.module.css';
import ToggleButtonStatus from './ToggleButton/ToggleButtonStatus';
import ToggleButtonCustom from './ToggleButton/ToggleButtonCustom';

const Row = ({
	resource,
	type,
	name,
	id,
	span,
	status,
	themeArray,
	setThemeArray,
}) => {
	return (
		<tr className={TCStyles.tr}>
			<td className={TCStyles.td} colSpan={span}>
				{resource?.[name]}
			</td>
			{type == 'observation' && (
				<td className={TCStyles.td} colSpan={span} style={{ margin: '0 10px' }}>
					{resource?.observation_master_recommendation}
				</td>
			)}
			<td className={TCStyles.td} colSpan={span}>
				<ToggleButtonStatus
					themeArray={themeArray}
					setThemeArray={setThemeArray}
					active="Inactive"
					inActive="Active"
					status={resource?.[status]}
					id={resource?.[id]}
					type={type}
					themeId={id}
					themeStatus={status}
				/>
			</td>
			<td className={TCStyles.td} colSpan={span}>
				<ToggleButtonCustom
					themeArray={themeArray}
					setThemeArray={setThemeArray}
					active="Master"
					inActive="Custom"
					isCustom={resource?.is_custom}
					id={resource?.[id]}
					type={type}
					recommendation={resource?.observation_master_recommendation}
					themeId={id}
				/>
			</td>
		</tr>
	);
};

export default Row;
