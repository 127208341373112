import React, { useState } from 'react';
import TCStyles from './tc.module.css';
import ViewTcDetails from './ViewTcDetails';
import produce from 'immer';
import isEqual from 'lodash/isEqual';

const TcTable = ({ index, tc }) => {
	const [viewIndex, setViewIndex] = useState(null);

	const viewDetailsBTN = (index) => {
		viewIndex == index ? setViewIndex(null) : setViewIndex(index);
	};
	return (
		<React.Fragment key={index}>
			<tr className={index % 2 == 0 ? TCStyles.trEven : TCStyles.trOdd}>
				<td className={TCStyles.td}>{tc?.user_first_name}</td>
				<td className={TCStyles.td}>{tc?.region_name}</td>
				<td className={TCStyles.td}>{tc?.country_name}</td>
				<td className={TCStyles.td}>{tc?.user_email}</td>
				<td className={TCStyles.td}>{tc?.user_contact}</td>
				<td className={'text-center' + ' ' + TCStyles.steRow}>
					<span>{tc?.total_Company}</span>
				</td>
				<td className={TCStyles.td}>
					<button
						onClick={() => viewDetailsBTN(index)}
						className={TCStyles.viewDetails}
					>
						{viewIndex == index ? 'Hide ' : 'View '} Details
					</button>
				</td>
			</tr>

			{viewIndex == index ? (
				<tr className={TCStyles.tr}>
					<td colSpan={7}>
						<ViewTcDetails id={tc?.user_id} />
					</td>
				</tr>
			) : null}
		</React.Fragment>
	);
};

export const MemoizedTcRow = React.memo(TcTable, (prevProps, nextProps) => {
	const previousPropsCopy = produce(prevProps, (draft) => {});
	// Compare the previousPropsCopy with the next props
	const isEqualObjects = isEqual(previousPropsCopy, nextProps);

	if (isEqualObjects) {
		return true;
	}
	return false;
});
