import React, { useState, useEffect } from 'react';
import Style from './viewprofile.module.css';
import DataService from '../../services/DataServices';
import AuthUser from '../authUser/authuser';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import uploadSign from '../uploadSignature/uploadSign.module.css';
import UploadNew from '../../assets/images/UploadNew.png';
import LoaderImg from '../../assets/images/loadin_gif.gif';
import { useLanguageChange } from '../customHooks/lan_hook';
import { useTranslation } from 'react-i18next';
import { ErrorLog } from '../erroLog/errorLog';
import Compressor from 'compressorjs';

const ViewProfile = () => {
	const { t } = useTranslation();
	useLanguageChange();
	const { logout } = AuthUser();
	const api = new DataService();
	const [currentTc, setCurrentTc] = useState('');
	const [userSigImg, setUserSigImg] = useState([]);
	const [Loader, setLoader] = useState(false);
	const navigate = useNavigate();
	const [Message, setMessage] = useState();
	const [images, setImages] = useState([]);
	const [savePopup, setSavePopup] = useState(false);
	const [ImageSizeError, setImageSizeError] = useState('');
	const [ErrorMessage, SetErrorMessage] = useState(false);
	const [picture, setPicture] = useState(null);
	const [imgData, setImgData] = useState(null);

	const handleImageUpload = (imageList) => {
		let imageFile = imageList.target.files[0];
		let megaBytes = 1500000;
		setLoader(true);
		new Compressor(imageFile, {
			quality: 0.8,
			success: (compressedResult) => {      
			  let FileSize =compressedResult.size;
			  if (FileSize < megaBytes) {
				if (compressedResult) {
					setPicture(compressedResult);
					const reader = new FileReader();
					reader.addEventListener("load", () => {
	
						setTimeout(() => {
							setLoader(false);
							setImgData(reader.result);
						}, 2000);
					});
					reader.readAsDataURL(compressedResult);
				}
			}
			else {
				setTimeout(() => {
					setLoader(false);
					setImageSizeError(t("imageSizevalidationOneMB"))
				}, 2000);
			}
			},
		  });
		setLoader(true);
		setMessage('');
		setImageSizeError('')
	};
	const handleClose = () => {
		setSavePopup(false);
	};
	const saveSignatureStatus = (status) => {
		if (status == 'yes') {
			saveSignature();
			setSavePopup(false);
			setTimeout(() => {
				setLoader(false);
			}, 2000);
		} else {
			setSavePopup(false);
		}
	};
	const saveSignature = async () => {
		setLoader(true);
		let data = {
			user_signature: imgData,
		};
		await api
			.uploadsignature(data)
			.then((response) => {
				if (response.status == 200 || response.status == 201) {
					let res = response.data;
					setTimeout(() => {
						setMessage(res.message);
						setLoader(false);
						getUserInfo();
						setImgData(null)
					}, 1000);
				}
			})
			.catch((error) => {
				if (error.code !== "ERR_NETWORK") {
					let errorCode = error.response.status;
					let message = error.response.data.error.message;
					if (errorCode == 400||errorCode == 500) {
						setLoader(true);
						setTimeout(() => {
							setMessage(message);
							setLoader(false);
						}, 2000);
						let errorLogData ={
							api_name:"uploadsignature",
							error_code:errorCode,
							error_message:message
						} 
						ErrorLog(errorLogData);
					}
					if (errorCode == 401) {
						logout();
					}
				}
				else {
					SetErrorMessage(true)
					setMessage("Please check your internet connection.")
				}
			});
	};
	async function getUserInfo() {
		try {
			const result = await api.getTcDetails();
			setUserSigImg(result.data.user_signature);
			setCurrentTc(result.data.companyList[0]);
		} catch (error) {
			if (error.code !== "ERR_NETWORK") {
				let message = error.response.data.error.message;
				let errorCode = error.response.status;
				if (errorCode == 401) {
					logout();
				}
				if (errorCode == 400||errorCode == 500) {
					let errorLogData ={
						api_name:"viewtcprofile",
						error_code:errorCode,
						error_message:message
					} 
					ErrorLog(errorLogData);
				}
			}
		}
	}
	useEffect(() => {
		getUserInfo();
	}, []);
	return (
		<div className="deskWrapper">
			<div className="container px-0">
				<div className={Style.wraper}>
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-lg-7">
								<div className="row">
									<div>
										<div className={Style.subtxt}>
											<p>{t("ProfileDetails")}</p>
										</div>

										<p className={Style.name}>{currentTc?.user_first_name}</p>
										<div className={Style.details}>
											<div>
												<p className={Style.design}>{currentTc?.role_name}</p>
												<p className={Style.cntry}>
													{currentTc?.region_name},&nbsp;
													{currentTc?.country_name}
												</p>
											</div>
											<div>
												<p style={{ marginBottom: '7px' }}>
													<span className={Style.email}>{t("Email")}:</span>
													<span className={Style.emailaddrs}>
														{currentTc.user_email}
													</span>
												</p>
												<p>
													<span className={Style.phone}>{t("PhoneNumber")}:</span>
													<span className={Style.phonenum}>
														{currentTc?.user_contact != 'null'
															? currentTc.user_contact
															: 'Not Provided'}
													</span>
												</p>
											</div>
										</div>
										<p className={Style.sign}>{t("Signature")}</p>
										{imgData == null ? <div style={{ marginBottom: "10px" }}>

											{userSigImg ? (
												<div className={Style.signatureImg}>
													{images.length == 0 ? <img src={userSigImg} alt="signature" /> : null}
												</div>
											) : null}
										</div> : null}
										<div>
											<div className="container">
												<div className="row">
													<div className="col-md-6 col-lg-6 px-0">
														<div className="row">
															<div className='col-12 px-2'>
																<label className={"secondary_date_btn" + " " + uploadSign.signature}>
																	<input
																		type="file"
																		name="file-uploader"
																		onInput={(e) => handleImageUpload(e)}
																		style={{ display: "none" }}
																		accept='.jpg,.png'
																		value = ''
																	/>
																	<img src={UploadNew} alt="UploadNew" />
																	{t("UploadSignatureBtn")}
																</label>
															</div>
															{imgData != null ? <div>
																<img src={imgData} alt="prev" style={{ marginTop: "10px", width: "200px" }} />
																<div style={{ marginTop: "10px" }}><button
																	onClick={() => setSavePopup(true)}
																	className="secondary_btn"
																>{t("SaveBtn")}</button>
																	<button
																		onClick={() => {
																			setImgData(null);
																		}}
																		className="secondary_btn"
																	>
																		{t("Cancel")}
																	</button>
																</div>
															</div> : null}
															<div className="form-text text-danger">
																{ImageSizeError && ImageSizeError}
															</div>
															{Loader == true ? (
																<img
																	src={LoaderImg}
																	alt="loader"
																	style={{ width: '200px'}}
																/>
															) : null}
															<div
																style={{
																	marginTop: '8px'
																}}
															>
																{Message ? Message : null}
															</div>
														</div>
													</div>
												</div>


											</div>
											<Modal
												className="newObservation removeConform"
												show={savePopup}
												onHide={handleClose}
												aria-labelledby="contained-modal-title-vcenter"
												centered
											>
												<Modal.Header closeButton></Modal.Header>
												<Modal.Body>
													<div className="P-3 pt-0 text-center">
														<h4 className="mb-4">{t("DoyouWantsaveConform")}</h4>
														<button
															className="secondary_btn small_btn"
															onClick={() => saveSignatureStatus('no')}
														>
															{t("No")}
														</button>
														<button
															className="secondary_btn small_btn"
															onClick={() => saveSignatureStatus('yes')}
														>
															{t("Yes")}
														</button>
													</div>
												</Modal.Body>
											</Modal>
											<Modal className="newObservation removeConform" show={ErrorMessage} onHide={() => SetErrorMessage(false)} aria-labelledby="contained-modal-title-vcenter" centered>
												<Modal.Header closeButton>
												</Modal.Header>
												<Modal.Body>
													<div className="P-3 pt-0 text-center">
														<h4 className="mb-4">{Message && Message}</h4>
													</div>
												</Modal.Body>
											</Modal>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ViewProfile;
