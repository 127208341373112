// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prev{
font-size: 18px;
}
#someId{
    font-size: 40px;
    background-color: none;
}
.prev-icon{
    font-size: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/components/ActivityLog/Pagination.css"],"names":[],"mappings":"AAAA;AACA,eAAe;AACf;AACA;IACI,eAAe;IACf,sBAAsB;AAC1B;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".prev{\r\nfont-size: 18px;\r\n}\r\n#someId{\r\n    font-size: 40px;\r\n    background-color: none;\r\n}\r\n.prev-icon{\r\n    font-size: 24px !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
