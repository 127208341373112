// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: #FFFCF4;
    
}
.input{

         border-top: none !important;
         border-left: none !important;
         border-right: none !important;
        border-bottom: 1px solid gray !important;
       color: gray;
        background-color: #FFFCF4;
        max-width: 200px;
        overflow: hidden;
        
}
.input:focus {
    outline: none;
}
.box{
    flex: 1 1;
    text-align: left;
}
.label{
    font-weight: bold;
    color: gray;
white-space: nowrap;
width:100%;
 max-width: 200px ;

}

.headingBold{
    color:black
}
.phone-code {
 
    color: #999;
    pointer-events: none;
    /* prevent clicking on the code */
}`, "",{"version":3,"sources":["webpack://./src/components/admin/components/EditCompany/editCompany.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,sBAAsB;IACtB,yBAAyB;;AAE7B;AACA;;SAES,2BAA2B;SAC3B,4BAA4B;SAC5B,6BAA6B;QAC9B,wCAAwC;OACzC,WAAW;QACV,yBAAyB;QACzB,gBAAgB;QAChB,gBAAgB;;AAExB;AACA;IACI,aAAa;AACjB;AACA;IACI,SAAO;IACP,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,WAAW;AACf,mBAAmB;AACnB,UAAU;CACT,iBAAiB;;AAElB;;AAEA;IACI;AACJ;AACA;;IAEI,WAAW;IACX,oBAAoB;IACpB,iCAAiC;AACrC","sourcesContent":[".mainContainer{\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    flex-direction: column;\r\n    background-color: #FFFCF4;\r\n    \r\n}\r\n.input{\r\n\r\n         border-top: none !important;\r\n         border-left: none !important;\r\n         border-right: none !important;\r\n        border-bottom: 1px solid gray !important;\r\n       color: gray;\r\n        background-color: #FFFCF4;\r\n        max-width: 200px;\r\n        overflow: hidden;\r\n        \r\n}\r\n.input:focus {\r\n    outline: none;\r\n}\r\n.box{\r\n    flex: 1;\r\n    text-align: left;\r\n}\r\n.label{\r\n    font-weight: bold;\r\n    color: gray;\r\nwhite-space: nowrap;\r\nwidth:100%;\r\n max-width: 200px ;\r\n\r\n}\r\n\r\n.headingBold{\r\n    color:black\r\n}\r\n.phone-code {\r\n \r\n    color: #999;\r\n    pointer-events: none;\r\n    /* prevent clicking on the code */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
