import React, { useEffect, useState } from 'react';
import pfdObTabStyles from './pfdObTab.module.css';
import remove from '../../assets/images/trash.png';
import addNew from '../../assets/images/addNew.png';
import { Link } from 'react-router-dom';
import DataService from '../../services/DataServices';
import { useLocation } from 'react-router-dom';
import notes from '../../assets/images/notes.png';
import gallery from '../../assets/images/gallery.png';
import Modal from 'react-bootstrap/Modal';
import AuthUser from '../authUser/authuser';
import {RiDeleteBin5Fill} from "react-icons/ri";
import { useTranslation } from 'react-i18next'
import { useLanguageChange } from '../customHooks/lan_hook';
import { ErrorLog } from '../erroLog/errorLog';

const ObservationMob = () => {
    const { logout } = AuthUser();
    const { t } = useTranslation();
    useLanguageChange();
    const api = new DataService();
    let currentLocation = useLocation();
    let pathName = currentLocation.pathname;
    let comp_url = pathName.substring(pathName.lastIndexOf('/') + 1);
    const [observationData, setObservationData] = useState();
    const [deletePopup, setDeletePopup] = useState(false);
    const [indexId, setIndexId] = useState();
    const [successMessage, setSuccessMessage] = useState(false);
    const [Message, setMessage] = useState();
    const [errorMessage,setErrormessage] = useState(false);
    const handleClose = () => {
        setDeletePopup(false);
        setSuccessMessage(false);
        setErrormessage(false);
        setMessage('');
    }
    const GetObservationFunction = async () => {
        let getBucketdata = localStorage.getItem("obsevationBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filteredBucket = BucketJson && BucketJson.filter((observation) => observation.company_id == comp_url);
        if (filteredBucket == null || filteredBucket.length == 0) {
            let data = {
                "company_id": comp_url
            }
            try{
                await api.getObservationDataById(data).then((response) => {
                    let obsData = {
                        company_id: response.data.company_id,
                        observation_json: response.data.observation_json
                    }
                    if (response.status == 200 || response.status == 201) {
                        setObservationData(response.data.observation_json)
                        let observationBucketArray = [];
                        observationBucketArray.push(obsData)
                        localStorage.setItem("obsevationBucket", JSON.stringify(observationBucketArray))
                        if (filteredBucket != null) {
                            let observationBucketArray = [...BucketJson];
                            observationBucketArray.push(obsData)
                            localStorage.setItem("obsevationBucket", JSON.stringify(observationBucketArray))
                            setObservationData(obsData.observation_json)
                        }
                        else {
                            let observationBucketArray = [];
                            observationBucketArray.push(obsData)
                            localStorage.setItem("obsevationBucket", JSON.stringify(observationBucketArray))
                            setObservationData(obsData.observation_json)
                        }
                    }
                })
            }
            catch(error){
                if(error.code !== "ERR_NETWORK"){
                    let errorCode = error.response.status;
                    let errormsg = error.response.data.error.message;
                    if (errorCode == 401) {
                        logout();
                    }
                    if (errorCode == 400 || errorCode == 500) {
                        setErrormessage(true);
                        setMessage(errormsg);
                        let errorLogData ={
                            api_name:"getobservations",
                            error_code:errorCode,
                            error_message:errormsg
                        } 
                        ErrorLog(errorLogData);
                    }
               }
            }
        }
        else {
            let filteredBucketTwo = BucketJson && BucketJson.filter((company) => company.company_id == comp_url);
            setObservationData(filteredBucketTwo[0].observation_json)
        }
    }
    const DeleteObservation = (indexId) => {
        setIndexId(indexId)
        deleteObservationAndsave()
    }

    const deleteObservationAndsave = () => {
        setDeletePopup(true)
    }
    const saveDeleteObservation = (status) => {
        if (status == "yes") {
            let Observation = [...observationData];
            Observation[indexId].is_delete = 1;
            let data = {
                "company_id": comp_url,
                "observation_json": observationData
            }
            let getBucketdata = localStorage.getItem("obsevationBucket");
            let BucketJson = JSON.parse(getBucketdata);
            let filterData = BucketJson.filter((item) => item.company_id != comp_url)
            filterData.push(data);
            localStorage.setItem("obsevationBucket", JSON.stringify(filterData))
            setObservationData(Observation);
            setDeletePopup(false)
            saveObservation();
        }
        else {
            setDeletePopup(false)
        }
    }
    const saveObservation = async () => {
        let data = {
            company_id: comp_url,
            observation_json: observationData
        }
        await api.deleteObservation(data).then((response) => {
            if (response.status == 200 || response.status == 201) {
                let getBucketdata = localStorage.getItem("obsevationBucket");
                let BucketJson = JSON.parse(getBucketdata);
                let filterData = BucketJson.filter((item) => item.company_id != comp_url)
                localStorage.setItem("obsevationBucket", JSON.stringify(filterData))
                GetObservationFunction()
                setSuccessMessage(true);
                setMessage(response.data.message);
            }
        }).catch((error) => {
            if(error.code !== "ERR_NETWORK"){
                let errormsg = error.response.data.error.message;
                let errorCode = error.response.status;
                if (errorCode == 401) {
                    logout();
                }
                if (errorCode == 400 || errorCode == 500) {
                    setSuccessMessage(true);
                    setMessage(errormsg);
                    let errorLogData ={
                        api_name:"deleteObservations",
                        error_code:errorCode,
                        error_message:errormsg
                    } 
                    ErrorLog(errorLogData);
                }
            }
            else{
                setSuccessMessage(true);
                setMessage(error.message+". Please check your internet connection.");
            }
        })
    }
    useEffect(() => {
        GetObservationFunction()
    }, [])
    return (
        <div className="">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 pt-3 pb-2">
                        <Link
                            to={"/addNewObservation/" + comp_url}
                            className={"success_btn" + " " + pfdObTabStyles.addnewcomp}
                            style={{ display: "block" }}
                        >
                            <img src={addNew} alt="add New" />
                           {t("AddNewObservation")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className={pfdObTabStyles.recordedObservations}>
                <div className="container">
                    <div className="row">
                        <div className={"col-md-12"+" "+" "+pfdObTabStyles.obsHeight} style={{height:"calc(100vh - 368px)"}}>
                            <h3 className={pfdObTabStyles.title}>{t("RecordedObservations")}</h3>
                            {observationData != undefined && observationData.length != 0 ? observationData.map((item, index) => item.is_delete == 0 ? <div className={pfdObTabStyles.listObservations} key={index}>
                                <h4>{item?.work_area_name} {item?.is_custom == 1 ? <span className={pfdObTabStyles.marked}></span> : null}</h4>
                                <div className={pfdObTabStyles.contentObservations}>
                                    <p>{item.sub_theme_name !="null"?item.sub_theme_name:''}</p>
                                    <p dangerouslySetInnerHTML={{__html: item.observation_master_name !="null"?item.observation_master_name:''}}/>
                                    {/* <p dangerouslySetInnerHTML={{__html:item.observation_recommendation !="null"?item.observation_recommendation:''}}/> */}
                                    <div style={{textAlign:"right",fontSize:"18px"}}><RiDeleteBin5Fill className='DelObservation' onClick={() => DeleteObservation(index)}/></div>
                                </div>
                            </div> : null) : <div>{t("NoObservationRecorded")}</div>}
                        </div>

                    </div>

                </div>

            </div>
            <div className='row mt-3'>
                <div className='col-6 text-center'><button className='Psecondary_btn_mob'>
                    <Link to={"/checkpoints/notes/" + comp_url}><img src={notes} alt="edit" height={19} /> {t("Notes")}</Link>
                </button>
                </div>
                <div className='col-6  text-center'><button className='Psecondary_btn_mob' to="/notes" >
                    <Link to={"/checkpoints/addgallery/" + comp_url}><img src={gallery} alt="edit" height={19} /> {t("Gallery")}</Link>
                </button>
                </div>
                {/* <div className='col-4'><button className="primary_btn" onClick={saveObservation}>Save Changes</button>
                </div> */}
            </div>
            <Modal className="newObservation removeConform" show={deletePopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="P-3 pt-0 text-center">
                        <h4 className="mb-4">{t("DeleteObservation")}</h4>
                        <button className="secondary_btn small_btn" onClick={() => saveDeleteObservation('no')}>{t("No")}</button>
                        <button className="secondary_btn small_btn" onClick={() => saveDeleteObservation('yes')}>{t("Yes")}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="newObservation removeConform" show={successMessage} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="P-3 pt-0 text-center">
                        <h4 className="mb-4">{Message && Message}</h4>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className="newObservation removeConform" show={errorMessage} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="P-3 pt-0 text-center">
                        <h5 className="form-text text-danger mb-4" style={{fontWeight:"500",fontSize:"14px"}}>{Message && Message}</h5>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
export default ObservationMob;