import React, { useState } from 'react'; 
import { Link, useLocation, useNavigate } from 'react-router-dom';
import loginStyles from './login.module.css'; 
import LoginLogo from '../../assets/images/logo.png';  
import DataService from '../../services/DataServices';  
import { useTranslation } from 'react-i18next'; 
import { useLanguageChange } from '../customHooks/lan_hook'; 
import AuthUser from '../authUser/authuser'; 
import { v4 as uuidv4 } from 'uuid';
import { app_version } from '../../constant';
import Platform from 'react-platform-js'; 
import { useRef } from 'react';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa'; 
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

const api = new DataService();
const Login = () => {
const navigate = useNavigate();
const shouldLog = useRef(true);
let uuid = uuidv4();
const { logout, setToken, expiryT } = AuthUser();
const { t } = useTranslation();
let location = useLocation();
let currentPath = location.pathname;
useLanguageChange();
const getLanguage_id = localStorage.getItem('lanId');
const [passwordType, setPasswordtype] = useState('password');
const [ErrorMessage,SetErrorMessage] =useState(false);
const [Message,setMessage] = useState('');
const [valuesLogin, setValues] = useState({
email: '',
password: '',
});

const [validations, setValidations] = useState({
email: '',
password: '',
});
const [error, setError] = useState('');
const validateAll = () => {
const { password, email } = valuesLogin;
const validations = { password: '', email: '' };
let isValid = true;
if (!email) {
validations.email = 'Please enter your correct Email Id.';
isValid = false;
}
if (email === '') {
validations.email =
	t("emailEmpty");
isValid = false;
}
if (email && !/\S+@\S+\.\S+/.test(email)) {
validations.email = 'Email format must be as example@mail.com.';
isValid = false;
}

// if (!password) {
//     validations.password = 'Please enter your correct Password'
//     isValid = false
// }
if (password == '') {
validations.password =
	t("passwordEmpty");
isValid = false;
}
// if ((password && password.length >=8)) {
// 	validations.password = 'Password must have 8 characters.';
// 	isValid = false;
// }

if (!isValid) {
setValidations(validations);
}

return isValid;
};
const handleChange = (e) => {
const { name, value } = e.target;
setValues({ ...valuesLogin, [name]: value });
};

const handleSubmit = async (e) => {
e.preventDefault();

const isValid = validateAll();
if (isValid == true) {
let data = {
	userid: valuesLogin.email,
	password: btoa(unescape(encodeURIComponent(valuesLogin.password)))
};
await api
	.validateLogin(data)
	.then((res) => {
		if (res.status == 200 || res.status == 201) {
			let TOKEN = res?.data;
			setToken(TOKEN);

			if (window.innerWidth > 768) {
				let deviceDesk = {
					device_name: Platform.OS,
					manufacturer: Platform.Browser,
					modelnumber: Platform.BrowserVersion,
					appversion: app_version,
					osversion: Platform.OSVersion,
					platform: Platform.OS,
					udid: uuid,
				};
				api
					.addDeviceInformation(deviceDesk)
					.then((response) => {
						if (response.status == 200 || response.status == 201) {
							localStorage.setItem('udid', uuid);
						}
					})
					.catch((error) => {
						let errorCode = error.response.status;
						if (errorCode == 401) {
							logout();
						}
					});
			} else {
				let deviceMobile = {
					device_name: Platform.OS,
					manufacturer: Platform.Browser,
					modelnumber: Platform.BrowserVersion,
					appversion: app_version,
					osversion: Platform.OSVersion,
					platform: Platform.OS,
					udid: uuid,
				};
				api
					.addDeviceInformation(deviceMobile)
					.then((response) => {
						if (response.status == 200 || response.status == 201) {
							localStorage.setItem('udid', uuid);
						}
					})
					.catch((error) => {
						let errorCode = error.response.status;
						if (errorCode == 401) {
							logout();
						}
					});
			}
		}
	})
	.catch((err) => {
		if(err.code !== "ERR_NETWORK"){
			setValidations({
				...validations,
				password: err.response.data.error.message,
			});
		}
		else{
			SetErrorMessage(true);
			setMessage("Please check your internet connection.")	
		}
	});
}
};
useEffect(() => {
let exe = localStorage.getItem('expiry');
if(window.innerWidth > 768){
if (new Date(exe) < Date.now()) {
	navigate('/login');
} else {
	navigate('/dashboard');
}
}
else{
if (new Date(exe) < Date.now()) {
	navigate('/login');
} else {
	navigate('/uploadSignature');
}
}

}, []);
return (
<div className={loginStyles.login}>
<div className="container">
	<div className="row">
		<div className="col-md-6 col-lg-5">
			<div className="row">
				<div className="col-md-12 text-center d-block d-md-none">
					<img className="LoginLogo" src={LoginLogo} alt="Logo" />
				</div>
				<div className="col-md-12 mb-4">
					<h1 className=" d-none d-md-block">{t('loginTitle')}</h1>
					{window.innerWidth>768?<h1 className=" d-block d-md-none">{t('welcome')}</h1>
					:<h1 className=" d-block d-md-none">{t('welcomeText')}</h1>}
					<p>{t('welcome')}</p>
				</div>
				<div className="col-md-12 mb-4">
					<form
						className={loginStyles.login_form}
						onSubmit={handleSubmit}
					>
						<div className="mb-3 loginField">
							<label className="form-label">
								{t('email')}
								<span className="star">*</span>
							</label>
							<input
								type="email"
								name="email"
								value={valuesLogin.email}
								onChange={handleChange}
								className="form-control loginPlace"
								placeholder={t("EnterRegisteredEmail")}
							/>
							<div className="form-text text-danger">
							   {validations.email}
							</div>
						</div>
						<div className="mb-3 po-relative loginField">
							<label className="form-label">
								{t('password')}
								<span className="star">*</span>
							</label>
							<input
								type={passwordType}
								name="password"
								value={valuesLogin.password}
								onChange={handleChange}
								className="form-control loginField loginPlace"
								placeholder={t("EnterPassword")}
							/>
							{passwordType == 'password' ? (
								<FaEyeSlash
									className="eyeClass"
									onClick={() => setPasswordtype('text')}
								/>
							) : (
								<FaRegEye
									className="eyeClass"
									onClick={() => setPasswordtype('password')}
								/>
							)}
							<div className="form-text text-danger">
								{validations.password}
								{/* {function () {
									if (validations.password) {
										if (getLanguage_id == 2) {
											return validations.password;
										} else {
											return t('passwordEmpty');
										}
									}
								}.call()} */}
							</div>
							<div className="form-text text-danger">{error}</div>
						</div>
						<div className="mb-3">
							<Link to="/forgotPassword">{t('forgotpassword')}</Link>
						</div>
						<div className="d-grid gap-2 col-md-12 mx-auto">
							{/* {window.innerWidth < 768 ? <Link to="/uploadSignature" className="primary_btn">Log In</Link> : <button type="submit" className="primary_btn">Log In</button>} */}
							<button type="submit" className="primary_btn">
								{t("loginTitle")}
							</button>
						</div>
					</form>
				</div>
				<div className="col-md-12 login_privacy">
					<p>
						{t('byclicking')}
						<Link to="/termsAndConditions"> {t('terms')}</Link> and{' '}
						<Link to="/privacyPolicy"> {t('policy')}</Link>
					</p>
				</div>
			</div>
		</div>
	</div>
	<Modal className="newObservation removeConform" show={ErrorMessage} onHide={()=>SetErrorMessage(false)} aria-labelledby="contained-modal-title-vcenter" centered>
	<Modal.Header closeButton>
	</Modal.Header>
	<Modal.Body>
		<div className="P-3 pt-0 text-center">
			<h4 className="mb-4">{Message&&Message}</h4>
		</div>
	</Modal.Body>
</Modal>
</div>
</div> 
);
}; 
export default Login; 
