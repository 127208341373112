export const userids = {
	Mihir: 118,
	Rishabh: 117,
	Buzurgmehr: 116,
	Jahkonfir: 115,
	Shahromjon: 114,
	Akmaljon: 113,
	Gulos: 112,
	Anvar: 111,
	Shukurillo: 110,
	Mirzohid: 109,
	Makhsum: 108,
	Dilnoza: 107,
	Bekhruz: 106,
	Hasan: 105,
	Bunyod: 104,
	Elena: 103,
	Oybek: 119,
	Malika: 120,
	Nilufari: 121,
	Madina: 122,
};
