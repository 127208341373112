
import React, { useState, useEffect } from 'react';
import dashboardStyles from './dashboard.module.css';
import { Link } from 'react-router-dom';
import plus from '../../assets/images/plus.svg';
import { useTranslation } from 'react-i18next'

import { useLanguageChange } from '../customHooks/lan_hook';
import { useNavigate } from 'react-router-dom';
import DataService from '../../services/DataServices';
import AuthUser from '../authUser/authuser';
import Loader from '../../assets/images/loadin_gif.gif';
import { ErrorLog } from '../erroLog/errorLog';
import Nodata from '../../assets/images/noData.png';

const Dashboard = () => {
    const { logout } = AuthUser();
    const api = new DataService();
    const [dashboardData, setDashboardData] = useState([]);
    const [LoaderImg,setLoader] =useState(true);
    let navigate = useNavigate();
    //useLanguageChange();
    let { lanId } = useLanguageChange();
    const { t } = useTranslation()

    //Dash board API call
    const getDashBoardData = async () => {
        if (lanId) {
            await api.getTCdashboard().then((response) => {
                let data = response.data;
                if (response.status == 200 || response.status == 201) {
                        setDashboardData(data);
                        setLoader(false);
                }
            }).catch((error) => {
                if (error.code !== "ERR_NETWORK") {
                    let errorCode = error.response.status;
                    let message = error.response.data.error.message;
                    if (errorCode == 401) {
                        logout();
                    }
                    if (errorCode == 400 || errorCode == 500) {
                        let errorLogData ={
                            api_name:"dashboard",
                            error_code:errorCode,
                            error_message:message
                        } 
                        ErrorLog(errorLogData);
                    }
                }
                else{
                    setLoader(false);
                }
            })
        }

    }
    const getMasterDataFunc = async () => {
        if (lanId) {
            await api.getMaterDataList().then((response) => {
                if (response.status == 200 || response.status == 201) {
                    let materData = JSON.stringify(response.data);
                    localStorage.setItem('masterData', materData)
                }
            }).catch((error) => {
                if (error.code !== "ERR_NETWORK") {
                    let errorCode = error.response.status;
                    let message = error.response.data.error.message;
                    if (errorCode == 401) {
                        logout();
                    }
                    if (errorCode == 400 || errorCode == 500) {
                        let errorLogData ={
                            api_name:"getMasterListData",
                            error_code:errorCode,
                            error_message:message
                        } 
                        ErrorLog(errorLogData);
                    }
                }
            })
        }
    }
    const getTCdetails = async ()=>{
        await api.getTcDetails().then((response)=>{
            if (response.status == 200 || response.status == 201) {
            let regionName = response.data.companyList[0].region_name;
            let regionAbbr = response.data.companyList[0].region_abbrevation;
            let regionId = response.data.companyList[0].region_id;
            let countryName = response.data.companyList[0].country_name;
            let countryId = response.data.companyList[0].country_id;
            let countryAbbr = response.data.companyList[0].country_abbrevation;
            localStorage.setItem("regionName",regionName);
            localStorage.setItem("regionAbbr",regionAbbr);
            localStorage.setItem("regionId",regionId);
            localStorage.setItem("countryName",countryName);
            localStorage.setItem("countryId",countryId);
            localStorage.setItem("countryAbbr",countryAbbr);
            }
        }).catch((error) => {
            if (error.code !== "ERR_NETWORK") {
                let message = error.response.data.error.message;
                let errorCode = error.response.status;
                if (errorCode == 401) {
                    logout();
                }
                if (errorCode == 400 || errorCode == 500) {
                    let errorLogData ={
                        api_name:"viewtcprofile",
                        error_code:errorCode,
                        error_message:message
                    } 
                    ErrorLog(errorLogData);
                }
            }
        })
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getDashBoardData();
            getMasterDataFunc();
            getTCdetails();
        }
        else {
            navigate("/login")
        }
    }, [lanId])
    return (
        <div className='deskWrapper'>
            <div className="container">
                <div className="row mb-3 mt-3">
                    <div className="col-md-4 d-none d-md-block">
                        <h3 className="mainTitle">{t('dashboard')}</h3>
                    </div>
                    <div className="col-md-8 text-end">
                        <Link to="/addCompany" className={"success_btn" + " " + dashboardStyles.addnewcomp}>
                            <img src={plus} alt="Plus" />
                            {t("AddNewCompany")}
                        </Link>
                    </div>
                </div>
                <div className="row">
                    {dashboardData != undefined && dashboardData.length != 0 && dashboardData.companyList.length != 0 ? dashboardData.companyList.map((item, index) => <div className="col-lg-6 col-xl-4 mb-4" key={index}>
                        <Link to={"/checkpoints/" + `${item.company_id}`}>
                            <div className={item.company_checkpoints_status == 0 ? "activeStatusZero" + " " + dashboardStyles.iarCard : item.company_checkpoints_status == 1 ? "activeStatusOne" + " " + dashboardStyles.iarCard : "activeStatusThree" + " " + dashboardStyles.iarCard}>
                                <div className='row po-relative'>
                                    <div className='col-12 col-md-12 col-lg-12'>
                                        <h2>{item.company_code}</h2>
                                        <h3 style={{paddingTop:"5px"}}>{item.company_name}</h3>

                                    </div>
                                    <div className='col-12 col-md-12 col-lg-5 pl-0 iarabsolute'>
                                        <div>
                                            <p>{item.company_iav_date != null ? t("IAdate") + new Date(item.company_iav_date).toLocaleDateString('en-us', { day: "numeric", month: "short", year: "numeric" }) : ''}</p>
                                        </div>
                                        <div className={dashboardStyles.PtagDot}><p>{item.ste_name != null ? t("STE") + item.ste_name :  t("STEnotassigned")}</p></div>
                                    </div>
                                </div>
                                <p>{item.company_checkpoints_status == 0 ? t("IAvisittobedone") : item.company_checkpoints_status == 1 ?
                                    t("IARDocumentyetprepared") : t("InitialAssessmentReport")}</p>
                            </div>
                        </Link>
                    </div>) : <div>{LoaderImg==true ? <img src={Loader} style={{width:"180px"}} alt="loader" />: 
                    <div className="nodataFound"><img src={Nodata} alt="no data" style={{width:"60px"}}/>{t("noCompanyList")}</div>}</div>}
                </div>
            </div>
        </div>
    );
}
export default Dashboard;