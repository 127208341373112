export const hideHeaderRoutes = [
	'',
	'/',
	'cms',
	'forgotPassword',
	'termsAndConditions',
	'notFound',
	'privacyPolicy',
	'chooselanguage',
];

export const displayHeaderWithLogoRoutes = [
	'',
	'termsAndConditions',
	'privacyPolicy',
	'chooselanguage',
	'/',
	'notFound',
];
