import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { GlobalContext } from '../../../context/GlobalState';

const ErrorModal = () => {
	const { networkError, setGlobalNetworkError } = useContext(GlobalContext);
	return (
		<Modal
			className="newObservation removeConform"
			show={networkError}
			onHide={() => setGlobalNetworkError(false)}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="P-3 pt-0 text-center">
					<h4 className="mb-4">Please check your internet connection</h4>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ErrorModal;
