import React, { useState, useEffect } from 'react';
import Row from './Row';
import TCStyles from './database.module.css';
import PaginationComponent from '../ActivityLog/Pagination';
import './database.css';
import DatabaseHeader from './DatabasePageHeader';

const Database = ({
	themeArray,
	excelFile,
	type,
	setThemeArray,
	themeRef,
	columnsArray,
	name,
	id,
	placeholder,
	secondName,
	api,
	status,
	isLoading,
	setIsLoading,
}) => {
	//pagination set up
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);

	// Logic to get the current items to display based on the current page
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = themeArray?.slice(indexOfFirstItem, indexOfLastItem);

	// Function to handle page changes
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {}, [currentItems, isLoading]);

	return (
		<div className="deskWrapper">
			<div className="container">
				<DatabaseHeader
					setThemeArray={setThemeArray}
					excelFile={excelFile}
					themeRef={themeRef}
					placeholder={placeholder}
					api={api}
					name={name}
					secondName={secondName}
					type={type}
					themeArray={themeArray}
					setIsLoading={setIsLoading}
				/>
				{isLoading ? (
					<div className="deskWrapper container">
						<div className="loader"></div>
					</div>
				) : currentItems?.length > 0 ? (
					<div className="row">
						<div
							className={'col-md-12 mt-3' + ' ' + TCStyles.tableWrapper}
							style={{
								border: '4px solid #b8b8b8',
								borderBottom: '1px solid #b8b8b8',
							}}
						>
							<table
								className={'table table-striped' + ' ' + TCStyles.iarTable}
							>
								<thead>
									<tr className={TCStyles.tr} style={{ height: '60px' }}>
										{columnsArray.map((column, index) => (
											<th
												className={TCStyles.th}
												key={index}
												colSpan={columnsArray.length}
												style={{ fontSize: '20px' }}
											>
												{column}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{currentItems?.map((resource, index) => (
										<Row
											themeArray={themeArray}
											setThemeArray={setThemeArray}
											resource={resource}
											index={index}
											key={index}
											type={type}
											name={name}
											id={id}
											span={columnsArray.length}
											status={status}
										/>
									))}
								</tbody>
							</table>
						</div>
						<PaginationComponent
							itemsPerPage={itemsPerPage}
							totalItems={themeArray?.length}
							paginate={paginate}
							activePage={currentPage}
						/>
					</div>
				) : (
					<div
						style={{
							margin: '50px',
							fontSize: '28px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						There is no data to show at the moment.
					</div>
				)}
			</div>
		</div>
	);
};
export default Database;
