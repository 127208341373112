import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import DataService from '../../services/DataServices';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useMasterdata } from '../customHooks/masterDatahook';
import { useTranslation } from 'react-i18next';
import AuthUser from '../authUser/authuser';
import { FaPlus } from 'react-icons/fa';
import {MdKeyboardArrowDown} from 'react-icons/md';
import Loader from '../../assets/images/loadin_gif.gif';
import { ErrorLog } from '../erroLog/errorLog';

const AddCompany = () => {
    const { t } = useTranslation();
    const { logout } = AuthUser();
    const { country, region } = useMasterdata();
    const RegionArray =region;
    let countryId = localStorage.getItem("countryId");
    let RegionId = localStorage.getItem("regionId");
    let defaultRegion = localStorage.getItem("regionName");
    let navigate = useNavigate();
    let api = new DataService();
    let inputRef = useRef(null);
    let inputfocusRef = useRef(null);
    const [ErrorShow, setErrorShow] = useState(false);
    const [errorProInput, setErrorProInput] = useState('');
    const [CompCodePopup, setCompCodePopup] = useState(false);
    const [Success, setSuccessFull] = useState(false);
    const [CompanycodeError, setCompanycodeError] = useState('')
    const [addnewProductBtn, setAddnewProductBtn] = useState(false)
    const [SuccessMessage,setSuccessMessage] =useState('')
    const [productTxt, setProductTxt] = useState("");
    const [LoaderSave,setLoaderSave] = useState(false);
    const handleCloseError = () => {
        setErrorShow(false);
        setSuccessFull(false);
        setLoaderSave(false);
    }
    const closeComCode = () => setCompCodePopup(false);
    const getLocalItems = () => {
        let companyD = localStorage.getItem("companyDetails");
        if (companyD !== null) {
            return JSON.parse(localStorage.getItem('companyDetails'))
        }
    }
    const [errorValue, setErrorValue] = useState({});

    const [productsData, setProducts] = useState({
        id: 0,
        name: ''
    });
    const [productsList, setproductsList] = useState([]);
    const [companyDetails, setCompanyDetails] = useState({
        country_abbr: getLocalItems() != null ? getLocalItems().country_abbr : localStorage.getItem("countryAbbr"),
        region_abbr: getLocalItems() != null ? getLocalItems().region_abbr : localStorage.getItem("regionAbbr"),
        company_code: getLocalItems() != null ? getLocalItems().company_code : '',
        companyname: getLocalItems() != null ? getLocalItems().companyname : '',
        address_one: getLocalItems() != null ? getLocalItems().address_one : '',
        address_two: getLocalItems() != null ? getLocalItems().address_two : '',
        region:getLocalItems() != null ? getLocalItems().region : RegionId,
        subregion: getLocalItems() != null ? getLocalItems().subregion : '',
        country:getLocalItems() != null ? getLocalItems().country : countryId,
        person_name: getLocalItems() != null ? getLocalItems().person_name : '',
        person_desg: getLocalItems() != null ? getLocalItems().person_desg : '',
        person_phn: getLocalItems() != null ? getLocalItems().person_phn : '',
        person_email: getLocalItems() != null ? getLocalItems().person_email : '',
        country_code: getLocalItems() != null ? getLocalItems().country_code : '+998',
        products: localStorage.getItem('productsAll') != null ? JSON.parse(localStorage.getItem('productsAll')) : []
    })
    const [getCountrycode, setCountrycode] = useState("+998");
    const [getComApplication, setComApplication] = useState(false)
    const [companyCode, setCompanyCode] = useState('')
    const onchangeFunc = (e, products) => {
        let { name, value } = e.target;
        var options = e.target.options;
        if (name == "region") {
            var region = options[options.selectedIndex].id;
            setCompanyDetails({ ...companyDetails, [name]: value, region_abbr: region })
        }
        else if (products == "products") {
            setProducts({ ...productsData, [name]: value })
        }
        else if(name == "person_phn"){
            if(value.length <= 9){
                setCompanyDetails({ ...companyDetails, [name]: value })
            }
        }
        else {
            setCompanyDetails({ ...companyDetails, [name]: value })
        }
    }
    let productArray = [...productsList]
    const addProduct = (val) => {
        if(productsList.length <= 5){
            if (val != '') {
                let data = {
                    id: 0,
                    name: val
                }
                productArray.push(data);
                localStorage.setItem('productsAll', JSON.stringify(productArray));
                setproductsList([...productsList, data]);
                setCompanyDetails({ ...companyDetails, products: productArray });
                setProducts({ ...productsData, name: '' })
                setErrorProInput("")
                setErrorValue({...errorValue,products:''})
            }
            else {
                setErrorProInput(t("Pleasefillproduct"))
            }
        }
       

    }
    const ApplicationValid = (e) => {
        let targetValue = e.target.checked;
        if (targetValue == true) {
            setComApplication(true)
        }
        else {
            setComApplication(false)
        }
    }
    const focusFunction = () => {
        inputfocusRef.current.focus();
    }
    const codeOnChnage = (e) => {
        let companyCode = e.target.value
        setCompanyCode(companyCode)
        let comapnyCountrycode = companyDetails.country_abbr + "-" + companyDetails.region_abbr
        setCompanyDetails({ ...companyDetails, company_code: comapnyCountrycode + "-" + companyCode })
    }
    const successFunc = () => {
        navigate('/dashboard');
    }
    const saveCompanycode = async () => {
        setLoaderSave(true)
        if (companyCode == "") {
            setTimeout(()=>{
                setLoaderSave(false);
            },1000)
            setCompanycodeError(t('company_code_validation_text_1'))
        }
        else if (companyCode.length < 3) {
            setTimeout(()=>{
                setLoaderSave(false);
            },1000)
            setCompanycodeError(t('company_code_validation_text_2'))
        }
        else {
            let data = {
                "company_id": 0,
                "company_name": companyDetails.companyname,
                "company_code": companyDetails.company_code,
                "company_address_line1": companyDetails.address_one,
                "company_address_line2": companyDetails.address_two,
                "company_sub_region": companyDetails.subregion,
                "company_region": companyDetails.region,
                "company_country": companyDetails.country,
                "company_created_by": 1,
                "company_products": companyDetails.products,
                "company_contact_person_name": companyDetails.person_name,
                "company_contact_person_desg": companyDetails.person_desg,
                "company_contact_person_phn": getCountrycode + "-" + companyDetails.person_phn,
                "company_contact_person_email": companyDetails.person_email,
            }
          
            await api.addNewCompany(data).then(res => {
                if (res.status == 200 || res.status == 201) {
                    let message=res.data.message;
                    localStorage.removeItem('companyDetails');
                    localStorage.removeItem("productsAll");
                    setSuccessFull(true);
                    setCompCodePopup(false)
                    setSuccessMessage(message)
                    setTimeout(()=>{
                        setLoaderSave(false);
                    },1000)
                }   
            }).catch((error) => {
                if(error.code !="ERR_NETWORK"){
                    if(error.response.status == 400 || error.response.status == 500){
                        let message = error.response.data.error.message;
                        setCompCodePopup(true)
                        setCompanycodeError(message)
                        setTimeout(()=>{
                            setLoaderSave(false);
                        },1000)
                        let errorLogData ={
                            api_name:"addCompany",
                            error_code:error.response.status,
                            error_message:message
                        } 
                        ErrorLog(errorLogData);
                    }
                    if(error.response.status == 401){
                        logout();
                    }
                }
                else{
                    setCompanycodeError(error.message)
                    setTimeout(()=>{
                        setLoaderSave(false);
                    },1000)
                }
            })
        }

    }
    const AddCompSubmit = async (e) => {
        e.preventDefault()
        let errorValue = formValidation(companyDetails);
        setErrorValue(errorValue)
        if (Object.keys(errorValue).length === 0) {
            setCompCodePopup(true)
            //inputfocusCode.focus();
            setTimeout(() => {
                focusFunction()
            }, 1000)
        }
        else {
            setErrorShow(true);
        }

    }
    const formValidation = (values) => {
        let error = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.companyname) {
            error.companyname = t('companyname_validation_text');
        }
        if (!values.address_one) {
            error.address_one = t('address_one_validation_text');
        }
        if (!values.address_two) {
            error.address_two = t('address_two_validation_text');
        }
        if (!values.region) {
            error.region = t('region_validation_text');
        }
        // if (!values.subregion) {
        //     error.subregion = t('subregion_validation_text')
        // }
        if (!values.country) {
            error.country = t('country_validation_text');
        }
        if (!values.person_name) {
            error.person_name = t('person_name_validation_text');
        }

        if (!values.person_desg) {
            error.person_desg = t('person_desg_validation_text');
        }
        if(values.person_email !== ''){
            if (!regex.test(values.person_email)) {
                error.person_email = t('person_email_validation_text');
            }
        }
        if (!values.person_phn || values.person_phn.length < 9) {
            if(values.person_phn.length == 0){
                error.person_phn = t('person_phn_validation_text');
              
            }
            else if( values.person_phn.length < 9){
                error.person_phn = t("Pleasecheckphonenumber");
            }
        }
        if (values.products.length == 0) {
            error.products = t('products');
        }
        return error;
    }
    const removeCurrentProducts = (indexId) => {
        let getitem = JSON.parse(localStorage.getItem('productsAll'))
        var result = getitem;
        result.forEach((element, index) => {
            if (index == indexId) {
                result.splice(indexId, 1)
            }
        });
        setproductsList(result);
        setCompanyDetails({ ...companyDetails, products: result });
        setErrorValue({...errorValue,products: t('products')})
        localStorage.setItem('productsAll', JSON.stringify(result))

    }
    useEffect(() => {
        if (companyDetails.products.length === 6) {
            setAddnewProductBtn(true)
            setProductTxt(t("Youhavereachedmaxlimit"))
        }
        else {
            setAddnewProductBtn(false)
            setProductTxt("")

        }
        localStorageCompany(companyDetails);
        getLocalItems();
    }, [companyDetails, productsList, errorValue])
    const localStorageCompany = (values) => {
        localStorage.setItem('companyDetails', JSON.stringify(values));
    };
    return (
        <div className='deskWrapper'>
            {/* <TcBreadCrumb title="Add a New Company"/> */}
            <div className="container">
                <div className="row mb-5">
                    <div className='col-md-12'>
                        <form onSubmit={(e) => AddCompSubmit(e)} autoComplete="off">
                            <div className={window.innerWidth>768?"row mb-5":"row mb-2"}>
                                <div className='col-md-12 mb-4'><h3 className="form_title">{t("companyDetails")}</h3></div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">{t("companyName")}<span className="star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="companyname"
                                        placeholder={t("EnterCompanyNamePlaceHolder")}
                                        maxLength="80"
                                        onChange={(e) => onchangeFunc(e)}
                                        value={companyDetails.companyname}
                                    />
                                    <div className="form-text text-danger">{errorValue.companyname}</div>
                                </div>
                                <div className="col-12 col-md-4 mb-4">
                                    <label className="form-label">{t("addressOne")}<span className="star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="address_one"
                                        placeholder={t("EnterAddressOnePlaceHolder")}
                                        maxLength="100"
                                        onChange={(e) => onchangeFunc(e)}
                                        value={companyDetails.address_one}
                                    />
                                    <div className="form-text text-danger">{errorValue.address_one}</div>
                                </div>
                                <div className="col-12 col-md-4 mb-4">
                                    <label className="form-label">{t("addressTwo")}<span className="star">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="address_two"
                                        placeholder={t("EnterAddressTwoPlaceHolder")}
                                        maxLength="100"
                                        onChange={(e) => onchangeFunc(e)}
                                        value={companyDetails.address_two}
                                    />
                                    <div className="form-text text-danger">{errorValue.address_two}</div>
                                </div>
                                <div className="col-12 col-md-4 mb-4 select">
                                    <label className="form-label">{t("Country")}<span className="star">*</span></label>
                                    <MdKeyboardArrowDown 
                                       className='selectArrow'
                                       style={{bottom:errorValue.country===undefined?"15px":"25px"}}
                                    />
                                    <select
                                        className="form-control"
                                        style={{paddingBottom:"10px"}}
                                        name="country"
                                        value={companyDetails.country}
                                        onChange={(e) => onchangeFunc(e)}
                                    >
                                        {/* <option value="">{t("SelectCountryPlaceHolder")}</option> */}

                                        {country && country.map((countryItem, index) => <option key={index} value={countryItem.country_id} id={countryItem.country_abbrevation}>{countryItem.country_name}</option>)}
                                    </select>
                                    <div className="form-text text-danger">{errorValue.country}</div>
                                </div>
                                <div className="col-12 col-md-4 mb-4 select">
                                    <label className="form-label">{t("region")}<span className="star">*</span></label>
                                    <MdKeyboardArrowDown
                                       className='selectArrow'
                                       style={{bottom:errorValue.region===undefined?"15px":"25px"}}
                                    />
                                    <select
                                        className="form-control"
                                        style={{paddingBottom:"10px"}}
                                        name="region"
                                        value={companyDetails.region}
                                        onChange={(e) => onchangeFunc(e)}
                                    >
                                        {/* <option value=''>{defaultRegion}</option> */}
                                        {RegionArray && RegionArray.map((itemRegion, index) => <option key={index} value={itemRegion.region_id} id={itemRegion.region_abbrevation}>{itemRegion.region_name}</option>)}
                                    </select>
                                    <div className="form-text text-danger">{errorValue.region}</div>
                                </div>
                                <div className="col-12 col-md-4 mb-4">
                                    <label className="form-label">{t("SubRegion")}</label>
                                    <input type="text"
                                        className="form-control"
                                        name="subregion"
                                        placeholder={t("EnterSubRegionPlaceHolder")}
                                        maxLength="100"
                                        onChange={(e) => onchangeFunc(e)}
                                        value={companyDetails.subregion}
                                    />
                                    {/* <div className="form-text text-danger">{errorValue.subregion}</div> */}
                                </div>
                            
                                <div className="col-12 col-md-6 mb-4">
                                    <div className='row'>
                                        <div className='col-10 col-md-8 col-lg-7'>
                                            <label className="form-label">{t("companyProductAndservice")}<span className="star">*</span></label>

                                            {companyDetails.products.map((item, index) => <div style={{ position: "relative" }} key={index}><input type="text"
                                                className="form-control"
                                                value={item.name}
                                                disabled
                                            /><label className="deleteItemservices" onClick={() => removeCurrentProducts(index)}>x</label></div>)}
                                            <input type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder={t("EnterCompanyMainProductPlaceHolder")}
                                                onChange={(e) => onchangeFunc(e, 'products')}
                                                onBlur={()=>addProduct(productsData.name)}
                                                value={productsData.name}
                                                ref={inputRef}
                                            />
                                             {addnewProductBtn===true ? <a 
                                             onClick={() => addProduct(productsData.name)} 
                                             type="button" disabled className="secondary_btn_disabled">{t("AddNewProduct")}</a> : 
                                             <a 
                                                  onClick={() => addProduct(productsData.name)} 
                                                  type="button" 
                                                  className="secondary_btn displaycenter mt-2">
                                                    <FaPlus/>
                                                   {t("AddNewProduct")}</a>}
                                            <div className="form-text text-danger">{errorValue.products}</div>
                                            <div className="form-text text-danger">{errorProInput}</div>
                                            <div className="form-text text-danger">{productTxt}</div>
                                            {/* <button onClick={() => addProduct(productsData.name)} className="secondary_btn" disabled={addnewProductBtn}>Add New Product Service</button> */}
                                        </div>

                                    </div>
                                </div>



                                {/* <div className="col-12 mt-2">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className="secondary_btn" >Add New Product Service</button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="row">
                                <div className='col-md-12 mb-4'><h3 className="form_title">{t("PersonalDetails")}</h3></div>
                                <div className="col-md-4 mb-3">
                                    <label className="form-label">{t("Name")}<span className="star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="person_name"
                                        placeholder={t("EnterContactPersonPlaceHolder")}
                                        maxLength="70"
                                        onChange={(e) => onchangeFunc(e)}
                                        value={companyDetails.person_name}
                                    />
                                    <div className="form-text text-danger">{errorValue.person_name}</div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label className="form-label">{t("Designation")}<span className="star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="person_desg"
                                        placeholder={t("EnterContactPersonDesignationPlaceHolder")}
                                        maxLength="70"
                                        onChange={(e) => onchangeFunc(e)}
                                        value={companyDetails.person_desg}
                                    />
                                    <div className="form-text text-danger">{errorValue.person_desg}</div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div><label className="form-label">{t("PhoneNumber")}<span className="star">*</span></label></div>
                                    <div className='phoneGroup'>
                                        <span>
                                            <select
                                                name="country_code"
                                                value={companyDetails.country_code}
                                                onChange={(e) => onchangeFunc(e)}
                                            >
                                                {country && country.map((countryItem, index) => <option key={index} value={countryItem.country_code}>{countryItem.country_code}</option>)}
                                            </select>
                                        </span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="person_phn"
                                            placeholder={t("EnterContactPersonPhonePlaceHolder")}
                                            onChange={(e) => onchangeFunc(e)}
                                            value={companyDetails.person_phn}
                                            maxLength="9"
                                        />
                                    </div>
                                    <div className="form-text text-danger">{errorValue.person_phn}</div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label className="form-label">{t("Email")}</label>
                                    <input
                                        // type="email"
                                        className="form-control"
                                        name="person_email"
                                        placeholder={t("EnterContactPersonEmailPlaceHolder")}
                                        maxLength="70"
                                        onChange={(e) => onchangeFunc(e)}
                                        value={companyDetails.person_email}
                                    />
                                    <div className="form-text text-danger">{errorValue.person_email}</div>
                                </div>
                                <div className="col-sm-12 mb-5 mt-2 acceptterms">
                                    <label className="checkbox-inline">
                                        <input
                                            type="checkbox"
                                            value=""
                                            onChange={(e) => ApplicationValid(e)}
                                        />{t("saveCompanyConformText")}
                                    </label>
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            {getComApplication ? <button type="submit" className="primary_btn" style={{padding:"10px 30px",borderRadius:"10px"}}>{t("SaveNewCompany")}</button> : <button type="submit" className="primary_btn primaryhover addCompanyDisable" style={{padding:"10px 30px",borderRadius:"10px"}} disabled>{t("SaveNewCompany")}</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <Modal className="newObservation removeConform" show={ErrorShow} onHide={handleCloseError} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{t("validationErrorcompany")}</h4>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal className="newObservation removeConform" show={CompCodePopup} onHide={closeComCode} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h3 
                                       className="mb-4"
                                       style={{
                                           fontWeight:"600",
                                           color:"#327072"
                                       }}
                                    >{t("EnterCompanyCode")}</h3>
                                    <p>{t("EnterThreeDigitCode")}</p>
                                    <div className='addcompanyCode'>
                                        <div>{companyDetails.country_abbr}</div>
                                        <div>-</div>
                                        <div>{companyDetails.region_abbr}</div>
                                        <div>-</div>
                                        <div className='inputCode'>
                                            <input className='inputCodeRef'
                                                type="text" maxLength="3"
                                                ref={inputfocusRef}
                                                onChange={(e) => codeOnChnage(e)}
                                                value={companyCode}
                                            />
                                            <div className='coverFrame'></div>
                                            <div className='borderCode'>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>

                                        </div>

                                    </div>
                                    {LoaderSave == true ?  <img src={Loader} alt="loader" width="130"/>:<div className="form-text text-danger mt-3">{CompanycodeError}</div>}
                                    <div className='saveCode'><button onClick={saveCompanycode}>{t("SaveCompanyCode")}</button></div>
                                    {/* <button className="secondary_btn small_btn">No</button>
                                    <button className="primary_btn small_btn">Yes</button> */}
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={Success} onHide={handleCloseError} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{SuccessMessage&&SuccessMessage}</h4>
                                    <button onClick={successFunc} className="secondary_btn small_btn">{t("Ok")}</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddCompany;