import { useState, useEffect } from "react";
import i18next from "i18next"

export const useLanguageChange = () => {
  const [lang,setlang] =useState(localStorage.getItem('lan')!=null?localStorage.getItem('lan'):localStorage.setItem('lan','ru'))
  const [lanId,setLangId] = useState();
 
  useEffect(() => {
    const setlanguageFunc=(lang)=>{
        i18next.changeLanguage(lang)
        setlang(lang)
        if(lang == 'en'){
          setLangId(2)
        }
        else{
          setLangId(1)
        }
    }
     setlanguageFunc(lang)
  }, [lang,lanId]);

  return {lang,lanId};
};