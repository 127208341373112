import React, { useEffect, useState, useRef, useContext } from 'react';
import AdminDataService from '../../../../../services/AdminDataServices';
import ObservationExcel from '../../../../../assets/excel_files/workarea_subtheme_observation_recomendation.xlsx';
import Database from '../database';
import { Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GlobalContext } from '../../../../../context/GlobalState';
import DatabaseHeader from '../DatabasePageHeader';
import TCStyles from '../database.module.css';
import Row from '../Row';
import PaginationComponent from '../../ActivityLog/Pagination';
import ObservationRow from './ObservationRow';

const Observation = () => {
	const columnsArray = [
		'Observation ',
		'Recommendation',
		'Sub Theme',
		'Work Area',
		'Activation Status',
		'Is Custom',
	];
	const adminApi = new AdminDataService();
	const { observationApiError, currentLanguageId } = useContext(GlobalContext);
	const [observation, setObservation] = useState([]);

	const [show, setShow] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const observationRef = useRef({});

	useEffect(() => {
		document.title = 'Reap :: Observation';

		async function getObservations() {
			let language_id = 2;
			if (localStorage.getItem('language_id')) {
				language_id = localStorage.getItem('language_id');
			} else {
				language_id = currentLanguageId;
			}
			try {
				const result = await adminApi.getObservationMasterList(language_id);

				setObservation(result?.data?.observation);
				observationRef.current = {
					...observationRef,
					0: result?.data?.observation,
				};
			} catch (error) {
				console.log(error);

				if (error.code == 'ERR_BAD_REQUEST') {
					alert('User Unauthorized. Please log back in to get the data');
				}
			} finally {
				setIsLoading(false);
			}
		}

		getObservations();
	}, []);

	//pagination set up
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);

	// Logic to get the current items to display based on the current page
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = observation?.slice(indexOfFirstItem, indexOfLastItem);

	// Function to handle page changes
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {}, [currentItems]);

	if (currentItems == null || currentItems == undefined) {
		return (
			<div
				style={{
					textAlign: 'center',
					fontSize: '24px',
					fontWeight: '500',
					marginTop: '200px',
				}}
			>
				There is no data to show at the moment.
			</div>
		);
	}

	return (
		<>
			<div className="deskWrapper ">
				<div className="container">
					<DatabaseHeader
						setThemeArray={setObservation}
						excelFile={ObservationExcel}
						themeRef={observationRef}
						placeholder="Search by Observation, Recommendation, SubTheme, WorkArea"
						api={adminApi.uploadObservationExcel}
						name="observation_master_name"
						secondName="observation_master_recommendation"
						thirdName="sub_theme_name"
						fourthName="work_area_name"
						id="observation_master_id"
						type="observation"
						themeArray={observation}
						setIsLoading={setIsLoading}
					/>
					{isLoading ? (
						<div className="loader" style={{ marginTop: '200px' }}></div>
					) : currentItems?.length > 0 ? (
						<div className="row">
							<div
								className={'col-md-12 mt-3' + ' ' + TCStyles.tableWrapper}
								style={{
									border: '4px solid #b8b8b8',
									borderBottom: '1px solid #b8b8b8',
								}}
							>
								<table
									className={'table table-striped' + ' ' + TCStyles.iarTable}
								>
									<thead>
										<tr className={TCStyles.tr} style={{ height: '60px' }}>
											{columnsArray.map((column, index) => (
												<th
													className={TCStyles.th}
													key={index}
													colSpan={columnsArray.length}
													style={{ fontSize: '20px' }}
												>
													{column}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{currentItems?.map((resource, index) => (
											<ObservationRow
												resource={resource}
												index={index}
												key={index}
												span={columnsArray.length}
												observation={observation}
												setObservation={setObservation}
											/>
										))}
									</tbody>
								</table>
							</div>
							<PaginationComponent
								itemsPerPage={itemsPerPage}
								totalItems={observation?.length}
								paginate={paginate}
								activePage={currentPage}
							/>
						</div>
					) : (
						<div
							style={{
								textAlign: 'center',
								fontSize: '24px',
								fontWeight: '500',
								marginTop: '200px',
							}}
						>
							There is no data to show at the moment.
						</div>
					)}
				</div>
			</div>
			{/* <Database
				themeArray={observation}
				setThemeArray={setObservation}
				themeRef={observationRef}
				columnsArray={columnsArray}
				type="observation"
				excelFile={ObservationExcel}
				name="observation_master_name"
				secondName="observation_master_recommendation"
				id="observation_master_id"
				status="observation_master_status"
				setShow={setShow}
			/> */}
		</>
	);
};

export default Observation;
