import { useState } from 'react'
import Style from './forgotpassword.module.css'
import DataService from '../../services/DataServices';
import AuthUser from '../authUser/authuser';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../assets/images/loadin_gif.gif';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import { ErrorLog } from '../erroLog/errorLog';

const ForgetPassword = () => {
    const { logout } = AuthUser();
    const navigate = useNavigate();
    const { t } = useTranslation();
	useLanguageChange();
    const api = new DataService();
    const [email, setEmail] = useState('');
    const [Error, setError] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [successLoader,setLoader] = useState(false);
    const [successPopup,setSuccessPopup] = useState(false);
    const onHandleChange = (e) => {
        const { value } = e.target;
        setEmail(value)
    }

    const saveForgotPassword = async () => {
       
        let errorValue = validation(email)
        setError(errorValue);
        
        if (Object.keys(errorValue).length === 0) {
            let data = {
                userid: email
            }
            setSuccessMessage('');
            setError({});
            setSuccessPopup(true)
            setLoader(true);
            await api.forgotPasswordData(data).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    let message = res.data.message;
                    setEmail('');
                    setTimeout(()=>{
                        setLoader(false)
                        setSuccessMessage(message);
                    },2000)
                }
            }).catch((error) => {
                if (error.code !== "ERR_NETWORK") {
                    let errorCode = error.response.status;
                    let message = error.response.data.error.message;
                    if (errorCode === 401) {
                        logout();
                    }
                    if (errorCode === 400 || errorCode === 500) {
                        setTimeout(()=>{
                            setLoader(false)
                            setError({...Error,email:message});
                        },2000)
                        let errorLogData ={
                            api_name:"forgotpassword",
                            error_code:errorCode,
                            error_message:message
                        } 
                        ErrorLog(errorLogData);
                    }
                }
                else{
                    setSuccessMessage(error.message+". Please check your internet connection.");
                }
            })
        }
    }
    const validation = () => {
        let error = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!regex.test(email)) {
            error.email = t("Invalidemailid");
        }
        if (email === "") {
            error.email = t("pleaseEnterEmailId");
        }
        return error;
    }
    return (
        <div className='container'>
            <div className={Style.wraper}>
                <div>
                    <p className={Style.headertxt}>{t("forgotpassword")}</p>
                </div>
                <div>
                    <p className={Style.subtxt}>{t("ForgotpasswordTextPara")}</p>
                </div>
                <div className="col-md-4 mt-5 forgotpw">
                    <label className="form-label" style={{fontSize:"20px"}}>{t("email")}</label>
                    <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder={t("EnterRegisteredId")}
                        onChange={(e) => onHandleChange(e)}
                        value={email}
                    />
                </div>
                <div className="form-text text-danger mb-2">{Error && Error.email}</div>
                <div className='col-12 mt-4'>
                    <button
                        className='primary_btn'
                        onClick={saveForgotPassword}
                    >{t("Submit")}</button>
                    {/* <p className={Style.bottomtxt}>{successMessage && successMessage}</p> */}
                </div>
            </div>
            <Modal className="newObservation removeConform" show={successPopup} onHide={()=>setSuccessPopup(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="P-3 pt-0 text-center">
                        {successLoader===true?<img src={Loader} style={{width:"180px",height:"50px",border:"none"}} alt="loader"/>:null}
                        <h4 className="mb-4">{successMessage && successMessage}</h4>
                        <div className="form-text text-danger mb-2">{Error && Error.email}</div>
                        {successLoader===false ?<button className="secondary_btn small_btn" onClick={() => {
                            setSuccessPopup(false)
                            navigate("/login")
                            }}>{t("Clicktologin")}</button>:null}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default ForgetPassword
