import React, { useState, useEffect, useCallback, useContext } from 'react';
import IARStyles from '../iar/iar.module.css';
import AdminDataService from '../../../../services/AdminDataServices';
import { MemoizedTableRow } from './TableRow';
import { GlobalContext } from '../../../../context/GlobalState';
import InfiniteScroll from 'react-infinite-scroll-component';
import './table.css';
import PaginationComponent from '../ActivityLog/Pagination';
import TCStyles from '../tc/tc.module.css';

const Table = ({ columnsArray, steList }) => {
	//setting the state with incoming company Array
	const { getCurrentCompany, currentTab } = useContext(GlobalContext);
	const adminApi = new AdminDataService();
	//ste list

	//pagination set up
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	let indexOfFirstItem = '';
	let indexOfLastItem = '';

	// Logic to get the current items to display based on the current page
	if (getCurrentCompany()?.length <= 9) {
		indexOfLastItem = 9;
		indexOfFirstItem = 0;
	} else {
		indexOfLastItem = Math.min(
			currentPage * itemsPerPage,
			getCurrentCompany()?.length
		);
		indexOfFirstItem = Math.min(
			indexOfLastItem - itemsPerPage,
			getCurrentCompany()?.length
		);
	}
	const currentItems = getCurrentCompany()?.slice(
		indexOfFirstItem,
		indexOfLastItem
	);

	// Function to handle page changes
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	if (
		currentItems == null ||
		currentItems == undefined ||
		currentItems.length <= 0
	) {
		return (
			<div
				style={{
					margin: '50px',
					fontSize: '28px',
					fontWeight: 'bold',
					textAlign: 'center',
				}}
			>
				There is no data to show at the moment.
			</div>
		);
	}

	return (
		<>
			<div className="container">
				{currentItems?.length > 0 ? (
					<div className="row">
						<div className={'col-md-12' + ' ' + IARStyles.tableWrapper}>
							<table
								className={'table table-striped' + ' ' + IARStyles.iarTable}
							>
								<thead>
									<tr className={TCStyles.tr} style={{ height: '60px' }}>
										{columnsArray.map((column, index) => (
											<th
												className={IARStyles.th}
												key={index}
												style={{
													fontSize: '18px',
													whiteSpace:
														column == 'Date of Deactivation' ||
														column == 'Reason for Deactivation'
															? 'normal'
															: 'nowrap',
												}}
											>
												{column}
											</th>
										))}
										<th className={IARStyles.th} />
									</tr>
								</thead>
								<tbody>
									{currentItems?.map((item, index) => (
										<MemoizedTableRow
											item={item}
											index={index}
											steList={steList}
											key={index}
										/>
									))}
								</tbody>
							</table>
						</div>
					</div>
				) : (
					<div
						style={{
							margin: '50px',
							fontSize: '28px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						There is no data to show at the moment.
					</div>
				)}
			</div>
			<PaginationComponent
				itemsPerPage={itemsPerPage}
				totalItems={getCurrentCompany()?.length}
				paginate={paginate}
				activePage={currentPage}
			/>
		</>
	);
};

export default Table;
