import Style from "./ProcessFlowPreview.module.css"
import React from "react"
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
import logo from '../../../assets/pdf_images/logo.png'
import sidewaysarrow from "../../../assets/pdf_images/sidewaysarrow.png"
import downarrow from "../../../assets/pdf_images/Main down arrow for process.png"
import project_implement_img8 from "../../../assets/pdf_images/Partner_Logos_Combined.png"

const PageThreePreview = ({ pdfDataProcessflow, company }) => {

    return (<div className="demo">
        <div style={{ display: "flex", marginTop: "75px", marginLeft: "70px" }}>
            <img src={logo} style={{ width: "137px" }} alt="headerImg" />
            <div className={Style.imgtxtSectn}>
                <div className={Style.switchImgTxt}>
                    <p>Project Funded by</p>
                </div>
                <div className={Style.image_two}>
                    <img src={switchasia} alt="headerImg" />
                </div>
            </div>

        </div>
        <div className={Style.custom_container_pagethree}>
            <p className={Style.header}>
                2. Process Flow Diagram with Resource Mapping
            </p>
            <p className={Style.text_article_two}>
               The company's production process involves the following steps.
            </p>
            <div className={Style.flowdiagram_header_preview}>
                <p className="flowdiagram_headertxt">Resources</p>
                <p className="flowdiagram_headertxt" >Process</p>
                <p className="flowdiagram_headertxt" >Waste & Hazards</p>
            </div>
            <div className={Style.processDiagram}>
                <div className={Style.flowdiagram_header_diagram_preview}>
                    <div className="row">
                        {pdfDataProcessflow?.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="col-md-3 text-end mb-3" key={item.resource_id}>

                                        {JSON.parse(item?.resource_json).map((item, index) => {
                                            // console.log(item.resource_name)
                                            return (
                                                <p className="flowdiagram_left_txt_processflow" key={index} >{item?.resource_name}</p>


                                            )
                                        }
                                        )}
                                    </div>
                                    {/* <p className="gg-arrow-long-right-processflow"></p> */}
                                    <img src={sidewaysarrow} style={{ width: "43px", height: "12px" }} className='arrows' />

                                    <div className="col-md-3 mb-3" key={item.process_id} >
                                        <p className="flowdiagram_mid_txt d-flex align-items-center justify-content-center" style={{ marginBottom: "15px" }}>{item.process_name}</p>
                                        {/* <img src={rightImage} className="rightimg" /> */}
                                        {index == pdfDataProcessflow.length - 1 ? "" : < img src={downarrow} className='downarrow' />}
                                    </div>
                                    {/* < p className="gg-arrow-long-right2-processflow" ></p> */}
                                    <img src={sidewaysarrow} style={{ width: "43px", height: "12px" }} className='arrows' />

                                    <div className="col-md-3 mb-3" >
                                        {item.waste_json.map((waste, index) => {
                                            // console.log("item.waste_json", item.waste_json)
                                            return (< p key={index} className="flowdiagram_right_txt_processflow d-flex align-items-center text-align-center" > {waste.waste_name} < br /></p>)
                                        })}
                                    </div >
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                </div>
            </div>

            {company && company.map((i, index) => {
                return (
                    <div className="mt-4" key={index}>
                        {i.process_caption && (
                            <p className={Style.fig_text_preview}>Figure 1: {i.process_caption}</p>)}
                    </div>
                )
            })}

        </div >
        <div>
            <img src={project_implement_img8} style={{ width: "600px", marginTop: "120px", marginLeft: "95px" }} alt="footerImg" />
        </div>
        <div style={{ backgroundColor: "#1E989A", marginTop: "18px", width: "814px" }} >
            <p style={{ color: "white", textAlign: "center", fontSize: "12px", marginBottom: "0" }}>1</p>
        </div>
    </div >
    )
}
export default PageThreePreview
