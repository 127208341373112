import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import AdminDataService from '../../../../../services/AdminDataServices';
import '../ToggleButton/toggle.css';
import { GlobalContext } from './../../../../../context/GlobalState';

const ToggleStatus = ({
	active,
	inActive,
	status,
	resource,
	observation,
	setObservation,
}) => {
	const { setMessage } = useContext(GlobalContext);
	const adminApi = new AdminDataService();
	const [toggled, setToggled] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(status);

	useEffect(() => {
		setCurrentStatus(status);
	}, [status]);

	const toggleRef = useRef(null);

	const handleToggle = async () => {
		try {
			const newCurrentKey = currentStatus == 1 ? 0 : 1;

			const apiResult = await adminApi.updateObservationStatus(
				newCurrentKey,
				resource.work_area_id,
				resource.sub_theme_id,
				resource.observation_master_id
			);
			const themeArrayCopy = observation.map((observationItem) => {
				if (
					observationItem.observation_master_id ===
					resource.observation_master_id
				) {
					observationItem = {
						...observationItem,
						Main_status: newCurrentKey,
					};
					return observationItem;
				} else {
					return observationItem;
				}
			});
			setObservation(themeArrayCopy);

			setMessage(apiResult.data.message);
			setCurrentStatus(newCurrentKey);
			setToggled(!toggled);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Button
			ref={toggleRef}
			className={currentStatus == 1 ? 'toggle-btn toggled' : 'toggle-btn'}
			onClick={handleToggle}
			size="md"
			style={{ backgroundColor: currentStatus == 0 ? '#FFFCF4' : '#1E989A' }}
		>
			<span className="toggle-ball"></span>
			<span
				style={{
					paddingBottom: '10px',
					color: currentStatus ? 'white' : 'black',
				}}
			>
				{currentStatus == 0 ? active : inActive}
			</span>
		</Button>
	);
};

export default ToggleStatus;
