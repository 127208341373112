import React, { useEffect, useState, useRef } from 'react';
import AdminDataService from '../../../../services/AdminDataServices';
import WasteExcel from '../../../../assets/excel_files/waste.xlsx';
import Database from './database';
import { useContext } from 'react';
import { GlobalContext } from '../../../../context/GlobalState';

const Waste = () => {
	const columnsArray = ['Waste & Hazard', 'Activation Status', 'Is Custom ?'];
	const adminApi = new AdminDataService();
	const [waste, setWaste] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { currentLanguageId } = useContext(GlobalContext);

	const wasteRef = useRef({});

	useEffect(() => {
		document.title = 'Waste | REAP';

		async function getWasteData() {
			let language_id = 2;
			if (localStorage.getItem('language_id')) {
				language_id = localStorage.getItem('language_id');
			} else {
				language_id = currentLanguageId;
			}
			try {
				const result = await adminApi.getWasteData(language_id);
				setWaste(result?.data?.waste);
				setIsLoading(false);
				wasteRef.current = { ...wasteRef, 0: result?.data?.waste };
			} catch (error) {
				setIsLoading(false);

				console.log(error);
			}
		}
		setIsLoading(true);

		getWasteData();
	}, []);

	return (
		<Database
			themeArray={waste}
			setThemeArray={setWaste}
			themeRef={wasteRef}
			columnsArray={columnsArray}
			type="waste"
			excelFile={WasteExcel}
			name="waste_hazard_name"
			id="waste_hazard_id"
			placeholder="Search by Waste Hazard Name"
			api={adminApi.uploadWasteExcel}
			status="waste_hazard_status"
			isLoading={isLoading}
			setIsLoading={setIsLoading}
		/>
	);
};

export default Waste;
