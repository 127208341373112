import React, { useEffect, useContext, useState } from 'react';
import { GlobalContext } from '../../../../context/GlobalState';

const SuccessMessage = (props) => {
	const { message, setMessage } = useContext(GlobalContext);

	useEffect(() => {
		const timer = setTimeout(() => {
			setMessage('');
		}, 4000);

		return () => clearTimeout(timer);
	}, [message]);
	return (
		<div>
			{message && (
				<div
					className="bottom"
					style={{
						position: 'fixed',
						bottom: '0px',
						left: '0px',
						right: '0px',
						border: '5px solid #3B7E80',
						borderRadius: '25px 25px 0px 0px',
						textAlign: 'center',
						fontWeight: '900',
						fontSize: '45px',
						padding: '15px',
						background: '#1E989A',
						zIndex: '50',
						color: 'white',
					}}
				>
					<h6>{message}</h6>
				</div>
			)}
		</div>
	);
};

export default SuccessMessage;
