import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/admin/Reap_logo.png';
import DataService from '../../services/DataServices';
import { useLocation } from 'react-router-dom';
import Style from './header.module.css';
import AuthUser from '../authUser/authuser';
import Modal from 'react-bootstrap/Modal';
import { GrClose } from 'react-icons/gr';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import { toolsList } from '../utils/toolsList';
import { MdArrowBackIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import LoaderImg from '../../assets/images/loadin_gif.gif';
import {app_version} from '../../constant';
import { ErrorLog } from '../erroLog/errorLog';

const Header = ({
handleChangeLanguage,
setEnablerUrl,
showList,
setShowList,
}) => {
const api = new DataService();
let lanId = localStorage.getItem('lanId');
useLanguageChange();
const { t } = useTranslation();
const { logout, user } = AuthUser();
const [ReadLoader, setReadLeader] = useState(false);
const [unReadLoader, setUnReadLeader] = useState(false);
let getLanguage = localStorage.getItem('lan');
let location = useLocation();
let currentPath = location.pathname;
let currentPathLast = currentPath.split('/')[1];
let com_id = currentPath.substring(currentPath.lastIndexOf('/') + 1);

let currentPathList = currentPath.split('/');
let currntPageUrl = currentPathList[currentPathList.length - 2];
let currntPageUrlOne = currentPathList[currentPathList.length - 1];
const [openTools, setopenTools] = useState(false);
const [showTools, setShowTools] = useState(false);
const [mobmenuActive, setMobmenu] = useState(false);
const [arrowToggle, setArrowtoggle] = useState(false);
const [arrowUserprofle, setArrowuserprofle] = useState(false);
const [arrowTools, setArrowtools] = useState(false);
const [logoutPopup, setLogoutpopup] = useState(false);
const [notificationUnread, setNotification] = useState([{}]);
const [notificationAll, setNotificationCount] = useState([]);
const [ReadNotification, setReadNotification] = useState([]);
const [ErrorMessage, SetErrorMessage] = useState(false);
const [Message, setMessage] = useState('');
const navigate = useNavigate();
const [breadCrumb, setCompBreadCrumb] = useState({
	comp_name: '',
	comp_code: '',
	current_url: currentPathList[1],
	url_level_two: currentPathList[2],
});
const toggleFunc = () => {
	setArrowtoggle(!arrowToggle);
};
const toggleuserprfle = () => {
	setArrowuserprofle(!arrowUserprofle);
};

const [openNotifi, setopenNotifi] = useState(false);
const [openProfile, setopenProfile] = useState(false);
const mobileMenuFun = (e) => {
	e.stopPropagation();
	setMobmenu(true);
};
const MenuClose = (e) => {
	e.stopPropagation();
	setMobmenu(false);
};
const logOutFunction = () => {
	setLogoutpopup(true);
	setMobmenu(false);
	setopenProfile(false);
	setArrowuserprofle(false);
};
const logoutStatus = (status) => {
	if (status == 'yes') {
		logout();
		setMobmenu(false);
		setopenProfile(false);
		setArrowuserprofle(false);
		setLogoutpopup(false);
	} else {
		setLogoutpopup(false);
	}
};

const handleMobileMenu = (e) => {
	setMobmenu(false);
	MenuClose(e);
};
let menuRef = useRef();
let toolsRef = useRef();
let notificationRef = useRef();
const notificationFunction = () => {
	setopenNotifi(!openNotifi);
	setArrowtoggle(!arrowToggle);
	getNotifications();
	setTimeout(() => {
		notificationReadAllFunc();
	}, 1000);
};
const getNotifications = async () => {
	setUnReadLeader(true);
	setReadLeader(true);
	await api
		.getNotifications()
		.then((response) => {
			let responsedata = response.data;

			setNotificationCount(responsedata);
			let unreadNoti = JSON.parse(responsedata[0][0].unread);
			let readNoti = JSON.parse(responsedata[1][0].readNotification);
			setReadNotification(readNoti);
			setNotification(unreadNoti);
			setTimeout(() => {
				setUnReadLeader(false);
				setReadLeader(false);
			}, 1000);
		})
		.catch((error) => {
			if (error.code !== 'ERR_NETWORK') {
				let errorCode = error.response.status;
				let errMsg = error.response.data.error.message;
				if (errorCode == 401) {
					logout();
				}
				if (errorCode == 400 || errorCode == 500) {
                    let errorLogData ={
                        api_name:"getNotifications",
                        error_code:errorCode,
                        error_message:errMsg
                    } 
                    ErrorLog(errorLogData);
                }
			} else {
				SetErrorMessage(true);
				setMessage('Please check your internet connection.');
			}
		});
};
const notificationReadAllFunc = async () => {
	await api
		.getReadAllNotification()
		.then(() => {
			//setNotificationReset(0)
			//getNotifications();
		})
		.catch((error) => {
			if (error.code !== 'ERR_NETWORK') {
				let errorCode = error.response.status;
				let errMsg = error.response.data.error.message;
				if (errorCode == 401) {
					logout();
				}
				if (errorCode == 400 || errorCode == 500) {
                    let errorLogData ={
                        api_name:"readallnotification",
                        error_code:errorCode,
                        error_message:errMsg
                    } 
                    ErrorLog(errorLogData);
                }
			} else {
				SetErrorMessage(true);
				setMessage('Please check your internet connection.');
			}
		});
};
const getCompanyDetailsFunc = async () => {
	let data = {
		company_id: com_id,
	};
	await api
		.getcompanybyid(data)
		.then((response) => {
			if (response.status == 200 || response.status == 201) {
				let com_data = response.data.company;
				setCompBreadCrumb({
					...breadCrumb,
					comp_name: com_data.length>0 ? com_data[0].company_name:'',
					comp_code: com_data.length>0?com_data[0].company_code:'',
				});
			}
		})
		.catch((error) => {
			if (error.code !== 'ERR_NETWORK') {
				let errMsg = error.response.data.error.message;
				let errorCode = error.response.status;
				if (errorCode == 401) {
					logout();
				}
				if (errorCode == 400 || errorCode == 500 ) {
					SetErrorMessage(true);
					setMessage(errMsg);
					let errorLogData ={
                        api_name:"getcompanybyid",
                        error_code:errorCode,
                        error_message:errMsg
                    } 
                    ErrorLog(errorLogData);
				}
			} else {
				SetErrorMessage(true);
				setMessage('Please check your internet connection.');
			}
		});
};
const goBack = () => {
	setShowList(true);

	navigate(-1);
};
const defaultTabSelection = () => {
	if (
		currntPageUrl != 'IARPreparation' &&
		currntPageUrl != 'addNewObservation'
	) {
		localStorage.setItem('defaultTab', 'es');
		localStorage.setItem('defaultTabMobile', 'MobilePro');
	}
};
useEffect(() => {
	if (currentPathLast != 'login' && currentPathLast!="/" ) {
		getNotifications();
		defaultTabSelection();
	}
	if (
		currentPathLast == 'addGallery' ||
		currentPathLast == 'checkpoints' ||
		currentPathLast == 'CompanyDetails'
	) {
		getCompanyDetailsFunc();
	}
	function handleClickOutside(event) {
		if (toolsRef.current && !toolsRef.current.contains(event.target)) {
			setopenTools(false);
			setArrowtools(false);
		}
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setopenProfile(false);
			setArrowuserprofle(false);
		}
		if (
			notificationRef.current &&
			!notificationRef.current.contains(event.target)
		) {
			setopenNotifi(false);
			setArrowtoggle(false);
		}
	}
	document.addEventListener('click', handleClickOutside);
	document.addEventListener('click', handleMobileMenu);
}, [menuRef, toolsRef, notificationRef, currentPath]);
return (
	<>
		{currentPath == '/login' && window.innerWidth < 768 ? null : (
			<div className={Style.paddingHeader + ' ' + 'header header_tc_web'}>
				<div className={Style.mobilePadding + ' ' + 'container'}>
					{window.innerWidth > 992 ? (
						<div className="row">
							<div className="col-md-6 header-left-sec">
								{currentPath != '/' ? (
									<ul className="list-inline">
										<li className="list-inline-item">
											<Link to="/dashboard">
												<img
													src={Logo}
													className="logo"
													alt="REAP logo"
													style={{ objectFit: 'contain' }}
												/>
											</Link>
										</li>
										{currentPathLast != '' &&
										currentPathLast != 'login' &&
										currentPathLast != 'chooselanguage' ? (
											<li className="list-inline-item">
												<Link to="/dashboard" className="workspace">
													{t('WorkSpace')}
												</Link>
											</li>
										) : null}
										{currentPathLast != '' &&
										currentPathLast != 'login' &&
										currentPathLast != 'chooselanguage' ? (
											<li className="list-inline-item tools" ref={toolsRef}>
												<button
													onClick={() => {
														setopenTools(!openTools);
													}}
													className="dropdown_btn"
													style={{
														paddingRight: lanId == 1 ? '20px' : '10px',
													}}
												>
													{t('Tools')}
													<span
														className={arrowTools ? 'arrow_down' : 'arrow_up'}
													></span>
												</button>
												{openTools ? (
													<ul className="dropdown_list_tools">
														{toolsList?.map((tool, index) => (
															<div
																key={index}
																onClick={() => {
																	setEnablerUrl(tool.url);
																	setopenTools(false);
																	navigate('/tools');
																}}
															>
																<li>
																	<div className={Style.toolsMaintxt}>
																		<p>{tool.name}</p>
																	</div>
																	<div className="d-flex">
																		<div className={Style.toolsSubtxt}>
																			<p>{tool.detail}</p>
																		</div>
																	</div>
																</li>
																{index === toolsList.length - 1 ? null : (
																	<hr className={Style.hr} />
																)}
															</div>
														))}
													</ul>
												) : null}
											</li>
										) : null}
									</ul>
								) : (
									<ul className="list-inline">
										<li className="list-inline-item">
											<Link to="/dashboard">
												<img
													src={Logo}
													className="logo"
													alt="REAP logo"
													style={{ objectFit: 'contain' }}
												/>
											</Link>
										</li>
									</ul>
								)}
							</div>
							{currentPath != '/' && currentPath != '/login' ? (
								<div className="col-md-6 header-right-sec">
									<ul className="list-inline float-end">
										<li className="list-inline-item" ref={notificationRef}>
											<button
												onClick={() => notificationFunction()}
												className={
													openNotifi == true
														? 'dropdown_btn dropdown_btnColor'
														: 'dropdown_btn'
												}
												style={{ paddingRight: lanId == 1 ? '20px' : '10px' }}
											>
												{t('Notifications')}
												<span
													className={
														arrowToggle
															? 'arrow_down dropdown_btnBorderColor'
															: 'arrow_up '
													}
												></span>
											</button>
											{openNotifi ? (
												<div className="dropdown_list_notification">
													<div className="notificationHeight">
														<ul>
															<li className="NotifiHeader">{t('Unread')}</li>
															<li className="notificationPaddinglist">
																{notificationUnread != null ? (
																	notificationUnread &&
																	notificationUnread.map(
																		(notification, index) => {
																			return (
																				<ul key={index}>
																					<li>
																						<Link>
																							<p
																								className={
																									Style.notificationMaintxt
																								}
																							>
																								<span>
																									{
																										notification.notification_title
																									}
																								</span>
																							</p>
																							<p className="d-flex">
																								<span
																									className={
																										Style.notificationSubtxt
																									}
																								>
																									<span>
																										{
																											notification.notification_description
																										}
																									</span>
																								</span>
																								<span
																									className={
																										Style.notificationSubtxtDate
																									}
																									style={{
																										textAlign: 'right',
																									}}
																								>
																									<span>
																										{
																											notification.notification_created_date
																										}
																									</span>

																									<span>
																										{
																											notification.notification_time
																										}
																									</span>
																									{/* <span>16:52</span> */}
																								</span>
																							</p>
																						</Link>
																						<hr className={Style.hr} />
																					</li>
																				</ul>
																			);
																		}
																	)
																) : unReadLoader == true ? (
																	<img
																		src={LoaderImg}
																		width="100"
																		alt="loaader"
																	/>
																) : (
																	<ul style={{ fontSize: '16px' }}>
																		<li>{t("NounreadList")}</li>
																	</ul>
																)}
															</li>
															<li className="NotifiHeaderTwo">{t("Older")}</li>
															<li className="notificationPaddinglist">
																{ReadNotification != null ? (
																	ReadNotification.map(
																		(notification, index) => {
																			return (
																				<ul key={index}>
																					<li>
																						<Link>
																							<p
																								className={
																									Style.notificationMaintxt
																								}
																							>
																								<span>
																									{
																										notification.notification_title
																									}
																								</span>
																							</p>
																							<p className="d-flex">
																								<span
																									className={
																										Style.notificationSubtxt
																									}
																								>
																									<span>
																										{
																											notification.notification_description
																										}
																									</span>
																								</span>
																								<span
																									className={
																										Style.notificationSubtxtDate
																									}
																									style={{
																										textAlign: 'right',
																									}}
																								>
																									<span>
																										{
																											notification.notification_created_date
																										}
																									</span>
																									<span>
																										{
																											notification.notification_time
																										}
																									</span>
																									{/* <span>16:52</span> */}
																								</span>
																							</p>
																						</Link>
																						<hr className={Style.hr} />
																					</li>
																				</ul>
																			);
																		}
																	)
																) : ReadLoader == true ? (
																	<img
																		src={LoaderImg}
																		width="100"
																		alt="loaader"
																	/>
																) : (
																	<ul style={{ fontSize: '16px' }}>
																		<li>{t("Nonotifications")}</li>
																	</ul>
																)}
															</li>
														</ul>
													</div>
												</div>
											) : null}
										</li>
										<li className="list-inline-item">
											<Link
												to="/help"
												className="help"
												style={{
													color: currentPathLast == 'help' ? '#1E989A' : '',
												}}
											>
												{t('Help')}
											</Link>
										</li>
										<li className="list-inline-item" ref={menuRef}>
											<button
												onClick={() => {
													setopenProfile(!openProfile);
													toggleuserprfle();
												}}
												className={
													openProfile == true
														? 'dropdown_btn dropdown_btnColor'
														: 'dropdown_btn'
												}
												style={{ paddingRight: lanId == 1 ? '20px' : '10px' }}
											>
												<HiOutlineUserCircle className={Style.userStyle} />
												{t('UserProfile')}
												<span
													className={
														arrowUserprofle
															? 'arrow_down dropdown_btnBorderColor'
															: 'arrow_up'
													}
												></span>
											</button>
											{openProfile ? (
												<ul
													className="dropdown_list"
													style={{ left: lanId == 1 ? '46px' : '-8px' }}
												>
													<li onClick={() => setopenProfile(false)}>
														<Link to="/viewProfie">{t('ViewProfile')}</Link>
													</li>
													<li onClick={() => setopenProfile(false)}>
														<Link to="/chooselanguage">
															{t('ChangeLanguage')}
														</Link>
													</li>
													<li onClick={() => setopenProfile(false)}>
														<Link to="/changepassword">
															{t('ChangePassword')}
														</Link>
													</li>
													<li onClick={() => setopenProfile(false)}>
														<Link to="/aboutApp">{t('AboutREAPDS')}</Link>
													</li>
													<li>
														<Link onClick={logOutFunction}>
															{t('LogOut')}
														</Link>
													</li>
												</ul>
											) : null}
										</li>
									</ul>
								</div>
							) : (
								<div className={Style.desktopLanguage + ' ' + 'col-6'}>
									<ul>
										<li>
											<ul>
												<li>выберите язык</li>
												<li>Change Language</li>
											</ul>
										</li>
										<li
											onClick={() => handleChangeLanguage('ru')}
											className={
												getLanguage == 'ru' ? Style.activecolor : null
											}
										>
											Русский
										</li>
										<li
											onClick={() => handleChangeLanguage('en')}
											className={
												getLanguage == 'en' ? Style.activecolor : null
											}
										>
											English
										</li>
									</ul>
								</div>
							)}
						</div>
					) : (
						<div>
							<div className="col-md-12 header-left-sec">
								<ul className="list-inline ">
									<li
										className={
											Style.listInlineItem + ' ' + 'list-inline-item'
										}
									>
										{
											currentPathLast !== 'checkpoints' &&
											currentPathLast !== 'CompanyDetails' &&
											currentPathLast !== 'viewProfie' &&
											currentPathLast !== 'notificationMobieview' &&
											currentPathLast !== 'tools' &&
											currentPathLast !== 'addNewObservation' &&
											currentPathLast !== 'changepassword' &&
											currentPathLast !== 'addCompany' &&
											currentPathLast !== 'aboutApp' &&
											currentPathLast !== 'help' ? (
												<Link
													to={
														currentPathLast !== 'uploadSignature'
															? '/dashboard'
															: '/uploadSignature'
													}
												>
													<img
														src={Logo}
														className="logo"
														alt="REAP logo"
														style={{ objectFit: 'contain' }}
													/>
												</Link>
											) : (
												<div className={Style.headingTitle}>
													<MdArrowBackIos onClick={goBack} />
													<div>
														<h1>
															{currntPageUrl == 'addgallery'
																? t("Gallery")
																: currntPageUrl == 'DateofIAvisit' ||
																	currntPageUrl == 'IARPreparation'
																? t("InitialAssessmentVisit")
																: currntPageUrl == 'addNewObservation'
																? t("AddNewObservation")
																:  currntPageUrl == 'checkpoints'
																? t("Checkpoints"):currntPageUrl == "notes"?
															    t("Notes"):currntPageUrl == "CompanyDetails"?
																t("companyDetails"):currntPageUrl=="dateofIAvisit"?t("InitialAssessmentVisit"):currntPageUrl}
															{currntPageUrlOne == 'viewProfie'
																? t("ViewProfile")
																: null}
															{currntPageUrlOne == 'notificationMobieview'
																? t("Notifications")
																: null}
															{currntPageUrlOne == 'changepassword'
																? t("ChangePassword")
																: null}
															{currntPageUrlOne == 'addCompany'
																? t("AddNewCompany")
																: null}
															{currntPageUrlOne == 'aboutApp'
																? t("AboutREAPDS")
																: null}
															{currntPageUrlOne == 'help' ? t("Help") : null}
															{currntPageUrlOne == 'tools' ? t("Tools") : null}
															
														</h1>
														{currntPageUrlOne !== 'tools' &&
														currntPageUrlOne !== 'addCompany' &&
														currntPageUrlOne !== 'changepassword' &&
														currntPageUrlOne !== 'viewProfie' &&
														currntPageUrlOne !== 'aboutApp' &&
														currntPageUrlOne !== 'help' ? (
															<h3>
																{breadCrumb.comp_name && breadCrumb.comp_name}
															</h3>
														) : null}
													</div>
												</div>
											) // <div className={Style.headingText}>Tools</div>
										}
									</li>
									{currentPathLast == 'login' ? (
										<li>
											<div className={Style.desktopLanguage}>
												<ul>
													<li>
														<ul>
															<li>выберите язык</li>
															<li>Change Language</li>
														</ul>
													</li>
													<li
														onClick={() => handleChangeLanguage('ru')}
														className={
															getLanguage == 'ru' ? Style.activecolor : null
														}
													>
														Русский
													</li>
													<li
														onClick={() => handleChangeLanguage('en')}
														className={
															getLanguage == 'en' ? Style.activecolor : null
														}
													>
														English
													</li>
												</ul>
											</div>
										</li>
									) : null}
									{currentPathLast != '' &&
									currentPathLast != 'login' &&
									currentPathLast != 'chooselanguage' &&
									currentPathLast != 'uploadSignature' ? (
										<li>
											<div
												className="hambergerMenu"
												onClick={mobileMenuFun}
												style={{ backgroundColor: '#FFFCF4' }}
											>
												{notificationUnread != null && (
													<div className="notifiRedicon"></div>
												)}
												<span></span>
												<span></span>
												<span></span>
											</div>
											<div
												className={
													mobmenuActive
														? 'mobileMenu mobileMenuActive'
														: 'mobileMenu '
												}
											>
												<div className="mobileMenudisplay">
													<div className="menuOpenwrapper">
														<div className="closeMenu">
															<div
																className="hambergerMenu"
																onClick={MenuClose}
															>
																{mobmenuActive == false &&
																	notificationUnread != null && (
																		<div className="notifiRedicon"></div>
																	)}
																<GrClose className={Style.closeButton} />
															</div>
														</div>
														<h2>{t("Menu")}</h2>
													</div>
													<ul className="mobileMenu-cat">
														<li>
															<Link to="/dashboard">{t("HomePage")}</Link>
														</li>

														<li>
															<Link to="/notificationMobieview">
																{t("Notifications")}
															</Link>
															{notificationAll[2] &&
																notificationAll[2].map((Noticount, index) => {
																	return (
																		<label
																			className="notif-label"
																			key={index}
																		>
																			{Noticount.count}
																		</label>
																	);
																})}
														</li>

														<li>
															<Link to="/help">{t('Help')}</Link>
														</li>
														<li>
															<ul>
																<li>
																	<Link to="/viewProfie">{t("ViewProfile")}</Link>
																</li>
																<li>
																	<Link to="/chooselanguage">
																		{t("ChangeLanguage")}
																	</Link>
																</li>
																<li>
																	<Link to="/changepassword">
																		{t("ChangePassword")}
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
													<ul className="mobLogout">
														<li
															className="mobLogPadding"
															onClick={logOutFunction}
														>
															{t("LogOut")}
														</li>
														<li>
															<ul>
																<li>
																	<Link
																		to="/aboutApp"
																		style={{ color: '#6D6D6D' }}
																	>
																		<div>{t("AboutREAPDS")}</div>
																		<div>{t("Version")} {app_version}</div>
																	</Link>
																</li>
															</ul>
														</li>
													</ul>
												</div>
											</div>
										</li>
									) : null}
								</ul>
							</div>
						</div>
					)}
				</div>
				{/* showing iframe */}

				<Modal
					className="newObservation removeConform"
					show={logoutPopup}
					onHide={() => setLogoutpopup(false)}
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<div className="P-3 pt-0">
							<h4 className="mb-4 text-left">{t('logoutConform')}</h4>
							<div className=" text-center">
								<button
									className="secondary_btn small_btn"
									onClick={() => logoutStatus('no')}
								>
									{t("No")}
								</button>
								<button
									className="secondary_btn small_btn"
									onClick={() => logoutStatus('yes')}
								>
									{t("Yes")}
								</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					className="newObservation removeConform"
					show={ErrorMessage}
					onHide={() => SetErrorMessage(false)}
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<div className="P-3 pt-0 text-center">
							<h4 className="mb-4">{Message && Message}</h4>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		)}
	</>
);
};
export default Header;
