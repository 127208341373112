import { useState } from 'react';
import { useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode';
export default function AuthUser() {
	let navigate = useNavigate();
	const getToken = () => {
		const tokenString = localStorage.getItem('token');
		const userToken = tokenString;
		return userToken;
	};

	const getUser = () => {
		const userString = localStorage.getItem('userInfo');
		const user_detail = JSON.parse(userString);
		return user_detail;
	};
	const getExpiry = () => {
		const userString = localStorage.getItem('expiry');
		const exeTime = JSON.parse(userString);
		return exeTime;
	};
	const [token, setToken] = useState(getToken());
	const [user, setUser] = useState(getUser());
	const [expiryT, setExpiry] = useState(getExpiry());
	const saveToken = (TOKEN) => {
		localStorage.setItem('token', JSON.stringify(TOKEN.accessToken));
		let DECODE_TOKEN = jwtDecode(TOKEN.accessToken);
		localStorage.setItem('userInfo', JSON.stringify(DECODE_TOKEN));
		localStorage.setItem('expiry', DECODE_TOKEN.exp);
		let uploadSignature = TOKEN.isUploadSignature;
		setToken(token);
		setUser(user);
		if (DECODE_TOKEN != undefined) {
			if (window.innerWidth > 768) {
				navigate('/dashboard');
			} else {
				if (uploadSignature != null && uploadSignature.length !== 0) {
					navigate('/dashboard');
				} else {
					navigate('/uploadSignature');
				}
			}
		} else {
			console.log('user invalid');
		}
	};
	const LocalstorageClear = () => {
		localStorage.removeItem('userInfo');
		localStorage.removeItem('udid');
		localStorage.removeItem('processFlowBucket');
		localStorage.removeItem('masterData');
		localStorage.removeItem('SCPimprovementBucket');
		localStorage.removeItem('exeSummaryBucket');
		localStorage.removeItem('token');
		localStorage.removeItem('obsevationBucket');
		localStorage.removeItem('expiry');
		localStorage.removeItem('companyDetails');
		localStorage.removeItem('toolsToken');
		localStorage.removeItem('toolsList');
		localStorage.removeItem('companyNotesBucket');
		localStorage.removeItem('defaultTabMobile');
		localStorage.removeItem('productsAll');
		localStorage.removeItem('defaultTab');
		localStorage.removeItem('currentWorkArea');
		localStorage.removeItem('currentSubtheme');
		localStorage.removeItem('currentObservation');
		localStorage.removeItem("regionName");
		localStorage.removeItem("regionAbbr");
		localStorage.removeItem("regionId");
		localStorage.removeItem("countryName");
		localStorage.removeItem("countryId");
		localStorage.removeItem("countryAbbr");
		localStorage.removeItem("currentToolpath");
	};
	const logout = () => {
		LocalstorageClear();
		navigate('/login');
	};

	return {
		setToken: saveToken,
		token,
		user,
		getUser,
		getToken,
		logout,
		expiryT,
		//expiryTime,
	};
}
