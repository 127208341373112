import DataService from "../../services/DataServices"
export const ErrorLog = (data)=>{
    console.log("data----------->",data);
    let api = new DataService();
    const erroApiCall =()=>{
         api.serverErrorLog(data).then((response)=>{
              //console.log(response);
         }).catch((error)=>{
              console.log(error);
         })
    }
    erroApiCall();
}