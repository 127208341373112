import React, { useState } from 'react';
import IARPreparationStyles from './IARPreparation.module.css';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExecutiveSummary from '../../components/iar/executiveSummary';
import ProcessFlowDiagram from '../../components/iar/processFlowDiagram';
import ScpImprovement from '../../components/iar/scpImprovement';
import OnsitePhotographs from '../../components/iar/onsitePhotographs';
import Modal from 'react-bootstrap/Modal';
import LoaderImg from '../../assets/images/loadin_gif.gif';
import { useLocation } from 'react-router-dom';
import TcBreadCrumb from '../tc_breadcrumb/tc_breadcrumb';
import PfdObTab from '../pfdObTab/pfdObTab';
import PageOnePreview from '../pdfPreview/FrontPagePreview/FrontPagePreview';
import PageTwoPreview from '../pdfPreview/ExecutiveSummeryPreview/ExecutiveSummeryPreview';
import PageThreePreview from '../pdfPreview/ProcessFlowPreview/ProcessFlowPreview';
import PageFivePreview from '../pdfPreview/SCPImprovementPreview/SCPImprovementPreview';
import PageEightPreview from '../pdfPreview/NotePreview/NotePreview';
import PageNinePreview from '../pdfPreview/GalleryPreview/GalleryPreview';
import DataService from '../../services/DataServices';
import AuthUser from "../authUser/authuser";
import { useTranslation } from 'react-i18next';
import NoteIcon from '../../assets/images/notes.png'
import GalleryIcon from '../../assets/images/gallery.png'
import MyDocument from '../myDocument/mydocument';
import { PDFDownloadLink, Document, Page, PDFViewer, BlobProvider } from '@react-pdf/renderer'
const IARPreparation = () => {
    const api = new DataService();
    const { logout } = AuthUser();
    const { t } = useTranslation();
    let currentLocation = useLocation();
    let pathName = currentLocation.pathname;
    let com_id = pathName.substring(pathName.lastIndexOf('/') + 1);
    const [tcname, setTcname] = useState();
    const [executiveData, setexecutiveData] = useState();
    const [company, setCompany] = useState([]);
    const [scpImrovment, setScpImprovement] = useState();
    const [userSignature, setUserSignature] = useState();
    const [gallery, setGallery] = useState();
    const [pdfDataProcessflow, setPdfDataProcessflow] = useState();
    const [iarName, setIARName] = useState('');
    const [modalLoader, setModalLoader] = useState(false);
    const [IarVersionNumber, setIarVersionNumber] = useState()
    const GetPdfData = async (status) => {
        setModalLoader(true);
        let data = {
            company_id: com_id,
            isPreview: status === 1 ? 1 : 0
        };
        await api.pdfdata(data).then((response) => {
            let responsedata = response.data;
            setexecutiveData(JSON.parse(responsedata.pdfData[0].EXECUTIVE_SUMMARY));
            setCompany(JSON.parse(responsedata.pdfData[0].COMPANY_ARRAY));
            const comapnyname = JSON.parse(responsedata.pdfData[0].COMPANY_ARRAY);
            setIARName(comapnyname[0].iar_version);
            localStorage.setItem('IARVersionPDF', comapnyname[0].iar_version);
            setIarVersionNumber(comapnyname[0].iar_version)
            let iarVerstion = comapnyname[0].iar_version;
            setPdfDataProcessflow(JSON.parse(responsedata.pdfData[0].PROCESS_FLOW_ARRAY));
            const processFLow = JSON.parse(response?.data?.pdfData[0]?.PROCESS_FLOW_ARRAY);
            let resourceArray = [];
            processFLow.map((process) => {
                resourceArray.push(JSON.parse(process?.resource_json));
            });
            setTcname(JSON.parse(responsedata.pdfData[0].USER_SIGNATURE));
            setScpImprovement(JSON.parse(responsedata.pdfData[0].SCP_IMPROVEMENT_ARRAY));
            setUserSignature(responsedata.base64Signature);
            setGallery(responsedata.linkedImg);
            setModalLoader(false);
            //Check if TC requested for PDF download
             if (status == 0) {
                setModalLoader(true);
                downloadPDFFunction();
             }
            
        }
        ).catch((error) => {

            if (error.code !== "ERR_NETWORK") {
                let errorCode = error.response.status;
                if (errorCode === 401) {
                    logout();
                }
            }
        });
    };
    const downloadPDFFunction = () => {
        setTimeout(() => {
            const link = document.querySelector('.DownloadLink');
            if(link.href.length>0&&link.href!==''){
                link.click();
                setModalLoader(false);
            }
        }, 5000)
    }
    const [DefaultTab, setDefaultTab] = useState(
        localStorage.getItem('defaultTab') ? localStorage.getItem('defaultTab') : 'es');

    const [showPDF, setShowPDF] = useState(false);
    const handleClosePDF = () => setShowPDF(false);
    const handleShowPDF = (status) => {
        setShowPDF(true);
        GetPdfData(status);
    };

    const tabClick = (key) => {
        let defaultTab = key.target.dataset.rrUiEventKey;
        setDefaultTab(defaultTab);
        localStorage.setItem('defaultTab', defaultTab);
    };

    const [loader, setLoader] = useState(false);
    const downloadPDF = (status) => {
        GetPdfData(status);
    };

    return (
        <div className="deskWrapper">
            <div className="container">
                 {window.innerWidth > 768 ? <TcBreadCrumb /> : null}
                <div className="row mt-2">
                    <div className={window.innerWidth < 768 ? 'col-md-12 px-0' + ' ' + IARPreparationStyles.iar_preparation : 'col-md-12' + ' ' + IARPreparationStyles.iar_preparation}>
                        {window.innerWidth > 768 ? (<Tabs defaultActiveKey={DefaultTab} justify className="tabPadding tabWebview" onClick={(key) => tabClick(key)}>
                            <Tab eventKey="es" title={t("ExecutiveSummary")} className='tabItem'>
                                <ExecutiveSummary />
                            </Tab>
                            <Tab eventKey="pfd" title={t("ProcessFlowDiagram")} className='tabItem'>
                                <ProcessFlowDiagram />
                            </Tab>
                            <Tab eventKey="scpi" title={t("SCPImprovementOptions")} className='tabItem'>
                                <ScpImprovement />
                            </Tab>
                            <Tab eventKey="osp" title={t("OnsitePhotographs")} className='tabItem'>
                                <OnsitePhotographs tabActive={DefaultTab} />
                            </Tab>
                        </Tabs>
                        ) : (
                            <PfdObTab />
                        )}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        {window.innerWidth > 768 ? (
                            <div>
                                <button className="secondary_btn">
                                    <Link to={'/checkpoints/notes/' + com_id}><img src={NoteIcon} alt="notes" style={{ width: "17px" }} /> {t("Notes")}</Link>
                                </button>
                                <button className="secondary_btn">
                                    <Link to={'/checkpoints/addgallery/' + com_id}><img src={GalleryIcon} alt="Gallery" style={{ width: "17px" }} /> {t("Gallery")}</Link>
                                </button>
                            </div>) : null}
                    </div>


                    {window.innerWidth > 768 ? (<div className="col-md-6 text-end">
                        <button className="secondary_btn" onClick={() => handleShowPDF(1)}>{t("PreviewDocument")}</button>
                        <>
                            {!loader ? (
                                <button className="secondary_btn" onClick={() => downloadPDF(0)}>{t("DownloadDraftIAR")}</button>) : (
                                <button className="secondary_btn pdfdownload" type="button" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Downloading...</button>)}
                        </> 

                    {loader ? (<div>
                        <img src={LoaderImg} />
                          </div>) : ""} 
                  </div>) : null}
                </div>

                <div className="row">
                    <div className="col-md-12"></div>
                </div>
            </div>
            {modalLoader ? (<div className='loaderPDF'>
                <img src={LoaderImg} alt="loaderImg" />

            </div>) : (

                <Modal className="galleryList newObservation" show={showPDF} onHide={handleClosePDF} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-center">
                            <h2>IAR Preview-{com_id}</h2>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row mt-2">
                            <div className="col-12 mb-3 pdfPreview">
                                <div className="border">
                                    <PageOnePreview company={company} />
                                </div>
                                <div className="border">
                                    <PageTwoPreview executiveData={executiveData} company={company} />
                                </div>
                                <div className="border">
                                    <PageThreePreview pdfDataProcessflow={pdfDataProcessflow} company={company} />
                                </div>
                                <div className="border">
                                    <PageFivePreview scpImrovment={scpImrovment} />
                                </div>
                                <div className="border">
                                    <PageEightPreview tcname={tcname} company={company} userSignature={userSignature} />
                                </div>
                                <div className="border">
                                    <PageNinePreview gallery={gallery} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
            {/* <div><PDFViewer style={{ width: "100%", height: "500px" }}>
                <MyDocument 
                   company={company} 
                   executiveData={executiveData} 
                   pdfDataProcessflow={pdfDataProcessflow}
                   scpImrovment={scpImrovment}
                   gallery={gallery}
                   tcname={tcname} userSignature={userSignature}
                />
            </PDFViewer></div> */}
            <button onClick={GetPdfData} style={{display:"none"}}>Fetch Data</button>
            {company.length !=0 && (
                <PDFDownloadLink
                    document={<MyDocument 
                          company={company} 
                          executiveData={executiveData}
                          pdfDataProcessflow={pdfDataProcessflow}
                          scpImrovment={scpImrovment}
                          gallery={gallery}
                          tcname={tcname} userSignature={userSignature}
                          />}
                    fileName={company && company[0].iar_version}
                    className='DownloadLink'
                >
                    {({ loading, error }) =>
                        loading ? 'downloading....' : ''
                    }
                </PDFDownloadLink>
            )}
        </div>
    );
};

export default IARPreparation;