import React from 'react';
import Logo from '../../assets/images/admin/Reap_logo.png';
import { useNavigate, useLocation } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();
	const path = useLocation();

	let adminPath = path.pathname;
	let adminCurrentpath = adminPath.split('/')[1];

	function handleGoBack() {
		navigate(-1);
	}

	// if (adminCurrentpath == 'cms') {
	// 	navigate('cms');
	// }
	return (
		<div className="deskWrapper">
			<div className="container">
				<div style={{ marginLeft: '10px' }}>
					<img src={Logo} style={{ width: '200px', height: '100px' }} />
				</div>
				<div style={{ paddingLeft: '20px' }}>
					<div
						style={{ fontSize: '72px', color: '#1E989A', fontWeight: '900' }}
					>
						404
					</div>
					<div
						style={{
							fontSize: '60px',
							fontWeight: '600',
							color: '#5A5A5A',
							marginTop: '-30px',
						}}
					>
						error
					</div>
					<div
						style={{
							font: 'normal normal medium 40px/48px Roboto',
							fontSize: '30px',
							color: '#6D6D6D',
							marginTop: '30px',
							fontWeight: '500',
						}}
					>
						The URL you requested cannot be found.
					</div>
					<button
						onClick={handleGoBack}
						style={{
							width: '550px',
							backgroundColor: '#1E989A',
							color: 'white',
							fontWeight: 'bold',
							fontSize: '16px',
							textAlign: 'center',
							padding: '6px',
							borderRadius: '5px',
							boxShadow: 'none',
							border: '1px solid #1E989A',
							marginTop: '40px',
						}}
					>
						Go Back to Previous Page
					</button>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
