// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section{
    margin-bottom: 30px;
}
.paragraph{
    font-size: 18px;
}
.heading{
    color:#5A5A5A;
    font-size: 20px;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .paragraph{
        font-size: 15px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/StaticPages/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".section{\r\n    margin-bottom: 30px;\r\n}\r\n.paragraph{\r\n    font-size: 18px;\r\n}\r\n.heading{\r\n    color:#5A5A5A;\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n    .paragraph{\r\n        font-size: 15px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
