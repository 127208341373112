import React, { useEffect, useState, useRef } from 'react';
import Style from '../header/header.module.css';
import { json, useNavigate } from 'react-router-dom';
import { toolsList } from '../utils/toolsList';
import { Link } from 'react-router-dom';
import './style.css';
import { userids } from './userid';

const Tools = ({ url, setEnablerUrl, showList, setShowList }) => {
	const isMobile = window.innerWidth < 768;
	const navigate = useNavigate();

	const [loader, setLoader] = useState(true);
	const [error, setError] = useState(false);
	const [attemptNumber, setAttemptNumber] = useState(1);

	//sending message to enabler from iframe

	useEffect(() => {
		if (localStorage.getItem('userInfo')) {
			//getting user info from localstorage and sending it to enabler for login verification
			const userInfo = JSON.parse(localStorage.getItem('userInfo'));
			const enablerLoginInfo = {
				username: userInfo.name,
				usertype: 'Reap DS User',
				isLoggedIn: true,
				userId: userids[userInfo.name.replace(/\s/g, '')],
				email: userInfo.email,
			};

			const sendMessage = () => {
				const recipientWindow = document.getElementById('iframe').contentWindow;
				// Set the actual origin of the recipient window here
				setTimeout(() => {
					try {
						recipientWindow.postMessage({ token: enablerLoginInfo }, '*');
					} catch (error) {
						console.log(error);
					}
				}, 2000);
			};
			sendMessage();

			if (attemptNumber == 1) {
				sendMessage();
				setAttemptNumber(2);
			}

			setLoader(true);
			if (!isMobile || attemptNumber == 1) {
				setTimeout(() => {
					setLoader(false);
				}, 4000);
			}
		} else {
			window.location.href = '/login';
		}
	}, [url, showList]);

	// if (attemptNumber == 1) {
	// 	console.log('sg');
	// 	console.log(attemptNumber);
	// 	setAttemptNumber(2);
	// 	sendMessage();
	// }

	const handleToolClick = (tool) => {
		setEnablerUrl(tool.url);
		navigate('/tools');
		setShowList(false);
		setLoader(true);
		setTimeout(() => {
			setLoader(false);
		}, 4000);
	};

	// const goBack = () => {
	// 	setShowList(true);
	// 	setLoader(true);
	// };

	//  SET HEIGHT OF iFRAME DYNAMICALLY BY CHECKING THE NAME OF THE CALCULATOR
	//cHECK WHY PDF IS NOT DOWNLOADING
	return (
		<div className="iFrameContainer" style={{ textAlign: 'center' }}>
			{!error ? (
				isMobile ? (
					<div>
						<ul
							style={{
								width: '100%',
								display: showList ? 'block' : 'none',
								cursor: 'pointer',
								marginTop: '20px',
								marginBottom: '50px',
							}}
						>
							{toolsList?.map((tool, index) => (
								<div key={index} onClick={() => handleToolClick(tool)}>
									<li style={{ paddingLeft: '25px' }}>
										<div
											className={Style.toolsMaintxt}
											style={{ textAlign: 'left' }}
										>
											<p style={{ paddingBottom: '0px' }}>{tool.name}</p>
										</div>
										<div className="d-flex">
											<div className={Style.toolsSubtxt}>
												<p style={{ textAlign: 'left' }}>{tool.detail}</p>
											</div>
										</div>
									</li>
									{index === toolsList.length - 1 ? null : (
										<hr className={Style.hr} />
									)}
								</div>
							))}
						</ul>
						<div style={{ textAlign: 'center', paddingTop: '30px' }}>
							{/* <div style={{ textAlign: 'left', margin: '20px 10px' }}>
								<button className="primary_btn" onClick={goBack}>
									Back
								</button>
							</div> */}
							{loader && !showList && <div className="loader"></div>}

							<iframe
								scrolling="no"
								id="iframe"
								className="iFrameContainer"
								title="Tools From Enabler"
								src={url}
								sandbox="allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-downloads allow-forms"
								style={{
									width: '90%',
									height: `auto`,
									height: `1200px`,
									display: showList ? 'none' : 'block',
									marginLeft: '20px',
									visibility: loader ? 'hidden' : 'visible',
								}}
							/>
						</div>
					</div>
				) : (
					<div style={{ marginTop: '50px' }}>
						{loader && <div className="loader"></div>}

						<iframe
							scrolling="no"
							id="iframe"
							className="iFrameContainer"
							title="Tools From Enabler"
							src={url}
							sandbox="allow-scripts allow-same-origin allow-downloads"
							style={{
								width: '50%',
								height: `auto`,
								height: `1200px`,
								visibility: loader ? 'hidden' : 'visible',
							}}
						/>
					</div>
				)
			) : (
				<div
					style={{ textAlign: 'center', fontSize: '16px', margin: '80px auto' }}
				>
					{error}
				</div>
			)}
		</div>
	);
};

export default Tools;
