import { useState } from 'react';
import { useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode';

export default function AuthAdmin() {
	let navigate = useNavigate();
	const getToken = () => {
		const tokenString = localStorage.getItem('adminToken');
		const userToken = tokenString;
		return userToken;
	};

	const getUser = () => {
		const userString = localStorage.getItem('adminInfo');
		const user_detail = JSON.parse(userString);
		return user_detail;
	};
	const [token, setToken] = useState(getToken());
	const [user, setUser] = useState(getUser());

	const adminLogout = () => {
		localStorage.removeItem('adminInfo');
		localStorage.removeItem('adminToken');
		localStorage.removeItem('countryArray');
		localStorage.removeItem('tcList');
		localStorage.removeItem('languages');
		navigate('/cms');
	};

	const saveAdminToken = (token) => {
		localStorage.setItem('adminToken', JSON.stringify(token));
		let DECODE_TOKEN = jwtDecode(token);
		localStorage.setItem('adminInfo', JSON.stringify(DECODE_TOKEN));
		setToken(token);
		setUser(user);

		if (DECODE_TOKEN != undefined) {
			navigate('/cms/companies');
		} else {
			console.log('admin invalid');
		}
	};

	return {
		saveAdminToken,
		token,
		user,
		getUser,
		getToken,

		adminLogout,
	};
}
