import React, { useEffect, useState, useRef, useContext } from 'react';
import AdminDataService from '../../../../services/AdminDataServices';
import ResourceExcel from '../../../../assets/excel_files/resource.xlsx';
import Database from './database';
import { GlobalContext } from '../../../../context/GlobalState';

const Resource = () => {
	const columnsArray = ['Resource Name', 'Activation Status', 'Is Custom ?'];
	const adminApi = new AdminDataService();
	const [resources, setResources] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { currentLanguageId } = useContext(GlobalContext);

	const resourceRef = useRef({});

	useEffect(() => {
		document.title = 'Resource | REAP';

		async function getResources() {
			let language_id = 2;
			if (localStorage.getItem('language_id')) {
				language_id = localStorage.getItem('language_id');
			} else {
				language_id = currentLanguageId;
			}
			try {
				const result = await adminApi.getResourceData(language_id);
				setResources(result?.data?.resource);
				resourceRef.current = {
					...resourceRef,
					0: result?.data?.resource,
				};
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		}

		getResources();
	}, []);

	return (
		<Database
			themeArray={resources}
			setThemeArray={setResources}
			themeRef={resourceRef}
			columnsArray={columnsArray}
			type="resource"
			excelFile={ResourceExcel}
			name="resource_name"
			id="resource_id"
			placeholder="Search by Resource Name"
			api={adminApi.uploadResourceExcel}
			status="resource_status"
			isLoading={isLoading}
			setIsLoading={setIsLoading}
		/>
	);
};

export default Resource;
