import React, { useState, useEffect, useContext } from 'react';
import AdminDataService from '../../../../services/AdminDataServices';
import Modal from 'react-bootstrap/Modal';
import IARStyles from '../iar/iar.module.css';
import './editCompany.css';
import produce from 'immer';
import isEqual from 'lodash/isEqual';
import { GlobalContext } from '../../../../context/GlobalState';
import { Alert } from 'react-bootstrap';

const EditCompany = ({ id, index, setViewDetailsIndex }) => {
	const adminApi = new AdminDataService();
	const {
		deactivatedCompanies,
		setDeactivatedCompanies,
		setMessage,
		setCompanyEditMode,
		getCurrentCompany,
		activeCompanies,
		setActiveCompanies,
		setCurrentCompany,
		currentTab,
		countryArray,
		regionArray,
	} = useContext(GlobalContext);
	let companyType =
		currentTab == 'tab1'
			? 'active companies'
			: currentTab == 'tab2'
			? 'approved companies'
			: 'deactivated companies';
	// const [countryArray, setCountryArray] = useState([]);
	// const [regionArray, setRegionArray] = useState([]);
	const [company, setCompany] = useState([]);
	const [productsArray, setProductsArray] = useState([]);
	//state for view details or edit mode of each company
	const [mode, setMode] = useState('view');
	const [show, setShow] = useState(false);
	const [companyCode, setCompanyCode] = useState('');
	const [duplicate, setDuplicate] = useState(false);
	const [deactivationReason, setDeactivationReason] = useState('');
	const [error, setError] = useState('');
	const [countryCode, setCountryCode] = useState('+998');
	const [mobileNumber, setMobileNumber] = useState('');
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		async function getCompany() {
			try {
				const companyDetails = await adminApi.getCompanyById(id);
				setCompany(companyDetails?.data);
				//setting the country code

				countryArray.map((country) => {
					if (
						country.country_name.toString().toLowerCase() ==
						companyDetails?.data.country_name
					) {
						setCountryCode(country.country_code);
					}
				});
				setMobileNumber(
					companyDetails?.data.company_contact_person_phone?.split('-')[1]
				);
				setProductsArray(
					JSON.parse(companyDetails?.data?.company_product_json)
				);
			} catch (error) {
				console.log(error);
			}
		}
		getCompany();
	}, [id]);
	// useEffect(() => {
	// 	async function getTcData() {
	// 		try {
	// 			//getting region and country arrays
	// 			const countryAndRegionArray = await adminApi.getCountryAndRegionArray();
	// 			setCountryArray(
	// 				JSON.parse(countryAndRegionArray.data[0].COUNTRY_ARRAY)
	// 			);

	// 			setRegionArray(JSON.parse(countryAndRegionArray.data[0].REGION_ARRAY));
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	}
	// 	getTcData();
	// }, []);

	const changeDetails = ({ target: { value, name } }) => {
		const newCompany = { ...company, [name]: value };
		setCompany(newCompany);
	};

	// const changeMobileNumber = ({ target: { value, name } }) => {
	// 	console.log(name);
	// 	const newCompany = { ...company, [name]: countryCode + '-' + value };
	// 	setCompany(newCompany);
	// 	console.log(newCompany);
	// };

	const changeCountryAndRegion = ({ target: { name, value } }) => {
		let subname = '';
		const dataArray = value.split(' ');
		if (name == 'region_id') {
			subname = 'region_name';
		} else {
			subname = 'country_name';
			countryArray.map((country) => {
				if (country.country_id == Number(dataArray[0])) {
					setCountryCode(country.country_code);
				}
			});
		}

		const newCompany = {
			...company,
			[name]: Number(dataArray[0]),
			[subname]: dataArray[1],
		};
		setCompany(newCompany);
	};

	const alterProductName = (value, id) => {
		const newProductArray = productsArray.map((company) => {
			if (company.id == id) {
				company.name = value;
				return company;
			} else {
				return company;
			}
		});
		setProductsArray(newProductArray);
	};

	const removeProduct = ({ id }) => {
		const newProductArray = productsArray.filter(
			(product) => product.id !== id
		);
		setProductsArray(newProductArray);
	};

	const addProduct = () => {
		let lastProductId = productsArray[productsArray.length - 1].id;
		const newProductArray = [...productsArray];
		newProductArray.push({ id: lastProductId + 1, name: '' });
		setProductsArray(newProductArray);
	};

	const saveEditedDetails = async () => {
		const filteredProductArray = productsArray.filter(
			(company) => company.name != ''
		);
		const data = {
			company_id: id,
			company_address_line1: company?.company_address_line1,
			company_address_line2: company?.company_address_line2,
			company_sub_region: company?.company_sub_region,
			company_region: company?.region_id,
			company_country: company?.country_id,
			company_products: filteredProductArray,
			company_contact_person_name: company?.company_contact_person_name,
			company_contact_person_desg: company?.company_contact_person_desg,
			company_contact_person_phn: countryCode + '-' + mobileNumber,
			company_contact_person_email: company?.company_contact_person_email,
		};

		try {
			setCompany({
				...company,
				company_contact_person_phone: countryCode + '-' + mobileNumber,
			});
			const result = await adminApi.editCompany(data);
			setMessage('Changes saved successfully');
			setMode('view');
		} catch (error) {
			console.log(error);
		}
	};

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const deactivateCompany = async (id) => {
		if (deactivationReason.length < 3) {
			setError('You need to provide a valid reason');
		} else {
			try {
				await adminApi.deactivateCompany({
					company_id: id,
					deactivation_reason: deactivationReason,
				});
				setError('');
			} catch (error) {
				console.log(error);
			}

			const newDataArray = getCurrentCompany().filter(
				(company) => company.company_id !== id
			);
			const deactivatedCompany = getCurrentCompany().find(
				(company) => company.company_id === id
			);

			const currentDate = new Date();
			const day = String(currentDate.getDate()).padStart(2, '0');
			const month = String(currentDate.getMonth() + 1).padStart(2, '0');
			const year = currentDate.getFullYear();
			const hours = String(currentDate.getHours()).padStart(2, '0');
			const minutes = String(currentDate.getMinutes()).padStart(2, '0');
			const seconds = String(currentDate.getSeconds()).padStart(2, '0');
			const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

			const newDeactivatedCompanies = [
				{
					...deactivatedCompany,
					deactivation_date: formattedDate,
					deactivation_reason: deactivationReason,
				},
				...deactivatedCompanies,
			];
			setDeactivatedCompanies(newDeactivatedCompanies);
			setCurrentCompany(newDataArray);
			setShow(false);
			setMessage(
				`${deactivatedCompany?.company_name} deactivated successfully`
			);
			setViewDetailsIndex(null);
		}
	};

	const handleMobileNumberChange = (e) => {
		setMobileNumber(e.target.value);
		validateMobileNumber(e.target.value);
	};

	const validateMobileNumber = (number) => {
		// Regex patterns for Uzbekistan and Tajikistan mobile numbers
		let mobileNumber = countryCode + number;
		const uzbekistanPattern = /^\+?998\d{9}$/;
		const tajikistanPattern = /^\+?992\d{9}$/;
		if (mobileNumber === '') {
			setIsValid(true); // Empty input is considered valid
		} else if (
			mobileNumber.match(uzbekistanPattern) ||
			mobileNumber.match(tajikistanPattern)
		) {
			setIsValid(true);
		} else {
			setIsValid(false);
		}
	};
	const codeOnChnage = (e) => {
		let companyCode = e.target.value;
		setCompanyCode(companyCode);
	};
	const duplicateCompany = async (id, code) => {
		if (companyCode.length < 3) {
			setError('You need to provide a three character company code');
		} else {
			const newCompanyCode = code + companyCode;
			try {
				await adminApi.duplicateCompany({
					company_id: id,
					company_code: newCompanyCode,
				});
				//adding duplicated company to current company array
				const duplicatedCompany = getCurrentCompany().find(
					(company) => company.company_id === id
				);
				const updatedCompany = {
					...duplicatedCompany,
					company_code: newCompanyCode,
				};
				const newArray = [updatedCompany, ...activeCompanies];
				setActiveCompanies(newArray);

				setCompanyCode('');
				setError('');
				setMessage(
					`${duplicatedCompany?.company_name} duplicated successfully`
				);
				setViewDetailsIndex(null);
			} catch (error) {
				if (error.code == 'ERR_BAD_REQUEST') {
					setError('Company Code Already Exists');
				}
			}
		}
	};

	const handleEditClick = () => {
		setCompanyEditMode(index);
		setMode('edit');
	};

	const handleExitEditMode = () => {
		setCompanyEditMode(null);
		setMode('view');
	};

	//changing format of company updated date
	let companyUpdatedDate = '';
	if (company?.company_updated_date) {
		let formattedDate = company?.company_updated_date.split(' ')[0].split('-');
		companyUpdatedDate =
			formattedDate[2].substr(0, 2) +
			'-' +
			formattedDate[1] +
			'-' +
			formattedDate[0];
	}

	return (
		<div>
			{mode == 'view' ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-evenly',
						gap: '40px',
						padding: '10px 40px',
					}}
				>
					<div className="box">
						<b style={{ marginBottom: '15px' }}>Address</b>{' '}
						<div>{company?.company_address_line1}</div>
						<div>{company?.company_address_line2}</div>
						<div>{company?.company_sub_region}</div>
						<div>{company?.region_name}</div>
						<div>{company?.country_name}</div>
					</div>
					<div className="box">
						<b style={{ whiteSpace: 'nowrap' }}>
							Company's Main Product/Service{' '}
						</b>
						<br />

						{productsArray?.map((item, index) => {
							return <div key={index}>{item.name}</div>;
						})}
					</div>
					<div className="box">
						<div>
							<div>
								<b>Last Updated:</b>{' '}
							</div>
							{companyUpdatedDate}
						</div>
					</div>
					<div className="box">
						<b>Contact Person Details: </b>
						<br />
						<div style={{ textAlign: 'left' }}>
							{company?.company_contact_person_name}
						</div>
						<div>{company?.company_contact_person_desg}</div>
						<div>{countryCode + '-' + mobileNumber}</div>
						<div>{company?.company_contact_person_email}</div>
					</div>
					<div className="box" style={{ display: 'flex' }}>
						{companyType == 'active companies' && (
							<div style={{ display: 'flex' }}>
								<div>
									<button
										className={IARStyles.three_btns}
										onClick={handleEditClick}
									>
										Edit
									</button>
								</div>
								<div>
									<button className={IARStyles.three_btns} onClick={handleShow}>
										Deactivate
									</button>
								</div>
								<Modal
									className="deactivateCompany"
									show={show}
									onHide={handleClose}
									aria-labelledby="contained-modal-title-vcenter"
									centered
								>
									<Modal.Header closeButton></Modal.Header>
									<Modal.Body>
										<div className="row">
											<div className="col-12 text-center">
												<h4 style={{ fontWeight: '700' }}>
													Please mention the reason for deactivation:
												</h4>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: '7px',
													}}
												>
													<input
														type="text"
														style={{
															padding: '15px',
															width: '100%',
															border: '1px solid gray',
															borderRadius: '3px',
														}}
														placeholder="Type here"
														value={deactivationReason}
														onChange={(e) =>
															setDeactivationReason(e.target.value)
														}
													/>

													<button
														className="primary_btn small_btn"
														style={{
															width: '100px',
															minWidth: '100px',
														}}
														onClick={() => {
															deactivateCompany(company?.company_id);
														}}
													>
														Deactivate
													</button>
												</div>
												{error && (
													<Alert style={{ marginTop: '10px' }} variant="danger">
														{error}
													</Alert>
												)}
											</div>
										</div>
									</Modal.Body>
								</Modal>
							</div>
						)}
						{(companyType == 'approved companies' ||
							companyType == 'active companies') && (
							<div>
								<button
									className={IARStyles.three_btns}
									// onClick={() => {
									// 	duplicateCompany(company?.company_id);
									// }}
									onClick={() => setDuplicate(true)}
								>
									Duplicate
								</button>
								<Modal
									className="deactivateCompany"
									show={duplicate}
									onHide={() => setDuplicate(false)}
									aria-labelledby="contained-modal-title-vcenter"
									centered
								>
									<Modal.Header closeButton></Modal.Header>
									<Modal.Body>
										<div className="row">
											<div className="col-12 ">
												<h4 style={{ fontWeight: 'bold' }}>
													Enter Company Code
												</h4>
												<p style={{ fontWeight: '500' }}>
													Please Enter three digits in the blank spaces to
													create the company code
												</p>
												<div className="addcompanyCode">
													<div>{company?.company_code?.slice(0, 6)}</div>

													<div className="inputCode">
														<input
															className="inputCodeRef"
															type="text"
															maxLength="3"
															onChange={(e) => codeOnChnage(e)}
															value={companyCode}
														/>

														<div className="borderCode">
															<div></div>
															<div></div>
															<div></div>
														</div>
													</div>
												</div>
												{error && (
													<Alert
														style={{ marginTop: '10px', textAlign: 'center' }}
														variant="danger"
													>
														{error}
													</Alert>
												)}
												<div style={{ textAlign: 'center', marginTop: '15px' }}>
													<button
														className="primary_btn small_btn"
														style={{ padding: '10px' }}
														onClick={() => {
															duplicateCompany(
																company?.company_id,
																company?.company_code?.slice(0, 6)
															);
														}}
													>
														Duplicate
													</button>
												</div>
											</div>
										</div>
									</Modal.Body>
								</Modal>
							</div>
						)}
					</div>
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-evenly',
						gap: '40px',
						padding: '10px 40px',
					}}
				>
					{Object.keys(company).length > 0 && (
						<>
							<div className="box">
								<div style={{ marginBottom: '15px' }}>
									<b className="headingBold">Address</b>{' '}
								</div>
								<input
									className="input"
									value={company?.company_address_line1}
									name="company_address_line1"
									onChange={(e) => changeDetails(e)}
								/>
								<input
									className="input"
									value={company?.company_address_line2}
									name="company_address_line2"
									onChange={(e) => changeDetails(e)}
								/>
								<input
									className="input"
									value={company?.company_sub_region}
									name="company_sub_region"
									onChange={(e) => changeDetails(e)}
								/>

								<select
									className="input"
									name="region_id"
									style={{ width: '163px' }}
									defaultValue={company?.region_id + ' ' + company?.region_name}
									onChange={(e) => changeCountryAndRegion(e)}
								>
									{regionArray
										?.filter((region) =>
											company?.country_id === null
												? region.country_id == 1
												: region.country_id == company?.country_id
										)
										?.map((region, index) => {
											return (
												<option
													key={index}
													value={region?.region_id + ' ' + region?.region_name}
												>
													{region?.region_name}
												</option>
											);
										})}
								</select>

								<select
									className="input"
									name="country_id"
									style={{ width: '163px', textTransform: 'capitalize' }}
									defaultValue={
										company?.country_id + ' ' + company?.country_name
									}
									onChange={(e) => changeCountryAndRegion(e)}
								>
									{countryArray?.map((country, index) => {
										return (
											<option
												key={index}
												value={
													country?.country_id + ' ' + country?.country_name
												}
												style={{ textTransform: 'capitalize' }}
											>
												{country?.country_name}
											</option>
										);
									})}
								</select>
							</div>
							<div className="box">
								<b className="headingBold">Company's main Product/Service</b>
								{productsArray?.map((product, index) => {
									return (
										<div key={index} style={{ display: 'flex' }}>
											<input
												className="input"
												name="product"
												value={product.name}
												onChange={(e) =>
													alterProductName(e.target.value, product?.id)
												}
											/>

											{productsArray.length - 1 == index ? (
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: '7px',
													}}
												>
													<b
														style={{ fontSize: '18px', fontWeight: 'bolder' }}
														onClick={() => removeProduct(product)}
													>
														x
													</b>
													<b
														style={{ fontSize: '22px', fontWeight: 'bolder' }}
														onClick={() => addProduct()}
													>
														+
													</b>
												</div>
											) : (
												<b
													style={{ fontSize: '18px', fontWeight: 'bolder' }}
													onClick={() => removeProduct(product)}
												>
													x
												</b>
											)}
										</div>
									);
								})}
							</div>
							<div className="box">
								<b className="headingBold">Last updated</b>
								<div style={{ marginTop: '10px' }}>{companyUpdatedDate}</div>
							</div>
							<div className="box">
								<div style={{ marginBottom: '15px' }}>
									<b className="headingBold">Contact Person Details</b>
								</div>
								<label className="label">
									Name
									<input
										className="input"
										value={company?.company_contact_person_name}
										onChange={(e) => changeDetails(e)}
										name="company_contact_person_name"
										style={{ marginLeft: '7px' }}
									/>
								</label>
								<label className="label">
									Designation
									<input
										className="input"
										value={company?.company_contact_person_desg}
										onChange={(e) => changeDetails(e)}
										name="company_contact_person_desg"
										style={{ width: '128px', marginLeft: '7px' }}
									/>
								</label>

								<label className="label">
									<b style={{ marginRight: '8px' }}>Phone</b>

									<span
										style={{
											pointerEvents: 'none',
											color: 'gray',
											fontWeight: '400',
											fontSize: '14px',
										}}
									>
										{countryCode}
									</span>
									<input
										className="input"
										value={mobileNumber}
										name="company_contact_person_phone"
										onChange={handleMobileNumberChange}
										style={{ width: '130px' }}
									/>
								</label>
								{!isValid && (
									<span style={{ color: 'red', fontSize: '14px' }}>
										Please enter a valid mobile number for{' '}
										{countryCode == 998 ? ' Uzbekistan' : ' Tajikistan'}{' '}
									</span>
								)}

								<label className="label">
									Email
									<input
										className="input"
										value={company?.company_contact_person_email}
										onChange={(e) => changeDetails(e)}
										name="company_contact_person_email"
										style={{ marginLeft: '7px', width: '167px' }}
									/>
								</label>
							</div>
							<div
								className="box"
								style={{
									flexShrink: '2',
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
								}}
							>
								<b style={{ color: '#1E989A' }}>In Edit Mode</b>
								<button
									className="primary_btn small_btn"
									style={{ width: '100px', margin: '50px auto 10px' }}
									onClick={saveEditedDetails}
								>
									Save changes
								</button>
								<span
									style={{ textDecoration: 'underline', cursor: 'pointer' }}
									onClick={handleExitEditMode}
								>
									Exit Edit mode
								</span>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export const MemoizedEditCompany = React.memo(
	EditCompany,
	(prevProps, nextProps) => {
		const previousPropsCopy = produce(prevProps, (draft) => {});
		// Compare the previousPropsCopy with the next props
		const isEqualObjects = isEqual(previousPropsCopy, nextProps);

		if (isEqualObjects) {
			return true;
		}
		return false;
	}
);
