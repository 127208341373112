import React, { useState, useEffect } from 'react';
import './style.css';

const TermsAndCondtions = () => {
	const isMobile = window.innerWidth < 768;

	const [currentLanguage, setCurrentLanguage] = useState(2);
	useEffect(() => {
		if (localStorage.getItem('lanId')) {
			setCurrentLanguage(localStorage.getItem('lanId'));
		}
	}, []);

	return (
		<div className="deskWrapper">
			<div
				style={{ paddingLeft: !isMobile ? '80px' : '20px', marginTop: '20px' }}
			>
				<h2 style={{ color: '#3B7E80', fontSize: '24px', fontWeight: 'bold' }}>
					{currentLanguage == 2
						? 'Terms of Use'
						: 'Политика конфиденциальности'}
				</h2>
				<div style={{ padding: !isMobile ? '15px 15px 15px 70px' : '10px' }}>
					{currentLanguage == 2 ? (
						<>
							<section className="section">
								<h4 className="heading">1. Acceptance</h4>
								<p className="paragraph">
									These terms and conditions ("terms of use") and the related
									privacy policy (“privacy policy”) shall govern the use by you
									of this web based REAP Digital Solution (REAP DS).
									<br />
									<b>
										If you do not agree to these terms and conditions and the
										related privacy policy, you must not use the mobile app.
									</b>
								</p>
							</section>
							<div></div>
							<section className="section">
								<h4 className="heading">2. Organizers</h4>
								<p className="paragraph">
									REAP DS is produced under the REAP project funded by the
									European Union SWITCH-Asia Grants Programme. It is designed
									and maintained by STENUM Asia Sustainable Development Society
									on behalf of The Central Asia Regional Economic Cooperation
									(CAREC). For any concerns or grievances related to this mobile
									app you can write to: &nbsp;
									<a href="mailto:info@reap-centralasia.org?subject=Query about Reap DS">
										info@reap-centralasia.org.
									</a>
								</p>
							</section>
							<section className="section">
								<h4 className="heading">3. REAP Digital Solution</h4>
								<p className="paragraph">
									<b>REAP DS </b>is designed for use by the technical
									consultants (TC) hired under REAP project in Uzbekistan and
									Tajikistan. It has been created to help automate some parts of
									the workflow which relates to the preparation of the initial
									assessment report (IAR). Specifically, REAP DS helps a TC
									prepare a draft IAR faster and more efficiently as compared to
									the manual process.
								</p>
								<p className="paragraph">
									<b>REAP DS </b> may include links to sites on the internet
									that are owned or operated by third parties. You access the
									third-party sites at your own risk. STENUM Asia does not
									assume any responsibility for material created or published by
									such third-party sites. By providing a link to a site the
									Organizers do not imply that they endorse the site, or the
									products or services referenced in such site.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">4. Access</h4>
								<p className="paragraph">
									Access to REAP DS is given to only the relevant REAP Project
									team members and it is not open to general public. Access is
									managed by a designated admin person from the REAP project
									team. <br /> Once you are granted access to REAP DS, you agree
									to take reasonable measures and all due care to protect your
									log in credentials against misuse by third parties. You agree
									to indemnify the Organizers against any loss or damage caused
									because of misuse of REAP DS by a third party.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">5. Fees</h4>
								<p className="paragraph">
									Use of REAP DS is free of charge. The Organizers may from time
									to time launch and include services which may be subject to a
									charge and same or additional terms.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">6. Intellectual Property Rights</h4>
								<p className="paragraph">
									All trade names, trademarks and service marks appearing on the
									REAP DS digital solution and the included content are
									protected by REAP project or respective owners. Any rights not
									expressly granted herein are reserved. All content of the REAP
									DS web based solution is for your personal use only. Your
									access to REAP DS web based solution should not be construed
									as granting, by implication, estoppel or otherwise, any
									license or right to use any marks (for example logos)
									appearing on the site without the prior written consent of
									REAP project or the owner thereof. You agree not to remove any
									copyright or other proprietary notices if you download or
									print content for your personal and use. You also agree not to
									circumvent or disable any security or other technical features
									that prevent or restrict the use or copying of the content
									where applicable.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">7. Submissions of User Content</h4>
								<p className="paragraph">
									You are solely responsible for any submission of input data
									and need to ensure that it does not contain a virus, “Trojan
									horse”, “sniffer routines”, “backdoors”, “robots”, “spiders”,
									“worms”, “time bombs”, “bots”, or any other harmful software
									code, file, program or programming routine, or other
									contaminating or destructive features
								</p>
							</section>
							<section className="section">
								<h4 className="heading">
									8. General Disclaimer of Warranties and Liabilities
								</h4>
								<p className="paragraph">
									This REAP DS web based solution is provided by REAP project on
									an “as is” and “as available” basis. The organizers do not
									warrant that the web based solution will be uninterrupted or
									error or virus-free. REAP project reserves the right to revise
									the REAP DS solution or withdraw access to it at any time
									without notice to you. REAP project expressly disclaim any
									express or implied warranties including, without limitation,
									warranties of title, non-infringement, merchantability,
									fitness for a particular purpose, availability, accuracy,
									reliability or content of the REAP DS web based solution
									including any export file.
								</p>
								<p className="paragraph">
									You expressly agree and acknowledge that the use of the REAP
									DS is at your sole risk. REAP project assumes no liability or
									responsibility for any content or information provided by
									other users of the REAP DS. Therefore, any use of the REAP DS
									automatically and without any prejudice indemnifies the
									Organizers, their affiliates, officers, directors, employees
									and agents against any liabilities or claims arising from use
									of this web based solution and the content within. Any
									reliance you place on such information is therefore strictly
									at your own risk. The organizers will not be liable for any
									loss or damage including without limitation, indirect or
									consequential loss or damage, or any loss or damage whatsoever
									arising from loss of data or profits arising out of, or in
									connection with, the use of this web based solution.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">9. Term</h4>
								<p className="paragraph">
									These Terms and Conditions and related Privacy Policy are
									effective from 01 st June 2023 and are valid until further
									notice. REAP project team has the right to change these Terms
									and Conditions and related Privacy Policy from time to time in
									their sole discretion. Such changes shall become effective
									upon posting an update in this section of the REAP DS solution
									and you agree to be bound to any and all of the changes when
									you use the REAP DS. REAP project team reserves the right, in
									their sole discretion and with immediate effect, and without
									any liability, to terminate your registration and your access
									to all or any part of the REAP DS without prior notice. You
									may also, at any time, terminate your registration to the REAP
									DS by writing to the admin. Please note that if you choose to
									terminate your registration, you will no longer have access to
									the REAP DS. In the event of termination, the provisions of
									these Terms and Conditions and Privacy Policy that are
									intended to survive such termination shall remain unaffected.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">10. Assignment</h4>
								<p className="paragraph">
									The REAP project team shall have the right to assign these
									Terms and Conditions and related Privacy Policy or its rights
									and obligations hereunder to a third party without your prior
									written consent but may not be assigned by you without REAP
									project team’s prior written consent.
								</p>
							</section>
						</>
					) : (
						<>
							<section className="section">
								<h4 className="heading">1. Согласие</h4>
								<p className="paragraph">
									Настоящие положения и условия («условия использования») и
									соответствующая политика конфиденциальности («политика
									конфиденциальности») регулируют использование вами данного
									веб-Цифрового Решения REAP (REAP ЦР).
								</p>
							</section>
							<h4 className="heading">
								Если вы не согласны с этими условиями и соответствующей
								политикой конфиденциальности, вы не должны использовать
								мобильное приложение
							</h4>
							<section className="section">
								<h4 className="heading">2. Organizers</h4>
								<p className="paragraph">
									REAP ЦР производится в рамках проекта REAP, финансируемого
									Программой Европейского Союза SWITCH-Asia. Оно разработано и
									поддерживается Обществом Устойчивого Развития STENUM Asia от
									имени Регионального Экологического Центра Центральной Азии
									(РЭЦЦА). По любым вопросам или жалобам, связанным с этим
									мобильным приложением, вы можете написать по адресу:
									<a>info@reap-centralasia.org</a>
								</p>
							</section>
							<section className="section">
								<h3>3. Цифровое решение REAP</h3>
								<p className="paragraph">
									<b>REAP ЦР </b>предназначен для использования техническими
									консультантами (ТК), нанятыми в рамках проекта REAP в
									Узбекистане и Таджикистане.
									<b>
										{' '}
										. Он был создан, чтобы помочь автоматизировать некоторые
										части рабочего процесса, связанного с подготовкой отчета о
										первоначальной оценке (ОПО). В частности, REAP ЦР помогает
										TC быстрее и эффективнее подготовить черновой вариант ОПО по
										сравнению с ручным процессом
									</b>
								</p>
								<p className="paragraph">
									<b>REAP ЦР </b> может содержать ссылки на сайты в Интернете,
									которые принадлежат третьим лицам или управляются ими. Вы
									получаете доступ к сторонним сайтам на свой страх и риск.
									STENUM Asia не несет никакой ответственности за материалы,
									созданные или опубликованные такими сторонними сайтами.
									Предоставляя ссылку на сайт, Организаторы не подразумевают,
									что они одобряют сайт или продукты, или услуги, упомянутые на
									таком сайте.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">4. Доступ</h4>
								<p className="paragraph">
									Доступ к REAP ЦР предоставляется только соответствующим членам
									команды проекта REAP и закрыт для широкой публики. Доступ
									управляется назначенным администратором из команды проекта
									REAP.
								</p>
								<p className="paragraph">
									<b>
										Получив доступ к REAP ЦР, вы соглашаетесь принять разумные
										меры и приложить все усилия для защиты ваших учетных данных
										для входа в систему от неправомерного использования третьими
										лицами. Вы соглашаетесь возместить Организаторам любые
										убытки или ущерб, вызванные неправильным использованием REAP
										ЦР третьей стороной.
									</b>
								</p>
							</section>
							<section className="section">
								<h4 className="heading">5. Взнос и оплата</h4>
								<p className="paragraph">
									Использование REAP ЦР бесплатно. Организаторы могут время от
									времени запускать и включать услуги, за которые может
									взиматься плата и на тех же или дополнительных условиях.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">
									6. Права на интеллектуальную собственность
								</h4>
								<p className="paragraph">
									Все торговые наименования, товарные знаки и знаки
									обслуживания, встречающиеся в цифровом решении REAP ЦР, а
									также включенный в него контент, защищены проектом REAP или
									соответствующими владельцами. Любые права, прямо не
									предоставленные здесь, защищены. Весь контент веб- решения
									REAP ЦР предназначен только для вашего личного использования.
									Ваш доступ к веб- решению REAP ЦР не должен толковаться как
									предоставление, косвенно, лишение права возражения или иным
									образом, какой-либо лицензии или права на использование любых
									знаков (например, логотипов), появляющихся на сайте, без
									предварительного письменного согласия проекта REAP или
									владельца. Вы соглашаетесь не удалять какие-либо уведомления
									об авторских правах или других правах собственности, если вы
									загружаете или распечатываете контент для личного
									использования. Вы также соглашаетесь не обходить и не
									отключать какие-либо функции безопасности или другие
									технические функции, которые предотвращают или ограничивают
									использование, или копирование контента, где это применимо.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">
									7. Отправка пользовательского контента
								</h4>
								<p className="paragraph">
									Вы несете единоличную ответственность за любую отправку
									входных данных и должны убедиться, что они не содержат
									вирусов, «троянских коней», «снифферных программ», «черных
									ходов», «роботов», «пауков», «черви», «бомбы замедленного
									действия», «боты» или любой другой вредоносный программный
									код, файл, программа или процедура программирования, или
									другие загрязняющие или разрушительные функции.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">
									8. Общий отказ от гарантий и ответственности
								</h4>
								<p className="paragraph">
									Это веб-решение REAP ЦР предоставляется проектом REAP на
									условиях «как есть» и «по мере доступности». Организаторы не
									гарантируют бесперебойную работу веб-решения, отсутствие
									ошибок или вирусов. Проект REAP оставляет за собой право
									пересмотреть решение REAP ЦР или отозвать доступ к нему в
									любое время без предварительного уведомления. Проект REAP
									прямо отказывается от каких-либо явных или подразумеваемых
									гарантий, включая, помимо прочего, гарантии правового титула,
									не нарушения прав, товарной пригодности, пригодности для
									определенной цели, доступности, точности, надежности или
									содержимого веб-решения REAP ЦР, включая любой экспортный
									файл.
								</p>
								<p className="paragraph">
									Вы прямо соглашаетесь и признаете, что используете REAP ЦР на
									свой страх и риск. Проект REAP не несет никакой
									ответственности за любой контент или информацию,
									предоставленную другими пользователями REAP ЦР. Следовательно,
									любое использование REAP ЦР автоматически и без какого-либо
									ущерба освобождает Организаторов, их аффилированных лиц,
									должностных лиц, директоров, сотрудников и агентов от любых
									обязательств или претензий, возникающих в связи с
									использованием этого веб-решения и содержащегося в нем
									контента. Таким образом, вы можете полагаться на такую
									информацию исключительно на свой страх и риск. Организаторы не
									несут ответственности за какие-либо убытки или ущерб, включая,
									помимо прочего, косвенные убытки или ущерб, а также любые
									убытки или ущерб, возникающие в результате потери данных или
									польза в результате или в связи с использованием этого
									веб-сайта решение.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">9. Условия и положения</h4>
								<p className="paragraph">
									Настоящие Условия и соответствующая Политика
									конфиденциальности вступают в силу с 01 июня 2023 года и
									действуют до дальнейшего уведомления. Команда проекта REAP
									имеет право время от времени изменять настоящие Условия и
									соответствующую Политику конфиденциальности по своему
									собственному усмотрению. Такие изменения вступают в силу после
									публикации обновления в этом разделе решения REAP ЦР, и вы
									соглашаетесь соблюдать любые и все изменения при использовании
									REAP ЦР. Команда проекта REAP оставляет за собой право по
									своему собственному усмотрению и с немедленным вступлением в
									силу и без какой-либо ответственности прекратить вашу
									регистрацию и ваш доступ ко всей или любой части REAP ЦР без
									предварительного уведомления. Вы также можете в любое время
									прекратить свою регистрацию в REAP ЦР, написав администратору.
									Обратите внимание, что если вы решите прекратить регистрацию,
									у вас больше не будет доступа к REAP ЦРЫ. В случае расторжения
									положения настоящих Условий и Политики конфиденциальности,
									которые должны остаться в силе после такого расторжения,
									остаются незатронутым.
								</p>
							</section>
							<section className="section">
								<h4 className="heading">10. Assignment</h4>
								<p className="paragraph">
									Команда проекта REAP имеет право передать настоящие Условия и
									соответствующую Политику конфиденциальности или свои права и
									обязанности по настоящему документу третьей стороне без вашего
									предварительного письменного согласия, но не может быть
									передана вами без предварительного письменного согласия
									команды проекта REAP.
								</p>
							</section>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TermsAndCondtions;
