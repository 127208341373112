// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadSign_upload__jZ\\+Ig {
    padding: 35px 20px;
}

.uploadSign_upload__jZ\\+Ig h1 {
    font-weight: 700;
    font-size: 24px;
    color: #3B7E80;
}

.uploadSign_upload__jZ\\+Ig p {
    color: #6D6D6D;
    font-size: 12px;
}

.uploadSign_signature__ayaPf {

    background-color: #FFFFFF;
    color: #1E989A;
    border: #1E989A 2px solid;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 10px;
    font-weight: 500;
    line-height: 1.5;
    margin: 2px;
    position: relative;
    display: inline-block;
    text-align: center;
    min-width:300px;
    font-family: 'Robotomedium';

}

input[type="file"] {
    display: none;
}

.uploadSign_upload__jZ\\+Ig img {
    width: 14px;
    margin-right: 10px;
}
@media screen and (max-width: 450px) {
    .uploadSign_signature__ayaPf {
       min-width: 80px;
       padding: 8px 50px;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/uploadSignature/uploadSign.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;;IAEI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,2BAA2B;;AAE/B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;AACA;IACI;OACG,eAAe;OACf,iBAAiB;IACpB;EACF","sourcesContent":[".upload {\r\n    padding: 35px 20px;\r\n}\r\n\r\n.upload h1 {\r\n    font-weight: 700;\r\n    font-size: 24px;\r\n    color: #3B7E80;\r\n}\r\n\r\n.upload p {\r\n    color: #6D6D6D;\r\n    font-size: 12px;\r\n}\r\n\r\n.signature {\r\n\r\n    background-color: #FFFFFF;\r\n    color: #1E989A;\r\n    border: #1E989A 2px solid;\r\n    padding: 8px 15px;\r\n    font-size: 14px;\r\n    border-radius: 10px;\r\n    font-weight: 500;\r\n    line-height: 1.5;\r\n    margin: 2px;\r\n    position: relative;\r\n    display: inline-block;\r\n    text-align: center;\r\n    min-width:300px;\r\n    font-family: 'Robotomedium';\r\n\r\n}\r\n\r\ninput[type=\"file\"] {\r\n    display: none;\r\n}\r\n\r\n.upload img {\r\n    width: 14px;\r\n    margin-right: 10px;\r\n}\r\n@media screen and (max-width: 450px) {\r\n    .signature {\r\n       min-width: 80px;\r\n       padding: 8px 50px;\r\n    }\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": `uploadSign_upload__jZ+Ig`,
	"signature": `uploadSign_signature__ayaPf`
};
export default ___CSS_LOADER_EXPORT___;
