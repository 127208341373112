import React, { useState, useEffect } from "react";
import remove from '../../assets/images/remove.png';
// import IARStyles from './iar.module.css';
import DataService from '../../services/DataServices';
import { useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import AuthUser from "../authUser/authuser";
import { FaPlus } from 'react-icons/fa';
import Loader from '../../assets/images/loadin_gif.gif';
import { useTranslation } from 'react-i18next';
import { ErrorLog } from "../erroLog/errorLog";

const ExecutiveSummary = () => {
    const { logout } = AuthUser();
    const { t } = useTranslation();
    const [LoaderSave,setLoaderSave] = useState(false);
    let currentLocation = useLocation();
    let pathName = currentLocation.pathname
    let comp_url = pathName.substring(pathName.lastIndexOf('/') + 1);
    const api = new DataService(); // assign api to constant
    const [saveSuccess, setSavesuccess] = useState(false);
    const [Success, setSuccess] = useState(false);
    const [Errorpopup, setErrorpopup] = useState(false);
    const [executiveSummery, setExecutiveSummery] = useState();
    const [ResponseMessage,setResponseMessage] = useState('');
    const [loaderImg,setloaderImg] = useState(false);
    const [ErrorMessage,setErrorMessage] = useState('');
    const handleClose = () => {
        setSavesuccess(false);
        setSuccess(false);
        setResponseMessage(false);
        setErrorpopup(false);
        setLoaderSave(false);
    }
    const getExecutivesummery = async () => {
        setloaderImg(true)
        let getBucketdata = localStorage.getItem("exeSummaryBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filteredBucket = BucketJson && BucketJson.filter((company) => company.company_id == comp_url);
        if (filteredBucket == null || filteredBucket.length == 0) {
            let data = {
                company_id: comp_url
            };
            try{
                await api.getExecutiveSummary(data).then((response) => {
                    let responsedata = response.data;
                    let executiveSummaryList = responsedata.executive_summary_list.length> 0 ? JSON.parse(responsedata.executive_summary_list):[]
                    let exeData = {
                        "company_code": response.data.company_code,
                        "company_iav_date": response.data.company_iav_date,
                        "company_id": response.data.company_id,
                        "company_name": response.data.company_name,
                        "currency": response.data.currency,
                        "executive_summary_list": executiveSummaryList
                    }
                    setExecutiveSummery(exeData)
                    let ExeBucketArray = [];
                        ExeBucketArray.push(exeData)
                        localStorage.setItem("exeSummaryBucket", JSON.stringify(ExeBucketArray))
                    if (filteredBucket != null) {
                        let ExeBucketArray = [...BucketJson];
                        ExeBucketArray.push(exeData)
                        localStorage.setItem("exeSummaryBucket", JSON.stringify(ExeBucketArray))
                        setExecutiveSummery(exeData)
                    }
                    else {
                        let ExeBucketArray = [];
                        ExeBucketArray.push(exeData)
                        localStorage.setItem("exeSummaryBucket", JSON.stringify(ExeBucketArray))
                        setExecutiveSummery(exeData)
                    }
                })
            }
           catch(error){
             if(error.code !="ERR_NETWORK"){
                let errorCode = error.response.status;
                let errMsg = error.response.data.error.message;
                if(errorCode == 401){
                    logout();
                }
                if(errorCode == 400 || errorCode == 500){
                    setloaderImg(false)
                    setErrorMessage(error.response.data.error.message);
                    let errorLogData ={
                        api_name:"getExecutiveSummary",
                        error_code:errorCode,
                        error_message:errMsg
                    } 
                    ErrorLog(errorLogData);
                }
            }
            }
        }
        else {
            let filteredBucketTwo = BucketJson && BucketJson.filter((company) => company.company_id == comp_url);
            setTimeout(()=>{
                setExecutiveSummery(filteredBucketTwo[0])
                setloaderImg(false);
            },2000)   
        }

    }
    //Add new row of executive summary
    const AddNewExecutiveRow = () => {
        let data = {
            "executive_summary_id": 0,
            "executive_summary_resource_name": '',
            "executive_summary_quantity": '',
            "executive_summary_monetary": '',
            "executive_summary_estimated_investment": '',
            "executive_summary_created_by": '',
            "executive_summary_unit_cost":'',
            "is_delete": 0
        }
        if (executiveSummery ==undefined || executiveSummery.executive_summary_list == null || executiveSummery.executive_summary_list == undefined) {
            let Currentdata = {
                "company_code": executiveSummery.company_code,
                "company_iav_date": executiveSummery.company_iav_date,
                "company_id": executiveSummery.company_id,
                "company_name": executiveSummery.company_name,
                "currency": executiveSummery.currency,
                "executive_summary_list": executiveSummery.executive_summary_list
            }
            let getBucketdata = localStorage.getItem("exeSummaryBucket");
            let BucketJson = JSON.parse(getBucketdata);
            let filterData = BucketJson.filter((item) => item.company_id != comp_url)
            filterData.push(Currentdata);
            localStorage.setItem("exeSummaryBucket", JSON.stringify(filterData))
            setExecutiveSummery(Currentdata)
        }
        else {
            let exeList = [...executiveSummery.executive_summary_list, data];
            let Currentdata = {
                "company_code": executiveSummery.company_code,
                "company_iav_date": executiveSummery.company_iav_date,
                "company_id": executiveSummery.company_id,
                "company_name": executiveSummery.company_name,
                "currency": executiveSummery.currency,
                "executive_summary_list": exeList
            }
            let getBucketdata = localStorage.getItem("exeSummaryBucket");
            let BucketJson = JSON.parse(getBucketdata);
            let filterData = BucketJson.filter((item) => item.company_id != comp_url)
            filterData.push(Currentdata);
            localStorage.setItem("exeSummaryBucket", JSON.stringify(filterData))
            setExecutiveSummery(Currentdata)
        }


        //setExecutiveSummery([...executiveSummery,executiveSummery.executive_summary_list,data])
    }
    //Edit row of executive summary
    const exehandleChange = (e, index) => {
        const { name, value } = e.target;
        const inputData = [...executiveSummery.executive_summary_list]
        inputData[index][name] = value;
        let data = {
            "company_code": executiveSummery.company_code,
            "company_iav_date": executiveSummery.company_iav_date,
            "company_id": executiveSummery.company_id,
            "company_name": executiveSummery.company_name,
            "currency": executiveSummery.currency,
            "executive_summary_list": inputData
        }
        let getBucketdata = localStorage.getItem("exeSummaryBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        // console.log("BucketJson-------------------->",BucketJson);
        filterData.push(data);
        localStorage.setItem("exeSummaryBucket", JSON.stringify(filterData))
        setExecutiveSummery(data)
    }
    //Save all executive summary data
    const saveEntireExecutivesummary = () => {
        setSavesuccess(true)
        //   let last = executiveSummery.slice(-1);
        //   if(last.executive_summary_resource_name == ''){ 
        //   }
    }
    const validateAll = (executiveSummeryData) =>{
        let exeCurrentData = executiveSummeryData.executive_summary_list;
        let error = {};
        exeCurrentData.forEach((element) => {
            if(element.is_delete == 0){
                if(element.executive_summary_resource_name == ''){
                    error.executive_summary_resource_name = "executive_summary_resource_name is empty."
                }
                if(element.executive_summary_quantity == ''){
                    error.executive_summary_quantity = "executive_summary_quantity  is empty."
                   
                }
                if(element.executive_summary_monetary == ''){
                    error.executive_summary_monetary = "executive_summary_monetary  is empty."
                   
                }
                if(element.executive_summary_unit_cost == ''){
                    error.executive_summary_unit_cost = "executive_summary_unit_cost  is empty."
                   
                }
                if(element.executive_summary_estimated_investment == ''){
                    error.executive_summary_estimated_investment = "executive_summary_estimated_investment is empty."
                    
                }
            }
           
            
        });
        return error;
    }
    const SaveExecutiveSummary = async (status) => {
        let errResp = validateAll(executiveSummery)
        if (status == "yes") {
            if(Object.keys(errResp).length === 0){
                setLoaderSave(true)
                let formData = {
                    "company_code": executiveSummery.company_code,
                    "company_iav_date": executiveSummery.company_iav_date,
                    "company_id": executiveSummery.company_id,
                    "company_name": executiveSummery.company_name,
                    "currency": executiveSummery.currency,
                    "executive_summary_list": executiveSummery.executive_summary_list
                };
                await api.addExecutiveSummary(formData).then((response) => {
                    let responseDB = response;
                    if (responseDB.status == 200 || responseDB.status == 201) {
                        setSavesuccess(false);
                        setSuccess(true);
                        setResponseMessage(response.data.message);
                        let getBucketdata = localStorage.getItem("exeSummaryBucket");
                        let BucketJson = JSON.parse(getBucketdata);
                        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
                        localStorage.setItem("exeSummaryBucket", JSON.stringify(filterData))
                        getExecutivesummery();
                        setTimeout(()=>{
                            setLoaderSave(false);
                        },1000)
                    }
                }).catch((error) => {
                    if(error.code !="ERR_NETWORK"){
                        let errorCode = error.response.status;
                        let errMsg = error.response.data.error.message;
                        if(errorCode == 401){
                            logout();
                        }
                        if (errorCode == 400 || errorCode == 500) {
                            let errorLogData ={
                                api_name:"addexecutivesummary",
                                error_code:errorCode,
                                error_message:errMsg
                            } 
                            ErrorLog(errorLogData);
                        }
                    }
                    else{
                        setSavesuccess(false);
                        setErrorpopup(true);
                        setResponseMessage(error.message+". Please check your internet connection.")
                    }
                })
            }
            else{
                setSavesuccess(false)
                setErrorpopup(true)
            }
          
        }
        else {
            setSavesuccess(false)
        }
    }
    const DeleteSpecificRow = (indexId) => {
        let AllData = [...executiveSummery.executive_summary_list];
        AllData[indexId].is_delete = 1;
        let data = {
            "company_code": executiveSummery.company_code,
            "company_iav_date": executiveSummery.company_iav_date,
            "company_id": executiveSummery.company_id,
            "company_name": executiveSummery.company_name,
            "currency": executiveSummery.currency,
            "executive_summary_list": AllData
        }
        let getBucketdata = localStorage.getItem("exeSummaryBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)

        // console.log("BucketJson-------------------->",BucketJson);
        filterData.push(data);
        localStorage.setItem("exeSummaryBucket", JSON.stringify(filterData))
        setExecutiveSummery(data)
    }
    useEffect(() => {
        getExecutivesummery();
    }, [])
    return (
        <div className='executiveSummary'>
            {executiveSummery?<div className="container scrollCustom" style={{height:"calc(100vh - 378px)",overflowY:"auto"}}>
                <div className="row">
                    <div className="col-md-12">
                        <p>{t("exeSummaryPartOne")} <b>{executiveSummery?executiveSummery.company_name:"(name not available)"}</b> on <b>{executiveSummery?new Date(executiveSummery.company_iav_date).toLocaleDateString('en-us', { day: "numeric", month: "short", year: "numeric" }):"(date not available"}</b>. {t("exeSummaryPartTwo")}</p>
                        <p>{t("exeSummaryPartThree")}</p>
                        <p>{t("exeSummaryPartFour")}</p>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-md-10 exsummryTable offset-md-1 column">

                        <table className="executiveTable table">
                            <thead className="tableexe">
                                <tr className="tableexe">
                                    <th className="text-center tableexesum" rowSpan="2">{t("SNo")}</th>
                                    <th className="text-center" rowSpan="2">{t("Resources")}</th>
                                    <th className="text-center" rowSpan="2">{t("UnitCost")}</th>
                                    <th className="text-center" colSpan="2">{t("PotentialSavings")}</th>
                                    <th className="text-center" rowSpan="2">{t("EstimatedInvestment")} {executiveSummery?"("+executiveSummery.currency+")":null}</th>
                                    <th rowSpan="2"></th>
                                </tr>
                                <tr>
                                    <th className="text-center" >{t("Quantity")}</th>
                                    <th className="text-center" >{t("Monetary")}{executiveSummery?"("+executiveSummery.currency+")":null}</th>
                                </tr>
                            </thead>
                            <tbody className="tableexesmry" >
                                {executiveSummery && executiveSummery.executive_summary_list != null && executiveSummery.executive_summary_list.length!=0 ? executiveSummery.executive_summary_list.map((item, index) =>
                                    item.is_delete === 0 ? <tr key={index}>
                                        <td className="text-center" >
                                            {index + 1}
                                        </td>
                                        <td className="text-center">
                                            <input
                                                type="text"
                                                value={item.executive_summary_resource_name!=null?item.executive_summary_resource_name:''}
                                                name="executive_summary_resource_name"
                                                className="form-control text-center"
                                                onChange={(e) => exehandleChange(e, index)}
                                                autoComplete="off"
                                            />
                                        </td>
                                        <td className="text-center">
                                            <input
                                                type="text"
                                                value={item.executive_summary_unit_cost!=null?item.executive_summary_unit_cost:''}
                                                name="executive_summary_unit_cost"
                                                className="form-control text-center"
                                                onChange={(e) => exehandleChange(e, index)}
                                                autoComplete="off"
                                            />
                                        </td>
                                        <td className="text-center">

                                            <input
                                                type="text"
                                                value={item.executive_summary_quantity!=null?item.executive_summary_quantity:''}
                                                name="executive_summary_quantity"
                                                className="form-control text-center"
                                                onChange={(e) => exehandleChange(e, index)}
                                                autoComplete="off"
                                            />
                                        </td>
                                        <td className="text-center">

                                            <input
                                                type="text"
                                                value={item.executive_summary_monetary!=null?item.executive_summary_monetary:''}
                                                name="executive_summary_monetary"
                                                className="form-control text-center"
                                                onChange={(e) => exehandleChange(e, index)}
                                                autoComplete="off"
                                            />
                                        </td>
                                        <td className="text-center">
                                            <input
                                                type="text"
                                                value={item.executive_summary_estimated_investment!=null?item.executive_summary_estimated_investment:''}
                                                name="executive_summary_estimated_investment"
                                                className="form-control text-center"
                                                onChange={(e) => exehandleChange(e, index)}
                                                autoComplete="off"
                                            />
                                        </td>
                                        <td className="text-center">
                                            <button className="delete_btn" onClick={() => DeleteSpecificRow(index, item.executive_summary_id)}> <img src={remove} alt="Remove" width={'20px'} /></button>
                                        </td>
                                    </tr> : null
                                ) : <tr style={{textAlign:"center"}}><th>{t("NoExecutiveSummary")}</th></tr>}

                            </tbody>
                        </table>
                        <div className="text-center mb-2">
                            <button 
                               className="frontendBtn"
                               onClick={AddNewExecutiveRow}
                               ><FaPlus style={{marginRight:"3px"}}/>{t("Addnewrow")}</button>

                            {/* <button onClick={() => { handleAddRow(); addLocalStorage() }} className="secondary_btn small_btn">Add new row</button> */}
                        </div>
                    </div>
                </div>
            </div>:loaderImg == true ? <img src={Loader} alt="loader" className="loaderIAR"/>:ErrorMessage}
            {executiveSummery && executiveSummery.executive_summary_list != null && executiveSummery.executive_summary_list.length!=0?<button 
               onClick={saveEntireExecutivesummary} 
               className="frontendBtn" 
               style={{float:"right",
                        margin:"42px -9px -15px",
                        padding:"8px 32px"
                      }}
            >
                  {t("Save")}
            </button>:null}
            <Modal className="newObservation removeConform" show={saveSuccess} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="P-3 pt-0 text-center">
                        <h4 className="mb-4">{t("DoyouWantsaveConform")}</h4>
                        <button className="secondary_btn small_btn" onClick={() => SaveExecutiveSummary('no')}>{t("No")}</button>
                        <button className="secondary_btn small_btn" onClick={() => SaveExecutiveSummary('yes')}>{t("Yes")}</button>
                        {LoaderSave===true?<div><img src={Loader} alt="loader" width="130"/></div>:null}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="newObservation removeConform" show={Success} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="P-3 pt-0 text-center">
                        {LoaderSave===true?<img src={Loader} alt="loader" width="130"/>:<h4 className="mb-4">{ResponseMessage}</h4>}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="newObservation removeConform" show={Errorpopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="P-3 pt-0 text-center">
                        <h4 className="mb-4">{ResponseMessage.length>0?ResponseMessage:t("validationErrorcompany")}</h4>
                    </div>
                </Modal.Body>
            </Modal>
           
        </div >
    );
}
export default ExecutiveSummary;