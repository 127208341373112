import React, { useState, useEffect } from 'react';
import checkpointStyles from './checkpoints.module.css';
import { Link } from 'react-router-dom';
import GalleryIcon from '../../assets/images/gallery.png';
import NoteIcon from '../../assets/images/notes.png';
import { useLocation } from 'react-router-dom';
import DataService from '../../services/DataServices';
import Modal from 'react-bootstrap/Modal';
import LoadingGIF from '../../assets/images/loadin_gif.gif';
import TcBreadCrumb from '../tc_breadcrumb/tc_breadcrumb';
import Gallery from '../iar/tc_gallery/gallery';
import AuthUser from '../authUser/authuser';
import { useTranslation } from 'react-i18next'
import Nodata from '../../assets/images/noData.png';
import { ErrorLog } from '../erroLog/errorLog';
import MyDocument from '../myDocument/mydocument';
import { PDFDownloadLink} from '@react-pdf/renderer'
const Checkpoints = () => {
    const { logout } = AuthUser();
    const { t } = useTranslation();
    const api = new DataService();
    let currentLocation = useLocation();
    let pathName = currentLocation.pathname;
    let currentUrl = pathName.split("/");
    let com_id = pathName.substring(pathName.lastIndexOf('/') + 1);
    const [iavisitPopupError, setIAvisitPopupError] = useState(false);
    const [iavisitConformation, setIAvisitConformation] = useState(false);
    const [iarAsseAleret, setIarAsseAleret] = useState(false);
    const [comapnyDetails, setCompanyDetails] = useState([]);
    const [title, setTitle] = useState(currentUrl[1]);
    const [conformCheck, setConformCheck] = useState(false);
    const [IAconformMessage, setIAconformMessage] = useState('');
    const [statusErrorMessagePopup, setErrorMessagePopup] = useState(false);
    const [LoaderImg, setLoaderImg] = useState(false);
    const [PdfLoader, setPdfLoader] = useState(false);

    const getCompanyDetailsFunc = async () => {
        setLoaderImg(true)
        let data = {
            "company_id": com_id
        }
        await api.getcompanybyid(data).then((response) => {
            if (response.status == 200 || response.status == 201) {
                let com_data = response.data;
                setCompanyDetails(com_data.company[0])
                setTimeout(() => {
                    setLoaderImg(false)
                }, 2000)
            }
        }).catch((error) => {
            if (error.code !== "ERR_NETWORK") {
                let errorCode = error.response.status;
                let message = error.response.data.error.message;
                if (errorCode == 401) {
                    logout();
                }
                if (errorCode == 400 || errorCode == 500) {
                    setErrorMessagePopup(true)
                    setIarAsseAleret(message)
                    setTimeout(() => {
                        setLoaderImg(false);
                    }, 2000)
                    let errorLogData = {
                        api_name: "getcompanybyid",
                        error_code: errorCode,
                        error_message: message
                    }
                    ErrorLog(errorLogData);
                }
            }
        })
    }
    const finishIAvisit = () => {
        setIAvisitPopupError(true)
    }
    const handleClose = () => {
        setIAvisitPopupError(false)
        setIAvisitConformation(false)
        setIarAsseAleret(false)
        setConformCheck(false)
        setErrorMessagePopup(false)
    }
    const iarDoneConformationPopup = () => {
        setIAvisitConformation(true)
        setConformCheck(true)
    }
    const iarDoneConformation = async (status) => {
        if (status == 'yes') {
            let data = {
                "company_id": com_id,
                "company_checkpoints_status": 1
            }
            await api.addCheckPoint(data).then((res) => {
                let response = res.data;
                if (res.status == 200 || res.status == 201) {
                    getCompanyDetailsFunc();
                    setIAvisitConformation(false);
                    setIAconformMessage(response.message)
                    setErrorMessagePopup(true)
                }
            }).catch((error) => {
                if (error.code !== "ERR_NETWORK") {
                    let errorCode = error.response.status;
                    let message = error.response.data.error.message;
                    if (errorCode == 401) {
                        logout();
                    }
                    if (errorCode == 400 || errorCode == 500) {
                        setIAconformMessage(message)
                        setErrorMessagePopup(true)
                        setIAvisitConformation(false)
                        let errorLogData = {
                            api_name: "companycheckpointstatus",
                            error_code: errorCode,
                            error_message: message
                        }
                        ErrorLog(errorLogData);
                    }
                }
            })
        }
        else {
            setConformCheck(false)
            setIAvisitConformation(false)
        }
    }
    const IarAssesmentAlert = () => {
        setIarAsseAleret(true)
    }
    // PDF Part start
    const [iarVersion, setIarVersion] = useState("")
    const [pdfDaTa, setPdfData] = useState()
    const [tcname, setTcname] = useState()
    const [approvalDate, setApprovalDate] = useState();
    const [approvedby, setApprovedBy] = useState();
    const [executiveData, setexecutiveData] = useState();
    const [company, setCompany] = useState([]);
    const [scpImrovment, setScpImprovement] = useState()
    const [userSignature, setUserSignature] = useState()
    const [gallery, setGallery] = useState()
    const [pdfDataProcessflow, setPdfDataProcessflow] = useState()
    const [iarName, setIARName] = useState("")
    const [pdfLoader, setPDFLoader] = useState(false);
    const [pdfActive, setPDFActive] = useState(true);

    const GetPdfData = async (status) => {
        setPdfLoader(true)
        let data = {
            company_id: com_id,
            isPreview: status === 1 ? 1 : 0
        }
        await api.pdfdata(data).then((response) => {
            let responsedata = response.data
            setexecutiveData(JSON.parse(responsedata.pdfData[0].EXECUTIVE_SUMMARY))
            setCompany(JSON.parse(responsedata.pdfData[0].COMPANY_ARRAY))
            const comapnyname = JSON.parse(responsedata.pdfData[0].COMPANY_ARRAY)
            setIARName(comapnyname[0].iar_version)
            localStorage.setItem("IARVersion", comapnyname[0].iar_version)
            setPdfDataProcessflow(JSON.parse(responsedata.pdfData[0].PROCESS_FLOW_ARRAY))
            const processFLow = JSON.parse(response?.data?.pdfData[0]?.PROCESS_FLOW_ARRAY);
            let resourceArray = [];
            processFLow.map((process, i) => {
                resourceArray.push(JSON.parse(process?.resource_json));
            });

            setPdfData(responsedata.base64Signature)
            setTcname(JSON.parse(responsedata.pdfData[0].USER_SIGNATURE));
            setApprovalDate(JSON.parse(responsedata.pdfData[0].COMPANY_ARRAY));
            setApprovedBy(JSON.parse(responsedata.pdfData[0].COMPANY_ARRAY));
            setScpImprovement(JSON.parse(responsedata.pdfData[0].SCP_IMPROVEMENT_ARRAY))
            setUserSignature(responsedata.base64Signature)
            setGallery(responsedata.linkedImg)
            // setTimeout(() => {
            //     generatePDF();
            // }, 1000)
            if (status == 0) {
                downloadPDFFunction();
             }

        }).catch((error) => {
            if (error.code !== "ERR_NETWORK") {
                let errorCode = error.response.status;
                let message = error.response.data.error.message;
                if (errorCode == 401) {
                    logout();
                }
                if (errorCode == 400) {
                    setPDFActive(false)
                    setIAconformMessage(message)
                    setErrorMessagePopup(true)
                    let errorLogData = {
                        api_name: "pdfdata",
                        error_code: errorCode,
                        error_message: message
                    }
                    ErrorLog(errorLogData);
                }
            }
        })
    }
    const downloadPDF = (status) => {
        GetPdfData(status);
    }
    const downloadPDFFunction = () => {
        setTimeout(() => {
            const link = document.querySelector('.DownloadLink');
            if(link.href.length>0&&link.href!==''){
                link.click();
                setPdfLoader(false)
            }
        }, 5000)
    }
    // PDF Part end
    let totalHeight = window.innerHeight;
    let currentBottom = totalHeight - 150;
    useEffect(() => {
        getCompanyDetailsFunc();
    }, [])
    return (

        <div className='deskWrapper'>
            {
                pdfLoader ? (<div className='loaderPDF' ><img src={LoadingGIF} alt="loaderImg" />

                </div>) : (<div className="container">
                    {window.innerWidth > 768 ? <TcBreadCrumb title={t("Checkpoints")} /> : null}
                    <div>
                        {window.innerWidth < 768 ? <Link to={"/CompanyDetails/" + `${com_id}`} ><p className="companydetail_txt">{t("CompanyfullDetail")}</p></Link> : ""}
                    </div>
                    {comapnyDetails && comapnyDetails.company_id > 0 ? <div className="row">
                        <div className="col-md-6 pr-2 mb-1">
                            {window.innerWidth > 768 ? <ul className={checkpointStyles.checkpointList + " " + "DesktopIARVisit"} >
                                {/* <li><Link to="/DateofIAvisit" >Initial Assessment Visit</Link></li> */}
                                <li className={comapnyDetails.company_checkpoints_status == 0 ? checkpointStyles.selected : checkpointStyles.selectedNobg} onClick={comapnyDetails.company_checkpoints_status == 0 ? finishIAvisit : null}>
                                    <label className={comapnyDetails.company_checkpoints_status == 0 ? "IRvisitcontainer" :
                                        comapnyDetails.company_checkpoints_status == 1 ? "IRvisitTickcontainer" :
                                            comapnyDetails.company_checkpoints_status == 2 ? "IRvisitTickcontainer" : null}>
                                        <input type="checkbox" checked="checked" readOnly />
                                        <span className={comapnyDetails.company_checkpoints_status == 0 ? "IRvisitcheckMarkRound" :
                                            comapnyDetails.company_checkpoints_status == 1 ? "IRvisitTickcheckmark" :
                                                comapnyDetails.company_checkpoints_status == 2 ? "IRvisitTickcheckmark" :
                                                    null}
                                        >
                                        </span>
                                    </label>
                                    <Link>
                                        {comapnyDetails.company_checkpoints_status == 0 || comapnyDetails.company_checkpoints_status == 1 || comapnyDetails.company_checkpoints_status == 2 ?
                                            <span>{t("InitialAssessmentVisit")}</span> : null
                                        }
                                    </Link>
                                    <hr />
                                </li>
                                <li
                                    className={comapnyDetails.company_checkpoints_status == 0 ?
                                        checkpointStyles.disabledStatus :
                                        comapnyDetails.company_checkpoints_status == 2 ? checkpointStyles.selectedNobg : checkpointStyles.selected}
                                >
                                    <Link
                                        className={checkpointStyles.textFullClick}
                                        to={comapnyDetails.company_checkpoints_status == 1 ? "/checkpoints/IARPreparation/" + `${com_id}` : ''}
                                    >{comapnyDetails.company_checkpoints_status == 0 ? <span className={checkpointStyles.textPositionIAr}>{t("IARPreparation")}</span> : null}
                                        {comapnyDetails.company_checkpoints_status == 1 ? <span className={checkpointStyles.textPosition}>{t("IARPreparation")}</span> : null}
                                        {comapnyDetails.company_checkpoints_status == 2 ? <span className={checkpointStyles.textPosition}>{t("IARPreparation")}</span> : null}
                                        <label className={comapnyDetails.company_checkpoints_status == 0 ? "IRvisitcontainer" :
                                            comapnyDetails.company_checkpoints_status == 1 ? "IRvisitTickcontainer" :
                                                comapnyDetails.company_checkpoints_status == 2 ? "IRvisitTickcontainer" : null}>
                                            <input type="checkbox" checked="checked" readOnly />
                                            <span className={comapnyDetails.company_checkpoints_status == 0 ?
                                                "IRvisitcheckDeactive" : comapnyDetails.company_checkpoints_status == 1 ?
                                                    "IRvisitcheckMarkRound" : comapnyDetails.company_checkpoints_status == 2 ?
                                                        "IRvisitTickcheckmark" : null
                                            }></span>
                                        </label>
                                    </Link>
                                    {/* {comapnyDetails.company_checkpoints_status == 0 ?
                                    <Link>IAR Preparation</Link> :
                                    comapnyDetails.company_checkpoints_status == 1 ?
                                        <Link to={"/checkpoints/IARPreparation/" + `${com_id}`}>IAR Preparation</Link> :
                                        comapnyDetails.company_checkpoints_status == 2 ? <Link>IAR Preparation</Link> : null
                                } */}
                                </li>
                            </ul> :
                                <ul className={checkpointStyles.checkpointList + " " + "mobileIARVisit"} >
                                    {/* <li><Link to="/DateofIAvisit" >Initial Assessment Visit</Link></li> */}
                                    <li className={comapnyDetails.company_checkpoints_status == 0 ? checkpointStyles.selected : checkpointStyles.selectedNobg}>
                                        <Link
                                            to={comapnyDetails.company_checkpoints_status === 0 && comapnyDetails.company_iav_date == null ? "/checkpoints/DateofIAvisit/" + `${com_id}` :
                                                comapnyDetails.company_checkpoints_status === 1 || comapnyDetails.company_checkpoints_status === 2 ? "" : "/checkpoints/IARPreparation/" + `${com_id}`}
                                            className={checkpointStyles.textFullClick}
                                        ><span className={checkpointStyles.textPosition}>{t("InitialAssessmentVisit")}</span>
                                            <label className={comapnyDetails.company_checkpoints_status == 0 ? "IRvisitcontainer" : "IRvisitTickcontainer"
                                            }>
                                                <input type="checkbox" checked="checked" readOnly />
                                                <span className={comapnyDetails.company_checkpoints_status == 0 ?
                                                    "IRvisitcheckMarkRound" : "IRvisitTickcheckmark"
                                                }></span>
                                            </label>
                                        </Link>
                                        <hr className={comapnyDetails.company_checkpoints_status != 0 ? 'hrSmall' : 'hrBig'} />
                                    </li>
                                    {window.innerWidth < 768 && comapnyDetails.company_checkpoints_status != 1 && comapnyDetails.company_checkpoints_status != 2 ? <li onClick={iarDoneConformationPopup}>
                                        <label className="iarConformcheckcontainer">{t("MarkIAVisitascompleted")}
                                            <input type="checkbox" checked={conformCheck} readOnly />
                                            <span className="iarConformcheckcheckmark"></span>
                                        </label>
                                    </li> : null}
                                    <li
                                        className={comapnyDetails.company_checkpoints_status == 1 ?
                                            checkpointStyles.selected : comapnyDetails.company_checkpoints_status == 2 ? checkpointStyles.selectedNobg :
                                                comapnyDetails.company_checkpoints_status == 0 ? checkpointStyles.selectedNobgDeactive : null}
                                        onClick={comapnyDetails.company_checkpoints_status == 1 ? IarAssesmentAlert : null}
                                    >
                                        <label className={comapnyDetails.company_checkpoints_status == 1 ? "IRvisitcontainer" :
                                            comapnyDetails.company_checkpoints_status == 2 ? "IRvisitTickcontainer" :
                                                comapnyDetails.company_checkpoints_status == 0 ? "IRvisitcontainer" : null}>
                                            <input type="checkbox" checked="checked" readOnly />
                                            <span className={comapnyDetails.company_checkpoints_status == 1 ? "IRvisitcheckMarkRound" :
                                                comapnyDetails.company_checkpoints_status == 2 ? "IRvisitTickcheckmark" :
                                                    comapnyDetails.company_checkpoints_status == 0 ? "IRvisitcheckDeactive" : null
                                            }></span>
                                        </label>
                                        {comapnyDetails.company_checkpoints_status == 1 ?
                                            <Link>{t("IARPreparation")}</Link> : comapnyDetails.company_checkpoints_status == 2 ?
                                                <Link>{t("IARPreparation")}</Link> : comapnyDetails.company_checkpoints_status == 0 ? <Link>{t("IARPreparation")}</Link> : null}
                                    </li>
                                </ul>}
                            <div>{IAconformMessage}</div>
                        </div>
                        <Modal className="newObservation removeConform" show={iavisitPopupError} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <div className={checkpointStyles.warningMsg}>
                                        <h5>{t("checkPointInitialAssesmentPopupLine1")}</h5>
                                        <p>{t("checkPointInitialAssesmentPopupLine2")}</p>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={iavisitConformation} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <div className={checkpointStyles.warningMsg}>
                                        <h5 style={{ color: "#327072" }}>{t("checkpointIAvisitCompletionCheckBox1")}</h5>
                                        <p>{t("checkpointIAvisitCompletionCheckBox2")}</p>
                                    </div>
                                    <button className="secondary_btn small_btn" onClick={() => iarDoneConformation("no")}>{t("Notyet")}</button>
                                    <button className="secondary_btn small_btn" onClick={() => iarDoneConformation("yes")}>{t("Yes")}</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={statusErrorMessagePopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <div className={checkpointStyles.warningMsg}>
                                        <h5 style={{ color: "#327072" }}>{t("Alert")}</h5>
                                        <p>{IAconformMessage && IAconformMessage}</p>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform alertMessagepopup" show={iarAsseAleret} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <div className={checkpointStyles.warningMsg + " " + "warningAlert"}>
                                        <h5 style={{ color: "#327072" }}>{t("Alert")}</h5>
                                        <p>{t("checkpointLapDeskconform")}</p>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        {comapnyDetails.company_checkpoints_status == 2 ? <div className="col-md-12 mb-1">
                            <div style={{ color: "#1E989A", fontWeight: "500", fontSize: "13px", marginBottom: "16px", fontFamily: 'Robotomedium' }}>{comapnyDetails.company_code + "-IAR_approved"}</div>
                            <button onClick={()=>downloadPDF(0)} className="secondary_btn">{t("DownloadApprovedIAR")}</button>
                        </div> : null}
                        {PdfLoader == true ?<div><img src={LoadingGIF} alt="loader" style={{width:"150px"}}/></div>:null}
                        {comapnyDetails.company_checkpoints_status !== 2 ? <div className="col-md-12 mb-4 mt-4 mobileButtonAbsolute" style={{ top: currentBottom + "px" }}>
                            <Link to={"/checkpoints/notes/" + com_id} className="secondary_btn"><img src={NoteIcon} alt="Notes" style={{ width: "18px" }} />{t("Notes")}</Link>
                            <Link to={"/checkpoints/addgallery/" + com_id} className="secondary_btn"> <img src={GalleryIcon} alt="Gallery" style={{ width: "18px" }} />{t("Gallery")}</Link>
                        </div> : <div className="col-md-12 mb-4 mt-4 mobileButtonAbsolute" style={{ top: currentBottom + "px" }}>
                            <Link className="secondary_btn utilButton disableBtn" ><img src={NoteIcon} alt="notes" style={{ width: "18px" }} />{t("Notes")}</Link>
                            <Link className="secondary_btn utilButton disableBtn"> <img src={GalleryIcon} alt="Gallery" style={{ width: "18px" }} />{t("Gallery")}</Link>
                        </div>}
                    </div> : <div className='px-3'>{LoaderImg == true ? <img src={LoadingGIF} style={{ width: "140px", marginTop: "10px" }} alt="loading" /> : <div className='nodataFound'><img src={Nodata} alt="data not found" style={{ width: "60px" }} /><span> Company Not Found</span></div>}</div>}
                </div>)}
             <button onClick={GetPdfData} style={{display:"none"}}>Fetch Data</button>
            {company.length > 0 && (
                <PDFDownloadLink
                    document={<MyDocument 
                          company={company} 
                          executiveData={executiveData}
                          pdfDataProcessflow={pdfDataProcessflow}
                          scpImrovment={scpImrovment}
                          gallery={gallery}
                          tcname={tcname} userSignature={userSignature}
                          />}
                    fileName={company && company[0].iar_version}
                    className='DownloadLink'
                >
                    {({ loading, error }) =>
                        loading ? '' : ''
                    }
                </PDFDownloadLink>
            )}
        </div>

    );
}
export default Checkpoints;