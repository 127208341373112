import React, {
	useState,
	createContext,
	useRef,
	useReducer,
	useEffect,
} from 'react';
import AdminDataService from '../services/AdminDataServices';
import AppReducer from './AppReducer';

const adminApi = new AdminDataService();

const initialState = {
	message: '',
	companyEditIndex: null,
	currentObservationId: null,
	tcId: '',
	tcName: '',
	steId: '',
	steName: '',
	networkError: false,
	currentLanguageId: 2,
	currentTab: 'tab1',
};

//create Context
export const GlobalContext = createContext(initialState);

//Provider Component
export const GlobalProvider = ({ children }) => {
	const tcListRef = useRef({});

	const [state, dispatch] = useReducer(AppReducer, initialState);
	//ref to store searched elements in array in sequential order
	const companiesRef = useRef({});

	const [countryArray, setCountryArray] = useState([]);
	const [regionArray, setRegionArray] = useState([]);
	//company types
	const [deactivatedCompanies, setDeactivatedCompanies] = useState([]);
	const [activeCompanies, setActiveCompanies] = useState([]);
	const [iarApprovedComapnies, setIarApprovedCompanies] = useState([]);
	//current tab
	//storing the tc list globally
	const [tcList, setTcList] = useState([]);
	const [observationApiError, setObservationApiError] = useState(false);

	//getting data from localstorage if already exists
	useEffect(() => {
		if (localStorage.getItem('countryArray')) {
			setCountryArray(JSON.parse(localStorage.getItem('countryArray')));
		}
		if (localStorage.getItem('regionArray')) {
			setRegionArray(JSON.parse(localStorage.getItem('regionArray')));
		}
		if (localStorage.getItem('tclist')) {
			setTcList(JSON.parse(localStorage.getItem('tclist')));
		}
		if (localStorage.getItem('language_id')) {
			changeCurrentLanguage(localStorage.getItem('language_id'));
		}

		initialState.netWorkError = false;
	}, []);

	//method to get current compnay
	const getCurrentCompany = () => {
		return state.currentTab == 'tab1'
			? activeCompanies
			: state.currentTab == 'tab2'
			? iarApprovedComapnies
			: deactivatedCompanies;
	};
	//method to set current company after changes
	const setCurrentCompany = (updatedCompany) => {
		if (updatedCompany == null) {
			updatedCompany = [];
		} else {
			state.currentTab == 'tab1'
				? setActiveCompanies(updatedCompany)
				: state.currentTab == 'tab2'
				? setIarApprovedCompanies(updatedCompany)
				: setDeactivatedCompanies(updatedCompany);
		}
	};

	//Actions

	const setCurrentTab = (tabname) => {
		dispatch({
			type: 'CHANGE TABS',
			payload: tabname,
		});
	};

	const setMessage = (message) => {
		dispatch({
			type: 'SET_MESSAGE',
			payload: message,
		});
	};

	const setCompanyEditMode = (index) => {
		dispatch({
			type: 'SET_COMPANY_EDIT_MODE',
			payload: index,
		});
	};

	const setCurrentObservatinId = (id) => {
		dispatch({
			type: 'SET_CURRENT_OBSERVATION_ID',
			payload: id,
		});
	};
	const setTcId = (id) => {
		dispatch({
			type: 'SET_TC_ID',
			payload: id,
		});
	};
	const setTcName = (name) => {
		dispatch({
			type: 'SET_TC_NAME',
			payload: name,
		});
	};
	const setSteId = (id) => {
		dispatch({
			type: 'SET_STE_ID',
			payload: id,
		});
	};

	const setSteName = (name) => {
		dispatch({
			type: 'SET_STE_NAME',
			payload: name,
		});
	};

	const setGlobalNetworkError = (booleanValue) => {
		dispatch({
			type: 'SET_NETWORK_ERROR',
			payload: booleanValue,
		});
	};

	const changeCurrentLanguage = (language_id) => {
		dispatch({
			type: 'CHANGE_LANGUAGE',
			payload: language_id,
		});
	};

	// const setTcData = (tcData) => {
	// 	dispatch({
	// 		type: 'SET_TC_Data',
	// 		payload: booleanValue,
	// 	});
	// }
	//get country and region list
	const getCountryAndRegionList = (token) => {
		async function getData(token) {
			let countryAndRegionArray = '';
			try {
				countryAndRegionArray = await adminApi.getCountryAndRegionArray(token);
				setCountryArray(
					JSON.parse(countryAndRegionArray.data[0].COUNTRY_ARRAY)
				);
				setRegionArray(JSON.parse(countryAndRegionArray.data[0].REGION_ARRAY));
				localStorage.setItem(
					'countryArray',
					countryAndRegionArray.data[0].COUNTRY_ARRAY
				);
				localStorage.setItem(
					'regionArray',
					countryAndRegionArray.data[0].REGION_ARRAY
				);
			} catch (error) {
				console.log(error);
			}
		}
		getData(token);
	};

	function getTcList(token) {
		async function getTcLists(token) {
			try {
				const tclists = await adminApi.getTcList(token);
				setTcList(tclists.data.tcList);
				localStorage.setItem('tclist', JSON.stringify(tclists.data.tcList));
				let length = 0;
				tcListRef.current = {
					...tcListRef.current,
					[length]: tclists?.data?.tcList,
				};
			} catch (error) {
				console.log(error);
			}
		}
		getTcLists(token);
	}

	const getLanguage = (token) => {
		async function getLanguages(token) {
			try {
				const languages = await adminApi.getLanguage(token);
				localStorage.setItem(
					'languages',
					JSON.stringify(languages.data.language)
				);
			} catch (error) {
				console.log(error);
			}
		}
		getLanguages();
	};

	function getCommonApiData(token) {
		getTcList(token);
		getCountryAndRegionList(token);
		getLanguage(token);
	}

	return (
		<GlobalContext.Provider
			value={{
				message: state.message,
				setTcId,
				setSteId,
				setTcName,
				setSteName,
				tcId: state.tcId,
				steId: state.steId,
				steName: state.steName,
				tcName: state.tcName,
				setCurrentObservatinId,
				currentObservationId: state.currentObservationId,
				countryArray,
				regionArray,
				setMessage,
				getCountryAndRegionList,
				getTcList,
				getCommonApiData,
				activeCompanies,
				setActiveCompanies,
				iarApprovedComapnies,
				setIarApprovedCompanies,
				deactivatedCompanies,
				setDeactivatedCompanies,
				companyEditIndex: state.companyEditIndex,
				setCompanyEditMode,
				currentTab: state.currentTab,
				setCurrentTab,
				getCurrentCompany,
				setCurrentCompany,
				tcList,
				setTcList,
				tcListRef,
				getLanguage,
				observationApiError,
				setObservationApiError,
				networkError: state.networkError,
				setGlobalNetworkError,
				currentLanguageId: state.currentLanguageId,
				changeCurrentLanguage,
				companiesRef,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};
