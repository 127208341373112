import Style from "./Gallery.module.css"
import { Page, Text, View, Image, Font, StyleSheet } from "@react-pdf/renderer";
import Header from '../../../assets/pdf_images/Header.png';
import Footer from '../../../assets/pdf_images/Footer.png'
import Robot from '../../../assets/fonts/Roboto-Regular.ttf';
import RobotSemiBold from '../../../assets/fonts/Roboto-Medium.ttf';
import ItalicText from '../../../assets/fonts/ArialCEItalic.ttf';
Font.register({
  family: "RobotFont",
  fonts: [
    {
      src: Robot,
      fontWeight: 100,
    },
    {
      src: RobotSemiBold,
      fontWeight: 600,
    },
    {
      src: ItalicText,
      fontStyle: "italic"
    }
  ]
});
const GalleryPDF = ({ gallery }) => {
  const ColorLightBlue = {
    color: "#1E989A"
  }
  const pageStyle = {
    paddingTop: 5,
    paddingBottom: 75,
    paddingHorizontal: 40,

  };
  const tableRowStyle = {
    flexDirection: "row"
  };

  const firstTableColStyle = {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#000",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1
  };

  const tableCellStyle = {
    textAlign: "center",
    margin: 5,
    fontSize: 10
  };
  const FooterStyle = {
    position: 'absolute',
    fontSize: 12,
    bottom: "10px",
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  };
  const pageNumberstyle = {
    position: 'absolute',
    fontSize: 12,
    bottom: "0px",
    left: 0,
    right: 0,
    textAlign: 'center',
    backgroundColor: "#1e989a",
    height: "16px",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
  }
  const perChunk = 2
  //const [gallery, setGallery] = useState([])
  const result = gallery && gallery.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])
  let TableContentTwo = '';
  TableContentTwo = <View>{result && result.map((item, index) => <View key={index} style={tableRowStyle}>
    {item.map((subItem, idx) => <View key={idx} style={{ ...firstTableColStyle, padding: "10px" }}>
      <View style={tableCellStyle}><Image src={`data:application/pdf;base64,${subItem.linkedImg}`} style={{ width: "100%", height: "150px", objectFit: "contain" }} /></View>
      <View style={{ flexDirection: "row" }}>
        <View><Text style={{ color: ColorLightBlue.color, fontSize: "10px", fontFamily: "RobotFont", fontStyle: "italic" }}>{subItem.gallery_name}</Text>
        </View>
        <View><Text style={{ color: ColorLightBlue.color, fontSize: "9px", fontFamily: "RobotFont", fontStyle: "italic" }}>: {subItem.gallery_caption}</Text>
        </View>
      </View>
    </View>)}
  </View>)}
  </View>
  return (
    <Page
      size="A4"
      orientation="portrait"
      style={pageStyle}
    >
      <View fixed><Image src={Header} /></View>
      <View
        style={{
          color: ColorLightBlue.color,
          flexDirection: 'row',
          paddingTop: "10px",
          paddingBottom: "10px"
        }}>
        <Text style={{ fontFamily: 'Roboto', fontSize: "12px" }}>{"4. "} </Text>
        <Text style={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "12px" }}>On-site Photographs</Text>
      </View>
      <View>{TableContentTwo}</View>
      <View style={FooterStyle} fixed><Image src={Footer} /></View>
      <View style={pageNumberstyle} fixed><Text style={{ color: "#fff", fontSize: 9, width: "100%", textAlign: "center" }}
        render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )}
      /></View>
    </Page>
  )
}
export default GalleryPDF
