import React, { useRef, useState } from "react";
import IARStyles from './iar.module.css';
import remove from '../../assets/images/trash.png';
import plus from '../../assets/images/plus.svg';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useMasterdata } from "../customHooks/masterDatahook";
import DataService from "../../services/DataServices";
import { useEffect } from "react";
import { useLanguageChange } from '../customHooks/lan_hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import AuthUser from "../authUser/authuser";
import { RiArrowDownFill } from 'react-icons/ri';
import Loader from '../../assets/images/loadin_gif.gif';
import { ErrorLog } from "../erroLog/errorLog";

const ProcessFlowDiagram = () => {
    const ResourceRef = useRef([]);
    const WasteRef = useRef([]);
    const { logout } = AuthUser();
    let currentLocation = useLocation();
    let pathName = currentLocation.pathname
    let comp_url = pathName.substring(pathName.lastIndexOf('/') + 1);
    let currentComp_Id = pathName.split('/')[2];
    let navigate = useNavigate();
    const api = new DataService();
    let { lanId } = useLanguageChange();
    const { t } = useTranslation()
    const { resources, process, wasteHazard } = useMasterdata();
    const [show, setShow] = useState(false);
    const [processShow, SetProcessShow] = useState(false);
    const [insertProcesssuccess, setInsertprocessSuccess] = useState(false)
    const [processFiledvalid, setProcessFiledvalid] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [successDone, setSuccessDone] = useState(false);
    const [LoaderSave, setLoaderSave] = useState(false);
    const [InternetError, setInternetError] = useState("");
    const [Errorpopup, setErrorpopup] = useState(false);
    const handleClose = () => {
        setShow(false);
        setLoaderSave(false)
        SetProcessShow(false)
        setInsertprocessSuccess(false)
        setProcessFiledvalid(false)
        setSuccessDone(false);
        setErrorMessage();
        setInternetError();
    }
    const [resourceOpen, setResourceopen] = useState();//resource open/close state
    const [processOpen, setProcessOpen] = useState();//process open/close state

    const [wasteHazardOpen, setWasteHazardopen] = useState();//waste open/close state

    const [resourceValue, setStateResource] = useState({
        resource_id: 0,
        resource_name: ""
    })

    const [wasteHazardCollection, setWastehazardCollection] = useState({
        waste_id: 0,
        waste_name: ""
    })

    const [processFlowData, setProcessFlowData] = useState();
    const [deleteIndex, setDeleteIndex] = useState();
    const [ErrorMessage, setErrorMessage] = useState()

    const [ResourceFilter, setResourceFilter] = useState();
    const [ProcessFilter, setProcessFilter] = useState();
    const [WasteFilter, setWasteFilter] = useState();

    const getProcessFlowDiagramData = async () => {
        let getBucketdata = localStorage.getItem("processFlowBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filteredBucket = BucketJson && BucketJson.filter((company) => company.company_id == comp_url);
        if (filteredBucket == null || filteredBucket.length == 0) {
            let data = {
                "company_id": comp_url,
            }
            try {
                await api.getProcessFlowData(data).then((res) => {
                    let responseData = res.data;
                    let Result = responseData.process_json.map((item, index) => {
                        let data = {
                            id: item.id,
                            is_custom: item.is_custom,
                            is_delete: item.is_delete,
                            process_id: item.process_id,
                            process_name: item.process_name,
                            resource_json: JSON.parse(item.resource_json),
                            waste_json: JSON.parse(item.waste_json)
                        }
                        return data;
                    })
                    let allProcessData = {
                        company_id: responseData.company_id,
                        process_json: Result
                    }
                    let ProcessFlowBucketArray = [];
                    ProcessFlowBucketArray.push(allProcessData)
                    localStorage.setItem("processFlowBucket", JSON.stringify(ProcessFlowBucketArray))
                    setProcessFlowData(allProcessData.process_json);
                    if (filteredBucket != null) {
                        let ProcessFlowBucketArray = [...BucketJson];
                        ProcessFlowBucketArray.push(allProcessData)
                        localStorage.setItem("processFlowBucket", JSON.stringify(ProcessFlowBucketArray))
                        setProcessFlowData(allProcessData.process_json)
                    }
                    else {
                        let ExeBucketArray = [];
                        ExeBucketArray.push(allProcessData)
                        localStorage.setItem("processFlowBucket", JSON.stringify(ProcessFlowBucketArray))
                        setProcessFlowData(allProcessData.process_json)
                    }
                })
            }
            catch (error) {
                if (error.code !== "ERR_NETWORK") {
                    let errorCode = error.response.status;
                    let errMsg = error.response.data.error.message;
                    if (errorCode == 401) {
                        logout();
                    }
                    if (errorCode == 400 || errorCode == 500) {
                        setInternetError(errMsg);
                        setErrorpopup(true);
                        let errorLogData = {
                            api_name: "getprocess",
                            error_code: errorCode,
                            error_message: errMsg
                        }
                        ErrorLog(errorLogData);
                    }
                }
            }
        }
        else {
            let filteredBucketTwo = BucketJson && BucketJson.filter((company) => company.company_id == comp_url);
            setProcessFlowData(filteredBucketTwo[0].process_json);
        }
    }


    const resourceFuncClick = (e, indexId) => {//resource function click dropdown open
        e.stopPropagation()
        // let currentProcessFlow = processFlowData.filter((index) => index == indexId);
        setProcessOpen();
        setWasteHazardopen();
        if (resources != null) {
            setResourceFilter(resources);
            processFlowData.forEach((value, index) => {
                if (index == indexId) {
                    setResourceopen(indexId)
                }
            });
        }
    }


    const selectResources = (e, indexId, resource_name, resource_id) => {//resource selection click
        e.stopPropagation()
        let inputsField = document.querySelectorAll(".resourcefield");
        inputsField.forEach((element, index) => {
            if (index == indexId) {
                element.focus();
            }
        })

        //addResourceCollection(indexId)
        // let getitem = JSON.parse(localStorage.getItem('processFlow'))
        //var result = [...processFlowData];
        let resourceFiled = document.querySelectorAll(".resourcefield");
        resourceFiled.forEach((item, index) => {
            if (index === indexId) {
                //setStateResource({...resourceValue,resource_name:resource_name})
                if (resource_name == "customResource") {
                    //ResourceRef.current[indexId].style.display = "block"
                    //item.focus();
                    //item.placeholder = "Add custom resources"
                    setStateResource({ ...resourceValue, resource_name: '' })
                    setResourceopen()
                }
                else {
                    //ResourceRef.current[indexId].style.display = "none"
                    item.value = resource_name;
                    //item.placeholder = "Select/Add custom resources";
                    setStateResource({ ...resourceValue, resource_name: resource_name, resource_id: resource_id })
                    setResourceopen()
                }

                // x.status=0
            }
            return item;
        });
    }
    //Select wastehazard collection

    const selectWasteHazard = (e, indexId, waste_name, waste_hazard_id) => {//resource selection click 
        ResourceRef.current[indexId].style.display = "none"
        let inputsField = document.querySelectorAll(".wastehazardField");
        inputsField.forEach((element, index) => {
            if (index == indexId) {
                element.focus();
            }
        })
        e.stopPropagation()
        let wasteFiled = document.querySelectorAll(".wastehazardField");
        wasteFiled.forEach((item, index) => {
            if (index == indexId) {
                if (waste_name == "customWaste") {
                    // item.focus();
                    // item.placeholder = "Add custom waste/hazard"
                    // item.value = ""
                    setWastehazardCollection({ ...wasteHazardCollection, waste_name: '' })
                    setWasteHazardopen()
                }
                else {
                    item.value = waste_name
                    setWastehazardCollection({ ...wasteHazardCollection, waste_name: waste_name, waste_id: waste_hazard_id })
                    setWasteHazardopen()
                }

                // x.status=0
            }
            return item;
        });
    }

    const processFuncClick = (e, indexId) => {//process function click dropdown open
        e.stopPropagation()

        setResourceopen();
        setWasteHazardopen();
        if (process != null) {
            setProcessFilter(process);
            //  let getitem = JSON.parse(localStorage.getItem('processFlow'))
            var result = [...processFlowData];
            result = result.map((x, index) => {
                if (index === indexId) {
                    setProcessOpen(indexId)
                }
                return x;
            });
            setProcessFlowData(result);
        }
        // localStorage.setItem('processFlow', JSON.stringify(result))
    }
    const selectProcess = (process_name, indexId, process_id) => {//resource selection click 
        let inputsField = document.querySelectorAll(".processInputfield");

        inputsField.forEach((element, index) => {
            if (index == indexId) {
                element.focus();
                //element.placeholder = "Add custom process";
            }
        })
        //let getitem = JSON.parse(localStorage.getItem('processFlow'))
        var result = [...processFlowData];
        let ProcessId = process_id;
        result = result.map((x, index) => {
            if (index === indexId) {

                if (process_name == "customProcess") {
                    x.process_name = "";
                }
                else {
                    x.process_name = process_name;
                    x.process_id = ProcessId;
                    x.is_custom = 0;
                }
                setProcessOpen()

            }
            return x;
        });

        let AllData = {
            company_id: comp_url,
            process_json: result
        }
        let getBucketdata = localStorage.getItem("processFlowBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        filterData.push(AllData);
        localStorage.setItem("processFlowBucket", JSON.stringify(filterData))
        setProcessFlowData(result);
        //localStorage.setItem('processFlowBucket', JSON.stringify(result))
    }


    const wastehazardFuncClick = (e, indexId) => {//waste/hazard function click dropdown open
        e.stopPropagation();
        setProcessOpen();
        setResourceopen();
        if (wasteHazard != null) {
            setWasteFilter(wasteHazard);
            processFlowData.forEach((value, index) => {
                if (index == indexId) {
                    setWasteHazardopen(indexId)
                }
            });
        }

    }

    const columnsArray = [t("Resources"), t("Process"), t("WasteHazards")];
    const [inputList, setInputList] = useState([{
        resource_name: '',
        process_name: '',
        waste_name: ''
    }])

    //onchnage Process function
    const processOnchange = (e, index) => {
        const { name, value } = e.target;
        const inputData = [...processFlowData]
        inputData[index].process_id = 0;
        inputData[index][name] = value;
        let filterValue = e.target.value;
        let filterAllvalue = filterValue.toLowerCase()
        setProcessFilter(process != null && process.filter(f => f.process_name.toLowerCase().includes(filterAllvalue)))
        setProcessFlowData(inputData)
        let AllData = {
            company_id: comp_url,
            process_json: inputData
        }
        let getBucketdata = localStorage.getItem("processFlowBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        filterData.push(AllData);
        localStorage.setItem("processFlowBucket", JSON.stringify(filterData))

    }

    //Remove Process function 
    const removeProcess = (indexId) => {
        //let getitem = JSON.parse(localStorage.getItem('processFlow'))
        var result = [...processFlowData];
        result = result.map((x, index) => {
            if (index === indexId) {
                x.process_name = '';
                // x.status=0
            }
            return x;
        });

        setProcessFlowData(result);
        let AllData = {
            company_id: comp_url,
            process_json: result
        }
        let getBucketdata = localStorage.getItem("processFlowBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        filterData.push(AllData);
        localStorage.setItem("processFlowBucket", JSON.stringify(filterData))
        // localStorage.setItem('processFlow', JSON.stringify(result))
    }
    //Resourse Onchange function 
    const resourceOnchange = (e, indexId) => {
        let InputList = document.querySelectorAll('.resourcefield');
        let ErrorCheck = document.querySelectorAll('.errorCheck');
        let filterValue = e.target.value;
        let filterAllvalue = filterValue.toLowerCase()
        InputList.forEach((item, index) => {
            if (index == indexId) {
                item.value = e.target.value
                if (item.value.length > 0) {
                    ErrorCheck.forEach((error, indexError) => {
                        if (indexError == indexId) {
                            error.innerHTML = "";
                        }
                    })
                }
                setStateResource({ ...resourceValue, resource_name: e.target.value, resource_id: 0 })
                setResourceFilter(resources != null && resources.filter(f => f.resource_name.toLowerCase().includes(filterAllvalue)))
            }
        })

    }
    //WasteHazard Onchange function 
    const wasteHazardOnChange = (e, indexId) => {
        let InputList = document.querySelectorAll('.wastehazardField')
        let ErrorCheck = document.querySelectorAll('.wasteErrorcheck');
        let filterValue = e.target.value;
        let filterAllvalue = filterValue.toLowerCase()
        setWasteFilter(wasteHazard != null && wasteHazard.filter(f => f.waste_hazard_name.toLowerCase().includes(filterAllvalue)))
        InputList.forEach((item, index) => {
            if (index == indexId) {
                item.value = e.target.value
                ErrorCheck.forEach((error, indexError) => {
                    if (indexError == indexId) {
                        error.innerHTML = "";
                    }
                })
                setWastehazardCollection({ ...wasteHazardCollection, waste_name: e.target.value, waste_id: 0 })
            }
        })
    }


    //Add wasteHazard Collection
    const wasteFieldHide = (indexId) => {
        setResourceopen();
        setProcessOpen();
        WasteRef.current[indexId].style.display = "block"
    }
    const addWasteHazardCollection = (indexId) => {
        setTimeout(() => {
            WasteRef.current[indexId].style.display = "none"
        }, 100)
        //let getitem = JSON.parse(localStorage.getItem('processFlow'));
        var result = [...processFlowData];
        let ErrorCheck = document.querySelectorAll(".wasteErrorcheck");
        result = result.map((wasteHazard, index) => {
            if (index === indexId) {
                if (wasteHazard.waste_json.length < 6) {
                    let data = {
                        waste_id: wasteHazardCollection.waste_id,
                        waste_name: wasteHazardCollection.waste_name
                    }

                    if (wasteHazardCollection.waste_name.length > 0) {
                        if (wasteHazard.waste_json == null) {
                            wasteHazard.waste_json = []
                            wasteHazard.waste_json.push(data)
                        }
                        else {
                            wasteHazard.waste_json.push(data)
                        }
                    }
                    // else {
                    //     ErrorCheck.forEach((error, index) => {
                    //         if (index === indexId) {
                    //             error.innerHTML = t('processflowWasteHazardValidation')
                    //         }
                    //     })
                    // }
                }
                else {
                    setWasteHazardopen();
                    alert(t("MaximumwasteandHazardlimit"))
                }

            }
            return wasteHazard;
        });

        setProcessFlowData(result);
        let AllData = {
            company_id: comp_url,
            process_json: result
        }
        let getBucketdata = localStorage.getItem("processFlowBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        filterData.push(AllData);
        localStorage.setItem("processFlowBucket", JSON.stringify(filterData))
        let InputList = document.querySelectorAll('.wastehazardField')
        InputList.forEach((item, index) => {
            if (index == indexId) {
                item.value = "";
                //item.focus();
                setWastehazardCollection({ ...wasteHazardCollection, waste_name: '' })
            }
        })
    }
    const resourceFieldHide = (indexId) => {
        setWasteHazardopen();
        setProcessOpen();
        ResourceRef.current[indexId].style.display = "block"
    }

    //Add resource Collection
    const addResourceCollection = (indexId) => {
        setTimeout(() => {
            ResourceRef.current[indexId].style.display = "none"
        }, 100);
        var result = [...processFlowData];
        //let ErrorCheck = document.querySelectorAll('.errorCheck')
        result = result.map((resource, index) => {
            if (index === indexId) {
                if (resource.resource_json.length < 6) {
                    let data = {
                        resource_id: resourceValue.resource_id,
                        resource_name: resourceValue.resource_name
                    }
                    if (resourceValue.resource_name.length > 0) {
                        if (resource.resource_json == null) {
                            resource.resource_json = []
                            resource.resource_json.push(data)
                        }
                        else {
                            resource.resource_json.push(data)
                        }
                    }
                    // else {
                    //     ErrorCheck.forEach((error, index) => {
                    //         if (index === indexId) {
                    //             error.innerHTML = t('processflowResourceValidation')
                    //         }
                    //     })
                    // }
                }
                else {
                    setResourceopen();
                    alert(t("MaximumResourcelimit"));
                }


            }
            return resource;
        });
        setProcessFlowData(result);
        let AllData = {
            company_id: comp_url,
            process_json: processFlowData
        }
        let getBucketdata = localStorage.getItem("processFlowBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        filterData.push(AllData);
        localStorage.setItem("processFlowBucket", JSON.stringify(filterData))
        let InputList = document.querySelectorAll('.resourcefield')
        //var result = [...getitem];
        InputList.forEach((item, index) => {
            if (index === indexId) {
                item.value = "";
                setStateResource({ ...resourceValue, resource_name: '' })
            }
        })

    }
    //Remove CurrentResource from collection

    const removeCurrentResource = (indexId, resourceId) => {
        // let getitem = JSON.parse(localStorage.getItem('processFlow'))
        var result = [...processFlowData];
        result = result.map((resource, index) => {
            if (index === indexId) {
                resource.resource_json.forEach((res, index) => {
                    if (index == resourceId) {
                        resource.resource_json.splice(index, 1);
                    }
                })
            }
            return resource;
        });
        setProcessFlowData(result);

        let AllData = {
            company_id: comp_url,
            process_json: result
        }
        let getBucketdata = localStorage.getItem("processFlowBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        filterData.push(AllData);
        localStorage.setItem("processFlowBucket", JSON.stringify(filterData))
    }
    //Remove Current Waste hazard
    const removeCurrentWaste = (indexId, wasteId) => {
        var result = [...processFlowData];
        result = result.map((waste, index) => {
            if (index === indexId) {
                waste.waste_json.forEach((was, indexWaste) => {
                    if (indexWaste == wasteId) {
                        waste.waste_json.splice(indexWaste, 1);
                    }
                })
            }
            return waste;
        });
        setProcessFlowData(result);
        let AllData = {
            company_id: comp_url,
            process_json: result
        }
        let getBucketdata = localStorage.getItem("processFlowBucket");
        let BucketJson = JSON.parse(getBucketdata);
        let filterData = BucketJson.filter((item) => item.company_id != comp_url)
        filterData.push(AllData);
        localStorage.setItem("processFlowBucket", JSON.stringify(filterData))
    }
    const addNewProcess = (current_index) => {
        if (processFlowData != undefined) {
            let finalIndex = current_index == undefined ? 0 : current_index + 1;
            var AllProcessData = [...processFlowData];

            let isDeleteCount = AllProcessData.filter((item, index) => item.is_delete == 0);
            let deleteCont = isDeleteCount.filter((item, index) => item.process_name == '');
            if (deleteCont.length == 0) {
                if (isDeleteCount.length < 15) {
                    AllProcessData.splice(finalIndex, 0,
                        {
                            id: 0,
                            is_custom: '',
                            is_delete: 0,
                            process_id: 0,
                            process_name: '',
                            resource_json: [],
                            waste_json: [],
                        });
                    let AllData = {
                        company_id: comp_url,
                        process_json: AllProcessData
                    }
                    let getBucketdata = localStorage.getItem("processFlowBucket");
                    let BucketJson = JSON.parse(getBucketdata);
                    let filterData = BucketJson.filter((item) => item.company_id != comp_url)
                    filterData.push(AllData);
                    localStorage.setItem("processFlowBucket", JSON.stringify(filterData))

                }
                else {
                    SetProcessShow(true)
                }
            }
            else {
                setProcessFiledvalid(true)
            }

            let processFlow = AllProcessData;
            // let newata = localStorage.setItem("newdata",mydata)
            //  localStorage.setItem('processFlow', JSON.stringify(AllProcessData))
            setProcessFlowData(processFlow)
            // getProcessFlowDataFunc();
        }


    }
    const deleteProcess = (indexId) => {
        deleteProcessRow()
        setDeleteIndex(indexId)
        setShow(true)
    }
    const deleteProcessRow = (dele_Value) => {
        if (dele_Value == 'yes') {
            // processFlowData.forEach((element, index) => {
            //     if (index === deleteIndex) {
            //         //result.splice(deleteIndex, 1);
            //         element.is_delete = 1
            //     }
            // });
            // setProcessFlowData(result);
            // setShow(false)
            // localStorage.setItem('processFlow', JSON.stringify(result))
            let processFlow = [...processFlowData];
            processFlow[deleteIndex].is_delete = 1;
            setShow(false)
            let AllData = {
                company_id: comp_url,
                process_json: processFlow
            }
            let getBucketdata = localStorage.getItem("processFlowBucket");
            let BucketJson = JSON.parse(getBucketdata);
            let filterData = BucketJson.filter((item) => item.company_id != comp_url)
            filterData.push(AllData);
            localStorage.setItem("processFlowBucket", JSON.stringify(filterData))
            setProcessFlowData(processFlow)
        }
        else {
            setShow(false)
        }
    }
    const closeFumction = () => {
        setProcessOpen();
        setResourceopen();
        setWasteHazardopen();
    }

    const saveProcessListFunction = () => {
        if (processFlowData != undefined) {
            var AllProcessData = processFlowData;
            //const objKeys = Object.keys(AllProcessData);
            let isDeleteCount = AllProcessData.filter((item, index) => item.is_delete == 0);

            let deleteCont = isDeleteCount.filter((item, index) => item.process_name == '');
            if (deleteCont.length == 0) {
                setInsertprocessSuccess(true)
            }
            else {
                setProcessFiledvalid(true)
            }
        }
    }

    const saveEntireProcess = async (Savestatus) => {
        if (Savestatus == "yes") {
            setLoaderSave(true);
            let data = {
                company_id: comp_url,
                process_json: processFlowData
            };
            await api.addProcessFlowData(data).then((response) => {
                if (response.status == 200 || response.status == 201) {
                    setInsertprocessSuccess(false)
                    setSuccessDone(true)
                    setSuccessMessage(response.data.message)
                    let getBucketdata = localStorage.getItem("processFlowBucket");
                    let BucketJson = JSON.parse(getBucketdata);
                    let filterData = BucketJson.filter((item) => item.company_id != comp_url)
                    localStorage.setItem("processFlowBucket", JSON.stringify(filterData))
                    getProcessFlowDiagramData();
                    setTimeout(() => {
                        setLoaderSave(false)
                    }, 1000)
                }
            }).catch((error) => {
                if (error.code != "ERR_NETWORK") {
                    let errorCode = error.response.status;
                    let errMsg = error.response.data.error.message;
                    if (errorCode == 400 || errorCode == 500) {
                        setTimeout(() => {
                            setErrorMessage(error.response.data.error.message)
                            setLoaderSave(false)
                        }, 1000)
                        let errorLogData = {
                            api_name: "addprocess",
                            error_code: errorCode,
                            error_message: errMsg
                        }
                        ErrorLog(errorLogData);
                    }
                    if (errorCode == 401) {
                        logout();
                    }
                }
                else {
                    setInsertprocessSuccess(false)
                    setSuccessDone(true)
                    setSuccessMessage(error.message + ". Please check your internet connection.")
                }
            })
        }
        else {
            setInsertprocessSuccess(false)
            setErrorMessage()
        }
    }
 
    useEffect(() => {
        setResourceFilter(resources);
        setProcessFilter(process);
        setWasteFilter(wasteHazard);
        document.addEventListener('click', closeFumction)
        getProcessFlowDiagramData()
    }, [lanId])

    return (
        <div className='processFlow'>
            <div className="container scrollCustom" style={{ height: "calc(100vh - 400px)", overflowY: "auto" }}>
                <div className="row">
                    <div className="col-md-12">
                        <table className={"table" + " " + IARStyles.iarTable}>
                            <thead>
                                <tr>
                                    {columnsArray && columnsArray.map((column, index) => (
                                        <th className="text-center" key={index}>{column}</th>
                                    ))}
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {processFlowData != null ? processFlowData.map((P_flow, index) => P_flow.is_delete == 0 ? <tr key={index}>
                                    <td
                                        className="text-center"
                                        style={{
                                            width: "33.3%",
                                            padding: window.innerWidth > 992 ? "26px 40px 3px 25px" : "26px 8px 3px 14px"
                                        }}
                                    >
                                        <ul className="addedList">
                                            {P_flow.resource_json != null ? P_flow.resource_json.map((resourceList, res_idx) => <li key={res_idx}>{resourceList.resource_id == 0 ? <span className="customRound"></span> : null}
                                                <span>{resourceList.resource_name}</span>
                                                <label className="deleteItem" onClick={() => removeCurrentResource(index, res_idx)}>x</label></li>) : []}
                                        </ul>
                                        <div className="customDropdown mb-2">
                                            <div ref={(el) => (ResourceRef.current[index] = el)}
                                                style={{ display: "none" }}
                                            >
                                                <Form.Control
                                                    className="resourcefield"
                                                    type="text"
                                                    name="resource_name"
                                                    placeholder={t("selectResource")}
                                                    onChange={(e) => resourceOnchange(e, index)}
                                                    onBlur={() => addResourceCollection(index)}
                                                    onClick={(e) => resourceFuncClick(e, index)}
                                                    value={P_flow.resource_name}
                                                    maxLength="50"
                                                    required
                                                    autoComplete="off"
                                                />
                                                {/* <button className={IARStyles.saveButton}><img src={SaveBTN} alt="save"/></button> */}
                                                {/* <label className="closeButton">X</label> */}

                                                <div className="form-text text-danger errorCheck" style={{ textAlign: "left" }}></div>
                                            </div>
                                            {resourceOpen == index ? <ul className="customMenu">
                                                {/* <li onClick={(e) => selectResources(e, index, "customResource", 0)}>Add Custom Resource</li> */}
                                                {ResourceFilter && ResourceFilter.map((itemR, R_index) => <li id={itemR.resource_id} key={R_index} onMouseDown={(e) => selectResources(e, index, itemR.resource_name, itemR.resource_id)}>{itemR.resource_name}</li>)}
                                                {ResourceFilter.length == 0 && <li>{t("AddCustomResource")}</li>}
                                            </ul> : null}
                                        </div>

                                        <button
                                            className="darkline_btn small_btn"
                                            //onClick={() => addResourceCollection(index)}
                                            onMouseDown={() => resourceFieldHide(index)}
                                            style={{ marginBottom: "18px" }}
                                        ><img src={plus} alt="Plus" />{t("addResources")}</button>
                                    </td>
                                    <td
                                        className="text-center"
                                        style={{
                                            width: "33.3%",
                                            padding: window.innerWidth > 992 ? "26px 40px 3px 25px" : "26px 8px 3px 14px"
                                        }}
                                    >
                                        <div className="customDropdown mb-2">
                                            {P_flow.process_id == 0 && P_flow.process_name.length > 0 || P_flow.is_custom === 1 ? <span className="customRound"></span> : null}
                                            <Form.Control
                                                className="processInputfield"
                                                type="text"
                                                name="process_name"
                                                placeholder={t("selectProcess")}
                                                onClick={(e) => processFuncClick(e, index)}
                                                onChange={(e) => processOnchange(e, index)}
                                                value={P_flow.process_name}
                                                maxLength="50"
                                                autoComplete="off"
                                            />
                                            {P_flow.process_name && P_flow.process_name.length > 0 ? <label className="closeButton" onClick={() => removeProcess(index)}>X</label> : null}
                                            {processOpen == index && processOpen != undefined ? <ul className="customMenu">
                                                {/* <li onClick={() => selectProcess("customProcess", index)}>Add Custom Process</li> */}
                                                {ProcessFilter && ProcessFilter.map((itemP, Pindex) => <li id={itemP.process_id} key={Pindex} onClick={() => selectProcess(itemP.process_name, index, itemP.process_id)}>{itemP.process_name}</li>)}
                                                {ProcessFilter.length == 0 && <li>{t("AddCustomProcess")}</li>}
                                            </ul> : null}
                                        </div>
                                        <button className="darkline_btn small_btn processDeskBtn" onClick={() => addNewProcess(index)}><img src={plus} alt="Plus" />{t("addProcess")}</button>
                                        <div className={IARStyles.arrowProcess}><RiArrowDownFill /></div>
                                    </td>

                                    <td
                                        className="text-center"
                                        style={{
                                            width: "33.3%",
                                            padding: window.innerWidth > 992 ? "26px 40px 3px 25px" : "26px 8px 3px 14px"
                                        }}
                                    >
                                        <ul className="addedList">
                                            {P_flow.waste_json != null ? P_flow.waste_json.map((waste, was_index) => <li key={was_index}>{waste.waste_id == 0 ? <span className="customRound"></span> : null}<span>{waste.waste_name}</span><label className="deleteItem" onClick={() => removeCurrentWaste(index, was_index)}>x</label></li>) : ''}
                                        </ul>
                                        <div className="customDropdown mb-2">
                                            <div ref={(el) => (WasteRef.current[index] = el)}
                                                style={{ display: "none" }}
                                            >
                                                <Form.Control
                                                    className="wastehazardField"
                                                    type="text"
                                                    name="waste_name"
                                                    placeholder={t("selectWaste")}
                                                    onClick={(e) => wastehazardFuncClick(e, index)}
                                                    onChange={(e) => wasteHazardOnChange(e, index)}
                                                    onBlur={() => addWasteHazardCollection(index)}
                                                    value={P_flow.waste_name}
                                                    maxLength="50"
                                                    autoComplete="off"
                                                />
                                                {/* <label className="closeButton">X</label> */}
                                                <div className="form-text text-danger wasteErrorcheck" style={{ textAlign: "left" }}></div>
                                            </div>
                                            {wasteHazardOpen == index ? <ul className="customMenu">
                                                {/* <li onClick={(e) => selectWasteHazard(e, index, "customWaste", 0)}>Add Custom Waste/Hazards</li> */}
                                                {WasteFilter && WasteFilter.map((itemW, indexW) => <li id={itemW.waste_hazard_id} key={indexW} onMouseDown={(e) => selectWasteHazard(e, index, itemW.waste_hazard_name, itemW.waste_hazard_id)}>{itemW.waste_hazard_name}</li>)}
                                                {WasteFilter.length == 0 && <li>{t("AddCustomWasteandHazard")}</li>}
                                            </ul> : null}
                                        </div>

                                        <button
                                            className="darkline_btn small_btn"
                                            //onClick={() => addWasteHazardCollection(index)}
                                            onMouseDown={() => wasteFieldHide(index)}
                                        ><img src={plus} alt="Plus" />{t("addWaste")}</button>
                                    </td>
                                    <td className="text-center">
                                        <button className="deletRow_btn small_btn" onClick={() => deleteProcess(index)}><img src={remove} alt="Remove" />{t("deleteRow")}</button>
                                    </td>
                                </tr> : '') : null}

                            </tbody>
                        </table>
                        {processFlowData == null || processFlowData.length == 0 || processFlowData[0].is_delete == 1 ? <div style={{ textAlign: "center" }}><button className="darkline_btn small_btn" onClick={() => addNewProcess()}><img src={plus} alt="Plus" />{t("addProcess")}</button></div> : null}

                        <Modal className="newObservation removeConform" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>

                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{t("deleteProcessconform")}</h4>
                                    <button className="secondary_btn small_btn" onClick={() => deleteProcessRow('no')}>{t("No")}</button>
                                    <button className="secondary_btn small_btn" onClick={() => deleteProcessRow('yes')}>{t("Yes")}</button>

                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={processShow} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{t("processLimit")}</h4>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={insertProcesssuccess} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{t("saveprocessConform")}</h4>
                                    <button className="secondary_btn small_btn" onClick={() => saveEntireProcess('no')}>{t("No")}</button>
                                    <button className="secondary_btn small_btn" onClick={() => saveEntireProcess('yes')}>{t("Yes")}</button>
                                    <div className="text-danger mt-2">{ErrorMessage ? ErrorMessage : null}</div>
                                    {LoaderSave === true ? <img src={Loader} alt="loader" width="130" /> : null}
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={processFiledvalid} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{t("Processleftempty")}</h4>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={successDone} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    {LoaderSave === true ? <div><img src={Loader} alt="loader" width="130" /></div> : <h4 className="mb-4">{successMessage}</h4>}
                                    <button className="secondary_btn small_btn" onClick={() => setSuccessDone(false)}>{t("Ok")}</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal className="newObservation removeConform" show={Errorpopup} onHide={() => setErrorpopup(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="P-3 pt-0 text-center">
                                    <h4 className="mb-4">{InternetError}</h4>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
            <button className="secondary_btn"
                onClick={saveProcessListFunction}
                style={{
                    float: "right",
                    margin: "42px -10px -15px",
                    padding: "8px 32px"
                }}
            >{t("Save")}</button>
        </div>
    );
}
export default ProcessFlowDiagram;