import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataService from '../../services/DataServices';
import Style from './notificationmob.module.css';
import TcBreadCrumb from '../tc_breadcrumb/tc_breadcrumb';
import AuthUser from '../authUser/authuser';
import Loader from '../../assets/images/loadin_gif.gif';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import { ErrorLog } from '../erroLog/errorLog';

const NotificationMobileview = () => {
    const { logout } = AuthUser();
    const api = new DataService();
    const {t} = useTranslation();
    useLanguageChange();
    const [UnRead,setUnRead] =useState([])
    const [ReadNotification,setReadNotification]=useState([])
    const [LoaderImg,setLoaderImg] =useState(true);
    const [ReadLoader,setReadLoader] = useState(false);
    const getNotifications = async () => {
        setLoaderImg(true);
        setReadLoader(true);
        await api.getNotifications().then((response) => {
            let data = response.data;
            let UnreadNotification = data[0];
            let readNotification = data[1];
            let undreadNot = JSON.parse( UnreadNotification[0].unread);
            let readNot = JSON.parse(readNotification[0].readNotification);
            setUnRead(undreadNot)
            setReadNotification(readNot)
            setTimeout(()=>{
                setLoaderImg(false)
                setReadLoader(false)
            },1000)
        }).catch((error) => {
            if (error.code !== "ERR_NETWORK") {
                let errorCode = error.response.status;
                let errMsg = error.response.data.error.message;
                if (errorCode == 401) {
                    logout();
                }
                if (errorCode == 400 || errorCode == 500) {
                    let errorLogData ={
                        api_name:"getNotifications",
                        error_code:errorCode,
                        error_message:errMsg
                    } 
                    ErrorLog(errorLogData);
                }
            }
        })
    }
     const notificationReadAllFunc = async ()=>{
			await api.getReadAllNotification().then(()=>{
			 }).catch((error)=>{
                if (error.code !== "ERR_NETWORK") {
                    let errorCode = error.response.status;
                    let errMsg = error.response.data.error.message;
                    if (errorCode == 401) {
                        logout();
                    }
                    if (errorCode == 400 || errorCode == 500) {
                        let errorLogData ={
                            api_name:"readallnotification",
                            error_code:errorCode,
                            error_message:errMsg
                        } 
                        ErrorLog(errorLogData);
                    }
                }
             })
	}
    useEffect(() => {
        getNotifications()
        return ()=>{
            notificationReadAllFunc()
        }
    }, [])
    return (
        <div>
           {window.innerWidth > 768 ? <TcBreadCrumb title="Notifications"/>:null}
            <div className="container mt-3">
                <div className='row'>
                    <div className='col-md-6 col-lg-5'>
                        <div className={"row notificationPaddingbottom"+" "+Style.notificationWrapper}>
                            <div>
                                <p className={Style.header}>{t("Unread")}</p>
                                <hr className={Style.header_hr} />
                                <ul>
                                    <div>
                                        {UnRead != null &&  UnRead.length > 0? UnRead.map((notification, index) =>
                                            <li key={index}>
                                                <Link>
                                                    <div className={Style.notificationMaintxt}>
                                                        <p >{notification.notification_title}</p>
                                                    </div>
                                                    <div className="d-flex" style={{justifyContent:"space-between"}}>
                                                        <div className={Style.notificationSubtxt}>
                                                            <p>{notification.notification_description}</p>
                                                        </div>
                                                        <div className={Style.notificationSubtxtDate} style={{textAlign:"right"}}>
                                                            <span>{notification.notification_created_date}</span>
                                                            <span>{notification.notification_time}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <hr className={Style.notification_hr} />

                                            </li>

                                        ) :LoaderImg === true ? <img src={Loader} alt="loader" width="150"/>: t("NounreadList")}
                                    </div>
                                </ul>
                            </div>
                            <div className='mt-3'>
                                <p className={Style.header}>{t("Older")}</p>
                                <hr className={Style.header_hr} />
                                <div>
                                    <ul>
                                        {ReadNotification != null && ReadNotification.length > 0? ReadNotification.map((notification, index) =>
                                            <li key={index}>
                                                <Link>
                                                    <div className={Style.notificationMaintxt}>
                                                        <p >{notification.notification_title}</p>
                                                    </div>
                                                    <div className="d-flex" style={{justifyContent:"space-between"}}>
                                                        <div className={Style.notificationSubtxt}>
                                                            <p>{notification.notification_description}</p>
                                                        </div>
                                                        <div className={Style.notificationSubtxtDate} style={{textAlign:"right"}}>
                                                            <span>{notification.notification_created_date}</span>
                                                            <span>{notification.notification_time}</span>
                                                           
                                                        </div>
                                                    </div>
                                                </Link>
                                                <hr className={Style.notification_hr} />

                                            </li>
                                        ) :ReadLoader == true?<img src={Loader} alt="loader" width="150"/>:t("Nonotifications")}
                                    </ul>
                                </div>
                                <hr className={Style.notification_hr} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default NotificationMobileview