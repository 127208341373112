export const english = [
	{
		title: 'What is the REAP Digital Solution?',
		content:
			'The REAP Digital Solution (REAP DS) is a closed group platform developed under the REAP project funded by the European Union. It is intended for use exclusively under the REAP project and provides various tools and resources to support project activities.',
	},
	{
		title: 'How do I access the REAP Digital Solution?',
		content:
			'Access to this solution is given to only the relevant REAP Project team members and it is not open to general public. Access is managed by a designated admin person from the REAP project team. If you have been given access and have registered yourself as a user, , you can access the REAP DS by logging in with your username and password.',
	},
	{
		title: 'What can I do with the REAP Digital Solution? ',
		content:
			'The REAP Digital Solution can be used to prepare the draft initial assessment report (IAR). It also has some calculation tools to help in calculation of potential resource savings. ',
	},
	{
		title: 'Who can I contact if I need help using the REAP Digital Solution? ',
		content:
			'If you have any questions or concerns about using the REAP Digital Solution, please contact the support team at <a href="mailto:info@reap-centralasia.org?subject=Query about Reap DS">info@reap-centralasia.org.</a> We are here to assist you and ensure that you have a positive experience using this digital solution',
	},
	{
		title: 'Is there any fee to use REAP DS?',
		content: 'At present, there is no fee to use REAP DS.',
	},
	{
		title: 'Does REAP DS work on different operating systems?',
		content:
			'Yes, it works on Android (minimum version should be xxxx), IoS (minimum version should be xxxx) and Windows (minimum version should be xxxx) operating systems',
	},
	{
		title: 'Can I use REAP DS offline when I do not have internet connection?',
		content:
			'Yes, you can use many features of REAP DS (for example xxxxxxxx) when you do not have internet connection. When you connect back to the internet, the REAP DS will synchronize any data you would have input when you were offline',
	},
	{
		title: 'Can I use REAP DS in English or Russian?',
		content:
			'Yes. You can use it in either in English or Russian. You can switch between the two languages by making the appropriate selection in the “Settings” section.',
	},
];

export const russian = [
	{
		title: 'В: Что такое Цифровое Решение REAP?',
		content:
			'Цифровое Решение REAP (REAP ЦР) — это закрытая групповая платформа, разработанная в рамках проекта REAP, финансируемого Европейским Союзом. Оно предназначено для использования исключительно в рамках проекта REAP и предоставляет различные инструменты и ресурсы для поддержки проектной деятельности.',
	},
	{
		title: 'Как получить доступ к Цифровому Решению REAP?',
		content:
			'Цифровое Решение REAP (REAP ЦР) — это закрытая групповая платформа, разработанная в рамках проекта REAP, финансируемого Европейским Союзом. Оно предназначено для использования исключительно в рамках проекта REAP и предоставляет различные инструменты и ресурсы для поддержки проектной деятельности.',
	},
	{
		title:
			'Доступ к этому решению предоставляется только соответствующим членам команды проекта ',
		content:
			'REAP, и оно не открыто для широкой публики. Доступ управляется назначенным администратором из команды проекта REAP. Если вы получили доступ и зарегистрировались как пользователь, то вы можете получить доступ к REAP ЦР, войдя в систему со своим именем пользователя и паролем.',
	},
	{
		title: 'Что я могу делать с Цифровым Решением REAP?',
		content:
			'Цифровое Решение REAP можно использовать для подготовки проекта отчета о первоначальной оценке (ОПО). Он также имеет некоторые инструменты расчета, помогающие рассчитать потенциальную экономию ресурсов.',
	},
	{
		title:
			'К кому я могу обратиться, если мне понадобится помощь в использовании Цифрового Решения REAP?',
		content:
			'Если у вас есть какие-либо вопросы или опасения по поводу использования Цифрового Решения REAP, обратитесь в службу поддержки по адресу <a href="mailto:info@reap-centralasia.org?subject=Query about Reap DS">info@reap-centralasia.org.</a> Мы здесь, чтобы помочь вам и убедиться, что у вас есть положительный опыт использования этого цифрового решения.',
	},
	{
		title: 'Взимается ли плата за использование REAP ЦР?',
		content: 'В настоящее время плата за использование REAP ЦР не взимается.',
	},
	{
		title: 'Работает ли REAP ЦР в разных операционных системах?',
		content:
			'Да, он работает на операционных системах Android (минимальная версия должна быть xxxx), IOS (минимальная версия должна быть xxxx) и Windows (минимальная версия должна быть xxxx).',
	},
	{
		title:
			'Могу ли я использовать REAP ЦР в автономном режиме, если у меня нет подключения к Интернету?',
		content:
			'Да, вы можете использовать многие функции REAP ЦР (например, xxxxxxxx), когда у вас нет подключения к Интернету. Когда вы снова подключитесь к Интернету, REAP ЦР синхронизирует все данные, которые вы вводили, когда были в автономном режиме.',
	},
	{
		title: 'Могу ли я использовать REAP ЦР на английском или русском языках?',
		content:
			'Да. Вы можете использовать его как на английском, так и на русском языке. Вы можете переключаться между двумя языками, сделав соответствующий выбор в разделе «Настройки».',
	},
];
