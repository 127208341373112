import Style from "./FrontPagePreview.module.css"
import logo from '../../../assets/pdf_images/logo.png';
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
import IAR_firstpage_logos from "../../../assets/pdf_images/IAR_firstpage_logos.png"

const FrontPagePreview = ({ company }) => {
    return (
        <div className={Style.demo}>
            <img className={Style.logo_img} src={logo} alt="headerImg" />
            <p className={Style.cmpnyHeader} ><span>Initial Assessment Report</span></p>
            {company && company.map((i, index) => {
                return (
                    <div key={index}>
                        <p className={Style.cmpnyName}>{i.company_name}, {company && i.region_name},</p>
                        <p className={Style.cmpnyCountry}>{i.country_name}</p>
                        <p className={Style.date}><span>{i.iav_date}</span></p>
                    </div>
                )
            })}
            <div className={Style.imgtxtSectn}>
                <div className={Style.switchImgTxt}>
                    <p>Project Funded by</p>
                </div>
                <div className={Style.image_two}>
                    <img src={switchasia} alt="headerImg" />
                </div>
            </div>
            <div>
                <img src={IAR_firstpage_logos} style={{ width: "100%", height: "92px", margin: "22px 0px 20px 22px" }} />
            </div>
        </div>
    )
}
export default FrontPagePreview;
