import { useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import style from './splash.module.css';
const Splash =()=>{
    let navigate = useNavigate()
    const changeScreen =()=>{
        setTimeout(()=>{
            navigate('/chooselanguage')
        },3000)
    }
    useEffect(()=>{
        changeScreen();
    })
    return(
        <div className={style.splash}>
               <img src={Logo} alt='splash'/>
        </div>
    )
}
export default Splash;