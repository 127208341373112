// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clStyles_chooseLanguage__DD8KS h1{
    color: #3B7E80;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}
.clStyles_chooseLanguage__DD8KS p{
    color: #6D6D6D;
    font-size: 14px; 
    text-align: center;
}
button.clStyles_confirm__RkL\\+Y{
    width: 100%;
    max-width: 300px;
} `, "",{"version":3,"sources":["webpack://./src/components/chooseLanguage/clStyles.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".chooseLanguage h1{\r\n    color: #3B7E80;\r\n    font-size: 24px;\r\n    font-weight: 600;\r\n    text-align: center;\r\n}\r\n.chooseLanguage p{\r\n    color: #6D6D6D;\r\n    font-size: 14px; \r\n    text-align: center;\r\n}\r\nbutton.confirm{\r\n    width: 100%;\r\n    max-width: 300px;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chooseLanguage": `clStyles_chooseLanguage__DD8KS`,
	"confirm": `clStyles_confirm__RkL+Y`
};
export default ___CSS_LOADER_EXPORT___;
