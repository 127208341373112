import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Login from './components/login/login';
import Dashboard from './components/dashboard/dashboard';
import Checkpoints from './components/checkpoints/checkpoints';
import IARPreparation from './components/iarPreparation/iarpreparation';
import AddCompany from './components/addCompany/addCompany';
import Notes from './components/notes/notes';
import ChooseLanguage from './components/chooseLanguage/chooseLanguage';
import SplashScreenMobile from './components/mobileSplash/splash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import NotificationMobileview from './components/notificationMobieview/notificationMobieview';
import CompanyDetails from './components/companyDetails/companyDetails';
import UploadSignature from './components/uploadSignature/uploadSignature';
// import ProcessflowRecordedObTab from './components/processflowRecordedObTab/processflowRecordedObTab';
import DateofIAvisit from './components/dateofIAvisit/dateofIAvisit';

import ViewProfile from './components/viewProfile/viewProfile';
import ForgotPassword from './components/forgotPassword/forgotPassword';
import PfdObTab from './components/pfdObTab/pfdObTab';
import AddNewObsMob from './components/pfdObTab/addNewObsMob';

import AboutApp from './components/StaticPages/AboutApp';
import PrivacyPolicy from './components/StaticPages/PrivacyPolicy';
import TermsAndCondtions from './components/StaticPages/TermsAndCondtions';
import Help from './components/Help/Help';
import HeaderWithLogo from './components/header/HeaderWithLogo';
import NotFound from './components/Not Found/NotFound';
import ChangePassword from './components/ChangePassword/ChangePassword';
import AdminRoutes from './AdminRoutes';
import AddGalleryPage from './components/addGallery/addgallery';
import { useEffect, useState } from 'react';
import DataService from './services/DataServices';
import AuthUser from './components/authUser/authuser';
import {
hideHeaderRoutes,
displayHeaderWithLogoRoutes,
} from './components/utils/utils';
import Tools from './components/Tools/Tools';
import Modal from 'react-bootstrap/Modal';
import AdminLogin from './components/admin/components/AdminLogin/login';
import { GlobalProvider } from './context/GlobalState';

const App = () => {
const api = new DataService();
const [enablerUrl, setEnablerUrl] = useState('');
const [showList, setShowList] = useState(true);
const { logout } = AuthUser();
let location = useLocation();
let currentPath = location.pathname.split('/')[1];
const [Languages, setLanguages] = useState([]);
//let location = useLocation();
//let currentPath = location.pathname;
const { t } = useTranslation();
const [ErrorMessage, SetErrorMessage] = useState(false);
const [Message, setMessage] = useState('');
if (localStorage.getItem('lan') === 'en') {
	localStorage.setItem('lanId', 2);
} else if (localStorage.getItem('lan') === null) {
	localStorage.setItem('lan', 'ru'); //default language set here
} else {
	localStorage.setItem('lanId', 1);
}
const languageGetFunc = async () => {
	await api
		.getLanguages()
		.then((response) => {
			let language = response.data.language;
			setLanguages(language);
		})
		.catch((error) => {
			if (error.code !== 'ERR_NETWORK') {
				console.log(error);
			} else {
				SetErrorMessage(true);
				setMessage('Please check your internet connection.');
			}
		});
};
const handleChangeLanguage = (lang_param) => {
	i18next.changeLanguage(lang_param);
	localStorage.setItem('lan', lang_param);
	if (lang_param === 'ru') {
		localStorage.setItem('lanId', 1);
		window.location.reload(true);
	} else {
		localStorage.setItem('lanId', 2);
		window.location.reload(true);
	}
};

useEffect(() => {
	languageGetFunc();
}, [currentPath]);

// const navigate = useNavigate();
return (
	<div className="App">
		{!hideHeaderRoutes.includes(currentPath) ? (
			<Header
				handleChangeLanguage={handleChangeLanguage}
				languageData={Languages}
				setEnablerUrl={setEnablerUrl}
				showList={showList}
				setShowList={setShowList}
			/>
		) : displayHeaderWithLogoRoutes.includes(currentPath) ? (
			<HeaderWithLogo />
		) : null}

		<Routes>
			{window.innerWidth < 768 ? (
				<Route path="/" element={<SplashScreenMobile />} />
			) : (
				<Route
					path="/"
					element={<ChooseLanguage languageData={Languages} />}
				/>
			)}
			{window.innerWidth < 768 ? (
				<Route
					path="/chooselanguage"
					element={<ChooseLanguage languageData={Languages} />}
				/>
			) : (
				<Route path="/login" element={<Login />} />
			)}
			<Route path="/login" element={<Login />} />
			<Route path="/dashboard" element={<Dashboard />} />
			<Route path="/addCompany" element={<AddCompany />} />
			<Route path="/checkpoints/:id" element={<Checkpoints />} />
			<Route
				path="/checkpoints/IARPreparation/:id"
				element={<IARPreparation />}
			/>
			<Route path="/checkpoints/notes/:id" element={<Notes />} />
			<Route
				path="/chooseLanguage"
				element={<ChooseLanguage languageData={Languages} />}
			/>
			<Route
				path="/notificationMobieview"
				element={<NotificationMobileview />}
			/>
			<Route
				path="/checkpoints/DateofIAvisit/:id"
				element={<DateofIAvisit />}
			/>
			<Route path="/CompanyDetails/:id" element={<CompanyDetails />} />
			<Route path="/uploadSignature" element={<UploadSignature />} />
			<Route path="/viewProfie" element={<ViewProfile />} />
			<Route path="forgotPassword" element={<ForgotPassword />} />
			<Route path="/pfdObTab" element={<PfdObTab />} />
			<Route path="/addNewObservation/:id" element={<AddNewObsMob />} />
			<Route path="/aboutApp" element={<AboutApp />} />
			<Route path="/privacyPolicy" element={<PrivacyPolicy />} />
			<Route path="/termsAndConditions" element={<TermsAndCondtions />} />
			<Route
				path="/chooselanguage"
				element={<ChooseLanguage languageData={Languages} />}
			/>
			<Route path="/changepassword" element={<ChangePassword />} />
			<Route path="/help" element={<Help />} />
			<Route path="/notFound" element={<NotFound />} />
			<Route
				path="/checkpoints/addgallery/:id"
				element={<AddGalleryPage />}
			/>

			<Route
				path="/tools"
				element={
					<Tools
						showList={showList}
						setShowList={setShowList}
						url={enablerUrl}
						setEnablerUrl={setEnablerUrl}
					/>
				}
			/>
			<Route
				path="/tools/*"
				element={<Tools url={enablerUrl} setEnablerUrl={setEnablerUrl} />}
			/>
			{/* CMS ROUTES */}
			<Route path="/cms/*" element={<AdminRoutes />} />
			{/* CMS ROUTES */}
			<Route
				path="*"
				element={
					<Navigate to="notFound" state={{ from: currentPath }} replace />
				}
			/>
		</Routes>
		<Modal
			className="newObservation removeConform"
			show={ErrorMessage}
			onHide={() => SetErrorMessage(false)}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="P-3 pt-0 text-center">
					<h4 className="mb-4">{Message && Message}</h4>
				</div>
			</Modal.Body>
		</Modal>
		<Footer setShowList={setShowList} />
	</div>
);
};

export default App;
