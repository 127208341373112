import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import WorkspaceBtn from '../../assets/images/workspace.png';
import calcBtn from '../../assets/images/calculator.png';
import FooterMobimgOne from '../../assets/images/footer_bg.svg';
import FooterMobimgTwo from '../../assets/images/footer_bg_two.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';

const Footer = ({ setShowList }) => {
	const navigate = useNavigate();
	let location = useLocation();
	const { t } = useTranslation();
	useLanguageChange();
	let currentPath = location.pathname;
	const [toolsActive, setToolActive] = useState(false);
	const [toolpath,setToolpath] = useState()
	const ToolsActiveFunc = () => {
		setToolActive(true);
		localStorage.setItem("currentToolpath",location.pathname);
		setToolpath(localStorage.getItem("currentToolpath"))
	};

	useEffect(() => {
		if (currentPath === '/tools') {
			setToolActive(true);
		} else {
			setToolActive(false);
		}
	}, []);

	useEffect(() => {
		if (location.pathname.startsWith('/tools')) {
			setToolActive(true);
		} else {
			setToolActive(false);
		}
	}, [location]);

	if (currentPath == '/termsAndConditions' || currentPath == 'privacyPolicy') {
		return null;
	}

	const ToolsdeActiveFunc = () => {
			setToolActive(false);
			navigate(toolpath);
			localStorage.removeItem("currentToolpath");
	};

	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const nextYear = currentDate.getFullYear() + 1;
	return (
		<>
			<div
				className="footer"
				style={{
					position:
						currentPath == '/login' ||
						currentPath == '/cms' ||
						currentPath == '/chooselanguage' ||
						currentPath == '/' ||
						currentPath == '/forgotPassword'
							? 'fixed'
							: 'unset',
				}}
			>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<p>
								Copyright © {currentYear + '-' + nextYear} REAP Central Asia.
								All rights reserved.{' '}
							</p>
						</div>
					</div>
				</div>
			</div>
			{currentPath == '/' ? null : currentPath ==
			  '/login' ? null : currentPath ==
			  '/chooselanguage' ? null : currentPath ==
			  '/privacyPolicy' ? null : currentPath ==
			  '/forgotPassword' ? null : currentPath ==
			  '/uploadSignature' ? null : currentPath ==
			  '/addCompany' ? null : currentPath.startsWith('/cms') ? null : (
				<div
					className="footer_mobile"
					style={{
						backgroundImage: toolsActive
							? `url(${FooterMobimgTwo})`
							: `url(${FooterMobimgOne})`,
					}}
				>
					<div className="container">
						<div className="row">
							<div
								className={
									toolsActive ? 'col-6 footerBtn' : 'col-6 text-end footerBtn'
								}
							>
								<div onClick={ToolsdeActiveFunc}>
									<img src={WorkspaceBtn} />
									<span
										className={
											toolsActive
												? 'tooltextTransi'
												: 'tooltextTransiActiveleft'
										}
									>
										{t("WorkSpace")}
									</span>
								</div>
							</div>
							<div
								className={
									toolsActive ? 'col-6 footerBtn' : 'col-6 text-end footerBtn'
								}
								onClick={() => setShowList(true)}
							>
								<Link onClick={ToolsActiveFunc} to="/tools">
									<img src={calcBtn} />
									<span
										className={
											toolsActive ? 'tooltextTransiActive' : 'tooltextTransi'
										}
									>
										{t("Tools")}
									</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default Footer;
