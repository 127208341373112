// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-btn {
    position: relative;
    display: inline-block;
    width: 130px;
    height: 35px;
 
    border-radius: 10px;
    background-color: #FFFCF4;
    cursor: pointer;
    overflow: hidden;
    border:2px solid #FFDE9B;
    padding-bottom: 10px;
}

.toggle-btn.toggled {
    background-color: #1E989A;
}

.toggle-ball {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 27px;
    border-radius: 30%;
    background-color: #327072;
    transition: all 0.3s ease-in-out;
}

.toggle-btn.toggled .toggle-ball {
    left: calc(100% - 24px);
        background-color:white;
}

.Master_btn{
            position: relative;
            display: inline-block;
            width: 130px;
            height: 35px;
    
            border-radius: 10px;
            background-color: #1E989A;
            cursor: pointer;
            overflow: hidden;
            border: 2px solid #FFDE9B;
            padding-bottom: 10px;
            vertical-align: center;
            text-align: center;
            padding:4px 0px 0px 0px ;
            font-size: 16px;
            color: white;
        
}`, "",{"version":3,"sources":["webpack://./src/components/admin/components/database/ToggleButton/toggle.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,YAAY;;IAEZ,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,wBAAwB;IACxB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,gCAAgC;AACpC;;AAEA;IACI,uBAAuB;QACnB,sBAAsB;AAC9B;;AAEA;YACY,kBAAkB;YAClB,qBAAqB;YACrB,YAAY;YACZ,YAAY;;YAEZ,mBAAmB;YACnB,yBAAyB;YACzB,eAAe;YACf,gBAAgB;YAChB,yBAAyB;YACzB,oBAAoB;YACpB,sBAAsB;YACtB,kBAAkB;YAClB,wBAAwB;YACxB,eAAe;YACf,YAAY;;AAExB","sourcesContent":[".toggle-btn {\r\n    position: relative;\r\n    display: inline-block;\r\n    width: 130px;\r\n    height: 35px;\r\n \r\n    border-radius: 10px;\r\n    background-color: #FFFCF4;\r\n    cursor: pointer;\r\n    overflow: hidden;\r\n    border:2px solid #FFDE9B;\r\n    padding-bottom: 10px;\r\n}\r\n\r\n.toggle-btn.toggled {\r\n    background-color: #1E989A;\r\n}\r\n\r\n.toggle-ball {\r\n    position: absolute;\r\n    top: 2px;\r\n    left: 2px;\r\n    width: 20px;\r\n    height: 27px;\r\n    border-radius: 30%;\r\n    background-color: #327072;\r\n    transition: all 0.3s ease-in-out;\r\n}\r\n\r\n.toggle-btn.toggled .toggle-ball {\r\n    left: calc(100% - 24px);\r\n        background-color:white;\r\n}\r\n\r\n.Master_btn{\r\n            position: relative;\r\n            display: inline-block;\r\n            width: 130px;\r\n            height: 35px;\r\n    \r\n            border-radius: 10px;\r\n            background-color: #1E989A;\r\n            cursor: pointer;\r\n            overflow: hidden;\r\n            border: 2px solid #FFDE9B;\r\n            padding-bottom: 10px;\r\n            vertical-align: center;\r\n            text-align: center;\r\n            padding:4px 0px 0px 0px ;\r\n            font-size: 16px;\r\n            color: white;\r\n        \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
