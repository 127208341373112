import React, { useState, useEffect } from 'react';
import './style.css';

const AboutApp = () => {
	const isMobile = window.innerWidth < 768;

	const [currentLanguage, setCurrentLanguage] = useState(2);
	useEffect(() => {
		if (localStorage.getItem('lanId')) {
			setCurrentLanguage(localStorage.getItem('lanId'));
		}
	}, []);
	return (
		<div>
			<div className="deskWrapper">
				<div
					style={{
						paddingLeft: !isMobile ? '80px' : '20px',
						marginTop: '20px',
					}}
				>
					{!isMobile ? (
						<h2
							style={{
								color: '#3B7E80',
								fontSize: '24px',
								fontWeight: 'bold',
							}}
						>
							{currentLanguage == '2' ? 'About REAP DS' : 'О REAP DS'}
						</h2>
					) : null}
					<div style={{ padding: !isMobile ? '15px 15px 15px 70px' : '10px' }}>
						{currentLanguage == 2 ? (
							<>
								<section className="section">
									<h4 className="heading">
										{currentLanguage == '2' ? 'About Us' : 'О нас:'}
									</h4>
									<p className="paragraph">
										The REAP Digital Solution (REAP DS) is developed under the
										REAP project funded by the European Union Switch Asia
										Programme. The four-year project promotes Sustainable
										Consumption and Production (SCP) practices in the agri-food
										production and processing industries along the entire supply
										chain in Uzbekistan and Tajikistan
									</p>
									<p className="paragraph">
										Under the REAP project, some Technical Consultants (TC) have
										been hired and they work closely with local industries in
										central Asia (both in Uzbekistan and Tajikistan) delivering
										consulting services supported by senior technical experts
										(STE) from India and Europe. The workflow underpinning these
										consulting services is mainly manual and involves data
										collection, report preparation, report presentation and many
										other activities. REAP DS has been created to help automate
										some parts of this workflow which relates to the preparation
										of the initial assessment report (IAR). Specifically, REAP
										DS helps a TC prepare a draft IAR faster and more
										efficiently as compared to the manual process
									</p>
									<p className="paragraph">
										REAP DS is a web based responsive solution that works on
										both mobile as well as laptop devices (across different
										operating systems like IoS, Windows, Android) but some
										screens of the solution are best viewed only on a laptop
										device. Access to this solution is given to only the
										relevant REAP Project team members and it is not open to
										general public. Access is managed by a designated admin
										person from the REAP project team.
									</p>
								</section>
								<section className="section">
									<h4 className="heading">General Disclaimer</h4>
									<p className="paragraph">
										This web based solution was created and maintained with the
										financial support of the European Union. Its contents are
										the sole responsibility of REAP project team and do not
										necessarily reflect the views of the European Union
									</p>
								</section>
								<section className="section">
									<h4 className="heading">Web based Solution Developer</h4>
									<p className="paragraph">
										This mobile application was designed by STENUM Asia
										Sustainable Development Society and coded by EDC Creative
										Technology Solutions Private Limited.
									</p>
								</section>
							</>
						) : (
							<>
								<section className="section">
									<h4 className="heading">О нас</h4>
									<p className="paragraph">
										Цифровое Решение REAP (REAP ЦР) разработано в рамках проекта
										REAP, финансируемого Программой Европейского Союза Switch
										Asia. Четырехлетний проект продвигает практику устойчивого
										потребления и производства (УПП) в агропродовольственном
										производстве и перерабатывающей промышленности по всей
										цепочке поставок в Узбекистане и Таджикистане.
									</p>
									<p className="paragraph">
										В рамках проекта REAP было нанято несколько технических
										консультантов (ТК), которые тесно сотрудничают с местными
										предприятиями в Центральной Азии (как в Узбекистане, так и в
										Таджикистане), предоставляя консультационные услуги при
										поддержке старших технических экспертов (СТЭ) из Индии и
										Европы. Рабочий процесс, лежащий в основе этих
										консультационных услуг, в основном выполняется вручную и
										включает сбор данных, подготовку отчетов, представление
										отчетов и многие другие действия. REAP ЦР был создан, чтобы
										помочь автоматизировать некоторые части этого рабочего
										процесса, связанного с подготовкой отчёта о первоначальной
										оценке (ОПО). В частности, REAP ЦР помогает TC быстрее и
										эффективнее подготовить черновой вариант ОПО по сравнению с
										ручным процессом.
									</p>
									<p className="paragraph">
										REAP ЦР — это адаптивное веб-решение, которое работает как
										на мобильных, так и на ноутбуках (в разных операционных
										системах, таких как IOS, Windows, Android), но некоторую
										информацию лучше всего просматривать только на ноутбуке.
										Доступ к этому решению предоставляется только
										соответствующим членам команды проекта REAP, и оно не
										открыто для широкой публики. Доступ управляется назначенным
										администратором из команды проекта REAP.
									</p>
								</section>
								<section className="section">
									<h4 className="heading">Общий отказ от ответственности</h4>
									<p className="paragraph">
										Это веб- решение было создано и поддерживается при
										финансовой поддержке Европейского Союза. Его содержание
										является исключительной ответственностью команды проекта
										REAP и не обязательно отражает точку зрения Европейского
										Союза.
									</p>
								</section>
								<section className="section">
									<h4 className="heading">Разработчик веб-решений</h4>
									<p className="paragraph">
										Это мобильное приложение было разработано Обществом
										устойчивого развития STENUM Asia и закодировано EDC Creative
										Technology Solutions Private Limited.
									</p>
								</section>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutApp;
