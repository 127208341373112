import React from 'react'
import "./ProcessFlow.css"
import sidewaysarrow from "../../../assets/pdf_images/sidewaysarrow.png"
import downarrow from "../../../assets/pdf_images/Main down arrow for process.png"
import { Page, Text, View, Image, Font, StyleSheet } from "@react-pdf/renderer";
import Header from '../../../assets/pdf_images/Header.png';
import Footer from '../../../assets/pdf_images/Footer.png'
import Robot from '../../../assets/fonts/Roboto-Regular.ttf';
import RobotSemiBold from '../../../assets/fonts/Roboto-Medium.ttf';
import ItalicText from '../../../assets/fonts/ArialCEItalic.ttf';
Font.register({
    family: "RobotFont",
    fonts: [
      {
        src: Robot,
        fontWeight: 100,
      },
      {
        src: RobotSemiBold,
        fontWeight: 600,
      },
      {
        src: ItalicText,
        fontStyle:"italic"
      }
    ]
  });
  
const ProcessFlowPDF = ({ pdfDataProcessflow, company }) => {
    const ColorLightBlue = {
        color: "#1E989A"
    }
    const pageStyle = {
        paddingTop: 5,
        paddingBottom: 80,
        paddingHorizontal: 40,

    };
    const FooterStyle = {
        position: 'absolute',
        fontSize: 12,
        bottom: "10px",
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    };
    const pageNumberstyle = {
        position: 'absolute',
        fontSize: 12,
        bottom: "0px",
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor:"#1e989a",
        height:"16px",
        flexDirection:"row",
        alignItems:"center",
        textAlign:"center",
    }
  
    return (
        <Page
            size="A4"
            orientation="portrait"
            style={pageStyle}
        >
            <View fixed><Image src={Header} /></View>
            <View
                style={{
                    color: ColorLightBlue.color,
                    flexDirection: 'row',
                    paddingTop: "5px",
                    paddingBottom: "10px"
                }}>
                <Text style={{ fontFamily: 'Roboto', fontSize: "12px" }}>{"2. "} </Text>
                <Text style={{ fontFamily: 'Roboto', fontWeight: "bold", fontSize: "12px" }}>Process Flow Diagram with Resource Mapping</Text>
            </View>
            <View>
                <Text style={{ fontSize: "11px", lineHeight: "1.8px" }}>
                   The company's production process involves the following steps.
                </Text>
            </View>
            <View style={{ flexDirection: "row",marginTop:"15px",marginBottom:"5px" }} fixed>
                <View style={{ width: "30%" }}>
                    <Text style={{ fontSize: "11px",textDecoration:"underline",textAlign:"center",fontFamily:"RobotFont",fontWeight:"700"}}>Resouces</Text>
                </View>
                <View style={{ width: "40%" }}>
                    <Text style={{ fontSize: "11px",textDecoration:"underline",textAlign:"center",fontFamily:"RobotFont",fontWeight:"700"}}>Process</Text>
                </View>
                <View style={{ width: "30%" }}>
                    <Text style={{ fontSize: "11px",textDecoration:"underline",textAlign:"center",fontFamily:"RobotFont",fontWeight:"700"}}>Waste & Hazards</Text>
                </View>
            </View>
           {pdfDataProcessflow?.map((item, index) =><View key={index} style={{flexDirection: "row",marginTop:"20px",alignItems:"center",marginBottom:"20px",justifyContent:"center"}}>
                <View style={{ width: "30%",paddingRight:10 }}>
                    <View style={{ flexDirection: "row",alignItems:"center"}}> 
                        <View style={{width:"90%",paddingRight:10,borderRight:JSON.parse(item?.resource_json).length>=2?"1px":"0px"}}>
                            {JSON.parse(item?.resource_json).map((resource, index) =><Text key={index} style={{ fontSize: "10px",textAlign:"right",marginBottom:5,fontFamily:"RobotFont",fontWeight:"700"}}>{resource.resource_name}</Text>)}
                        </View>
                    </View>
                </View>
                <View style={{width:"5%"}}><Image src={sidewaysarrow} style={{width:"10px"}}/></View>
                <View style={{ position:"relative",alignItems:"center",width: "30%",paddingRight:10,backgroundColor:"#d9d9d9",border:"1px"}}>
                    <View style={{flexDirection: "row",alignItems:"center",padding:14}}> 
                        <View style={{width:"80%",alignItems:"center"}}>
                            <Text style={{ fontSize: "10px",textAlign:"center",fontFamily:"RobotFont",fontWeight:"700"}}>{item.process_name}</Text>
                        </View>
                    </View>
                    {index + 1 === pdfDataProcessflow.length?null:<View style={{textAlign:"center",position:"absolute",bottom:"-20px"}}><Image src={downarrow} style={{width:"8px"}}/></View>}
                </View>
                <View style={{width:"5%",marginLeft:10}}><Image src={sidewaysarrow} style={{width:"10px"}}/></View>
                <View style={{ width: "30%",paddingLeft:20 }}>
                    <View style={{ flexDirection: "row",alignItems:"center"}}> 
                        <View style={{borderLeft:item?.waste_json.length>=2?"1px":"0px",paddingLeft:10}}>
                            {item?.waste_json.map((waste,index)=><Text key={index} style={{ fontSize: "10px",marginBottom:5,fontFamily:"RobotFont",fontWeight:"700"}}>{waste.waste_name}</Text>)}
                        </View>
                    </View>
                </View>
               
            </View>)}
            {company!=undefined && company!=null && <View style={{marginTop:10}}><Text style={{color: ColorLightBlue.color,fontSize:"10px",fontFamily:"RobotFont",fontStyle:"italic"}}>{company[0].process_caption !=null?"Figure 1: "+company[0].process_caption:''}</Text></View>}
            <View style={FooterStyle} fixed><Image src={Footer} /></View>
            <View style={pageNumberstyle} fixed><Text style={{color:"#fff",fontSize:9,width:"100%",textAlign:"center"}} 
               render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )}
            /></View>
        </Page>
    )
}
export default ProcessFlowPDF
