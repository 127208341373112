// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companydetail_companydetail_txt__pZ0DV {
    font-family: 'Robotomedium';
    color: #1E989A;
}

.companydetail_company_name__pcm1P {

    font-size: 20px;
    font-family: 'Robotomedium';

}

.companydetail_company_details_subtxt__8fTqR {
    font-size: 14px;
    font-family: 'Robotomedium';
}

.companydetail_company_adress__XKaHl {
    font-family: 'Roboto';
    font-size: 13px;
    color: #5A5A5A;
}

.companydetail_contact_persons_details__DUNOo {
    margin-top: 40px;
}

.companydetail_contact_person_txt__zky8X {
    font-size: 15px;
    font-family: 'Robotomedium';
    color: #327072;
}

.companydetail_contact_person_lhs__D5tu3 {
    font-family: 'Robotomedium';
    color: #3B7E80;
    font-size: 12px;
}

.companydetail_contact_person_rhs__ZhzH2 {
    font-size: 11px;
    padding-left: 8px;

}
.companydetail_productsCom__3eGXg{
    line-height: 15px;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/companyDetails/companydetail.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;;IAEI,eAAe;IACf,2BAA2B;;AAE/B;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;;AAErB;AACA;IACI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".companydetail_txt {\r\n    font-family: 'Robotomedium';\r\n    color: #1E989A;\r\n}\r\n\r\n.company_name {\r\n\r\n    font-size: 20px;\r\n    font-family: 'Robotomedium';\r\n\r\n}\r\n\r\n.company_details_subtxt {\r\n    font-size: 14px;\r\n    font-family: 'Robotomedium';\r\n}\r\n\r\n.company_adress {\r\n    font-family: 'Roboto';\r\n    font-size: 13px;\r\n    color: #5A5A5A;\r\n}\r\n\r\n.contact_persons_details {\r\n    margin-top: 40px;\r\n}\r\n\r\n.contact_person_txt {\r\n    font-size: 15px;\r\n    font-family: 'Robotomedium';\r\n    color: #327072;\r\n}\r\n\r\n.contact_person_lhs {\r\n    font-family: 'Robotomedium';\r\n    color: #3B7E80;\r\n    font-size: 12px;\r\n}\r\n\r\n.contact_person_rhs {\r\n    font-size: 11px;\r\n    padding-left: 8px;\r\n\r\n}\r\n.productsCom{\r\n    line-height: 15px;\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companydetail_txt": `companydetail_companydetail_txt__pZ0DV`,
	"company_name": `companydetail_company_name__pcm1P`,
	"company_details_subtxt": `companydetail_company_details_subtxt__8fTqR`,
	"company_adress": `companydetail_company_adress__XKaHl`,
	"contact_persons_details": `companydetail_contact_persons_details__DUNOo`,
	"contact_person_txt": `companydetail_contact_person_txt__zky8X`,
	"contact_person_lhs": `companydetail_contact_person_lhs__D5tu3`,
	"contact_person_rhs": `companydetail_contact_person_rhs__ZhzH2`,
	"productsCom": `companydetail_productsCom__3eGXg`
};
export default ___CSS_LOADER_EXPORT___;
