export const validateForm = (data) => {
	let errors = {};

	// validate firstname
	if (!data.firstname.trim()) {
		errors.firstname = 'First name is required.';
	}

	// validate lastname
	// if (!data.lastname.trim()) {
	// 	errors.lastname = 'Last name is required';
	// }

	// validate email
	if (!data.email.trim()) {
		errors.email = 'Email address is required.';
	} else if (!/\S+@\S+\.\S+/.test(data.email)) {
		errors.email = 'Invalid email address.';
	}

	return errors;
};
