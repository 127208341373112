import React from 'react';
import Table from '../table/Table';

const DeactivatedCompanies = ({ companies, steList }) => {
	const columnsArray = [
		'Date IA Visit',
		'Company Code',
		'Company Name',
		'TC',
		'STE',
		'Date of Deactivation',
		'Reason for Deactivation',
	];

	return (
		<div className="scpImprovement">
			<Table
				columnsArray={columnsArray}
				companyType="deactivated companies"
				dataArray={companies}
				steList={steList}
			/>
		</div>
	);
};
export default DeactivatedCompanies;
