// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dropdown{
    background-color: #1E989A;
    border: 1px solid #1E989A;
    width:110px;
    text-align:left;
    display:flex;
    gap:12px;
    align-items: center;
    text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/components/database/database.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,YAAY;IACZ,QAAQ;IACR,mBAAmB;IACnB,0BAA0B;AAC9B","sourcesContent":["#dropdown{\r\n    background-color: #1E989A;\r\n    border: 1px solid #1E989A;\r\n    width:110px;\r\n    text-align:left;\r\n    display:flex;\r\n    gap:12px;\r\n    align-items: center;\r\n    text-transform: capitalize;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
