import IARPreparationStyles from '../../iarPreparation/IARPreparation.module.css';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import DataService from '../../../services/DataServices';
import GalleryStyle from './gallery_style.module.css';
import AuthUser from '../../authUser/authuser';
import LoaderImg from '../../../assets/images/loadin_gif.gif'; 
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../../customHooks/lan_hook';
import { ErrorLog } from '../../erroLog/errorLog';

const Gallery = ({ companyId,linkpropsFunction,firstname}) => {
    const api = new DataService();
    const { logout } = AuthUser();
    const { t } = useTranslation();
    useLanguageChange();
    const [GalleryData, setGalleryData] = useState();
    const [linkingArray, setLinkingArray] = useState([]);
    const [Loader,setLoader] = useState(false);
    const [erroMessage,setErroMessage] = useState();
    const GetgalleryData = async () => {
        setLoader(true);
        let data = {
            "company_id": companyId
        }
        await api.getGallery(data).then((response) => {
            if (response.status == 200 || response.status == 201) {
                let gallerydata = response.data;
                setGalleryData(gallerydata.GalleryList);
                setLoader(false);
            }
        }).catch((error)=>{
            if(error.code !="ERR_NETWORK"){
                let errorCode = error.response.status;
                let errMsg = error.response.data.error.message;
                if (errorCode == 401) {
                    logout();
                }
                if (errorCode == 400 || errorCode == 500) {
                    let errorLogData ={
                        api_name:"getgalleryData",
                        error_code:errorCode,
                        error_message:errMsg
                    } 
                    ErrorLog(errorLogData);
                }
            }
        })
    }
    const onHandleCheckImageLink = (e,indexId,index) => {
        let imageSecondname = index+1
        let { value, checked, id } = e.target;
        if (checked == true) {
            setLinkingArray([...linkingArray, {id:id,name:"Image"+firstname+"."+imageSecondname}])
        }
        else {
            setLinkingArray(linkingArray.filter((item) =>item.id!== id ))
        }
    }
    const validationImageLink =()=>{
        setErroMessage(t("PlsSelectImage"))
    }
    useEffect(() => {
        GetgalleryData();
    }, [])
    return (
        <div className="">
            <div className="row mt-2">
                <div 
                   className={"selectGalleryImg"+" "+GalleryStyle.galleryFlex} 
                   style={{justifyContent:"left",paddingLeft:"30px"}}
                >
                   {GalleryData!=undefined && GalleryData.length!=0?GalleryData.map((item,index)=> <div key={index} >
                        <label htmlFor={item.gallery_id}  className={GalleryStyle.labelcheck}>
                            <input 
                                type="checkbox" 
                                id={item.gallery_id} 
                                value={item.gallery_caption}   
                                onChange={(e)=>onHandleCheckImageLink(e,item.gallery_id,index)}
                                className='checkboxAll'
                            />
                             <img src={item.media_url} alt={item?.gallery_caption} width={'100px'} />
                             <div className={GalleryStyle.textOverflow}>{item.gallery_caption}</div>
                        </label>
                    </div>):Loader == true ? <img src={LoaderImg} alt="loader" style={{width:"166px",border:"none"}}/>: <div>{t("noImage")}</div>}
                   
                </div>
            </div>
            <div className="row mt-4">
                <div className='text-danger text-center mb-2'>{erroMessage}</div>
                {GalleryData!=undefined && GalleryData.length!=0?<div className="col-md-12" style={{textAlign:"right"}}>
                   {firstname!=undefined?<Button
                       className={"primary_btnGallery" + " " + IARPreparationStyles.addnewcomp}
                       style={{backgroundColor:linkingArray.length > 0 ? "#1E989A":"#8b8b8b",color:linkingArray.length > 0 ?"#fff":"rgb(74, 63, 63)"}}
                       onClick ={()=>{
                        if(linkingArray.length>0){
                            linkpropsFunction(linkingArray)
                        }
                        else{
                            validationImageLink()
                        }
                       }}
                    >
                        {t("LinkPhoto")}
                    </Button>:<Button
                       className={"primary_btn" + " " + IARPreparationStyles.addnewcomp}
                            
                    >
                        {t("LinkPhoto")}
                    </Button>}
                </div>:null}
            </div>
        </div>
    )
}
export default Gallery;