import React from 'react';
import TCStyles from '../tc/tc.module.css';
import { formatDateWithDateFirst } from '../../../utils/formatDate';

const ActivityRow = ({ activity, span }) => {
	return (
		<tr className={TCStyles.tr}>
			<td className={TCStyles.td} colSpan={span}>
				{activity?.user_first_name}
			</td>

			<td
				className={TCStyles.td}
				colSpan={span}
				style={{ textTransform: 'capitalize' }}
			>
				{activity?.log_desc}
			</td>
			<td className={TCStyles.td} colSpan={span}>
				{formatDateWithDateFirst(activity?.log_created_date?.split('T')[0])}
			</td>
		</tr>
	);
};

export default ActivityRow;
