// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateofiavisit_datepickr_txt__RwYtG {
    font-family: 'Robotomedium';
    color: #1E989A;
}

.dateofiavisit_secondary_date_btn__zkjvR {
    background-color: #1E989A;
    color: #FFFFFF;
    border: #1E989A 1px solid;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 6px;
    font-weight: 500;
    line-height: 1.5;
    margin: 2px;
    position: relative;
    display: inline-block;
    text-align: center;
    min-width: 250px;
}

.dateofiavisit_date_btn__nr8ws {
    text-align: center;
}

.dateofiavisit_date_picker__Zf8jI {
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/dateofIAvisit/dateofiavisit.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".datepickr_txt {\r\n    font-family: 'Robotomedium';\r\n    color: #1E989A;\r\n}\r\n\r\n.secondary_date_btn {\r\n    background-color: #1E989A;\r\n    color: #FFFFFF;\r\n    border: #1E989A 1px solid;\r\n    padding: 8px 15px;\r\n    font-size: 14px;\r\n    border-radius: 6px;\r\n    font-weight: 500;\r\n    line-height: 1.5;\r\n    margin: 2px;\r\n    position: relative;\r\n    display: inline-block;\r\n    text-align: center;\r\n    min-width: 250px;\r\n}\r\n\r\n.date_btn {\r\n    text-align: center;\r\n}\r\n\r\n.date_picker {\r\n    margin-bottom: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datepickr_txt": `dateofiavisit_datepickr_txt__RwYtG`,
	"secondary_date_btn": `dateofiavisit_secondary_date_btn__zkjvR`,
	"date_btn": `dateofiavisit_date_btn__nr8ws`,
	"date_picker": `dateofiavisit_date_picker__Zf8jI`
};
export default ___CSS_LOADER_EXPORT___;
