
import { Page, Text, View, Document, StyleSheet,Image} from '@react-pdf/renderer';
import Logo from "../../../assets/pdf_images/logo.png";
import SwitchAsiaLogo from '../../../assets/pdf_images/switchasia.png'
import PartnerLogo from '../../../assets/pdf_images/Partner_Logos_Combined.png';

const PageOne = ({ company}) => {
    return (
        <Page size="A4">
        <View style={{width:"100%",textAlign:"center",paddingTop:"50px"}}>
           <Image src={Logo} style={{objectFit:"contain",width:"350px",margin:"0px auto"}}/>
        </View>
        <View style={{paddingTop:"100px"}}>
            <Text style={{width:"100%",textAlign:"center",fontSize:"25px",fontWeight:"bold",color:"#1E989A"}}>Initial Assessment Report</Text>
        </View>
        <View style={{width:"100%",textAlign:"center",marginTop:"40px"}}>
             <Text style={{fontSize:"20px",fontWeight:"bold",color:"#1E989A"}}>{company!=undefined &&company.length>0&&company[0].company_name}</Text>
        </View>
        <View style={{width:"100%",textAlign:"center",marginTop:"40px"}}><Text>{company!=undefined &&company.length>0&&company[0].company_iav_date}</Text></View>
        <View style={{width:"100%",paddingTop:"40px"}}><Image src={SwitchAsiaLogo} style={{width:"400px",margin:"0px auto"}}/></View>
        <View style={{width:"100%",paddingTop:"60px"}}><Image src={PartnerLogo} style={{width:"450px",margin:"0px auto"}}/></View>
      </Page>
    )
}
export default PageOne;

